
import { forwardRef } from 'react'

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types'

import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import Icon from '@mui/material/Icon'

const ZUTitleForm = forwardRef(({ title, icon, bgColor, children }, ref) => (
  <MDBox display='flex'>
    <MDBox
      display='flex'
      justifyContent='center'
      alignItems='center'
      width='4rem'
      height='4rem'
      variant='gradient'
      bgColor={bgColor}
      color='white'
      shadow='md'
      borderRadius='xl'
      ml={3}
      mt={-2}
    >
      <Icon fontSize='medium' color='inherit'>{icon}</Icon>
    </MDBox>
    <MDTypography variant='h5' sx={{ mt: 2, mb: 1, ml: 2 }}>
      {title}
    </MDTypography>
    {children}
  </MDBox>

))

// Setting default values for the props
ZUTitleForm.defaultProps = {
  bgColor: 'info',
  title: 'title',
  icon: 'user'
}

// Typechecking props for the ZUTitleForm
ZUTitleForm.propTypes = {
  bgColor: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.string,
  children: PropTypes.node
}

export default ZUTitleForm
