import { useEffect, useContext, useState, useMemo } from 'react'
// @mui material components
import Card from '@mui/material/Card'
import Icon from '@mui/material/Icon'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'

import { Link as MuiLink, Stack } from '@mui/material'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDSnackbar from 'components/MDSnackbar'
import MDButton from 'components/MDButton'

// Material Dashboard 2 PRO React example components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import DataTable from 'components/Tables/DataTable'

// zerouno
import maintenanceService from 'services/maintenance.service'
import authService from 'services/auth.service'
import { Link, useNavigate } from 'react-router-dom'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import RabbitContext from 'context/rabbit.js'
import utils from 'utils/utils'
import { config } from 'variables/Config.js'
import EventCalendar from 'components/Calendar'

export default function MaintenanceTable () {
  const [objects, setObjects] = useState([])
  const [objId, setObjId] = useState(0)
  const [showSnackbar, setShowSnackbar] = useState({ sh: false, msg: '', color: 'success' })
  const [openConfirm, setOpenConfirm] = useState(false)
  const { rabbit } = useContext(RabbitContext)
  const navigate = useNavigate()

  useEffect(() => {
    fetchObjects()
  }, [])

  const fetchObjects = () => {
    maintenanceService.getAll(rabbit.companyId).then(
      (response) => {
        setObjects(response.data)
      },
      (error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      }
    )
  }

  // Delete
  const deleteObject = () => {
    setOpenConfirm(false)
    maintenanceService.remove(objId).then(
      (response) => {
        setShowSnackbar({ sh: true, msg: 'operazione effettuata con successo', color: 'success' })
        fetchObjects()
      },
      (error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      }
    )
  }

  const onHandleEventClick = (info) => {
    // info.jsEvent.preventDefault() // se event ha un url
    const item = info.event.extendedProps
    navigate(`/maintenances/${item.id}`)
  }

  const columns = [
    {
      Header: 'chiusa',
      accessor: 'chiusa',
      Cell: ({ value, row }) => value ? 'si' : 'no'
    },
    {
      Header: 'data',
      accessor: 'data',
      Cell: ({ value, row }) => utils.formatDate(value, 'dd/MM/yyyy')
    },
    {
      Header: 'prodotto',
      accessor: 'product',
      Cell: ({ value, row }) => value ? value.serial + ' ' + value.productModel.name : ''
    },
    {
      Header: 'cliente',
      accessor: 'customer',
      Cell: ({ value, row }) => value ? value.name + ' ' + value.surname : ''
    },
    {
      Header: 'azioni',
      sortable: false,
      filterable: false,
      width: 100,
      accessor: 'id',
      Cell: ({ value, row }) => {
        return (
          <Stack direction='row' spacing={1}>
            <MDButton
              color='info'
              size='small'
              iconOnly
              component={Link}
              to={'/maintenances/' + value}
            >
              <Icon>edit</Icon>
            </MDButton>
            <MDButton
              iconOnly
              color='error'
              size='small'
              onClick={() => {
              	setObjId(value)
              	setOpenConfirm(true)
            	}}
            >
              <Icon>delete</Icon>
            </MDButton>
            {row.original.allegato &&
              <MDButton
                size='small'
                color='success'
                sx={{ mx: 2 }}
                href={config.SERVER_URL + '/download/' + row.original.allegato}
                target='_blank'
              >
                doc
              </MDButton>}
          </Stack>
        )
      }
    }
  ]

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <ZUTitleForm bgColor='info' title='Manutenzioni' icon='handyman' />
          <MDBox p={3} lineHeight={1}>
            <MDButton color='info' component={Link} to='/maintenances/new' m={1}>
              aggiungi
            </MDButton>
            {/* <MDButton
              color='success'
              component={MuiLink}
              href={
                  config.SERVER_URL +
                  '/maintenances/download/excel?companyId=' +
                  rabbit.companyId
                }
              target='_blank'
              sx={{ m: 1 }}
            >
              esporta in excel
            </MDButton> */}
          </MDBox>

          <DataTable table={{ columns: columns, rows: objects }} canSearch />
        </Card>
      </MDBox>
      <Footer />
      <Dialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          Confermi eliminazione?
        </DialogTitle>
        <DialogActions>
          <MDButton onClick={() => setOpenConfirm(false)}>Annulla</MDButton>
          <MDButton onClick={deleteObject} autoFocus>Conferma</MDButton>
        </DialogActions>
      </Dialog>
      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>

  )
}
