'use strict'

import http from './http-common'
import authHeader from './auth-header'
import useSWR from 'swr'

const getAll = (companyId) => {
  return http.axiosClient.get(`/transactions?companyId=${companyId}`, {
    headers: authHeader()
  })
}

const get = (id) => {
  return http.axiosClient.get(`/transactions/${id}`, {
    headers: authHeader()
  })
}

const create = (data) => {
  return http.axiosClient.post('/transactions', data, {
    headers: authHeader()
  })
}

const update = (id, data) => {
  return http.axiosClient.put(`/transactions/${id}`, data, {
    headers: authHeader()
  })
}

const remove = (id) => {
  return http.axiosClient.delete(`/transactions/${id}`, {
    headers: authHeader()
  })
}

const findByCustomer = (customerId) => {
  return http.axiosClient.get(`/transactions?include=order&include=customer&customerId=${customerId}`, { headers: authHeader() })
}

const findByOrder = (orderId) => {
  return http.axiosClient.get(`/transactions?include=order&include=customer&orderId=${orderId}`, { headers: authHeader() })
}

const downloadExcel = (companyId) => {
  return http.axiosClient.get(`/transactions/download/excel?companyId=${companyId}`, { headers: authHeader() })
}

export default {
  getAll,
  get,
  create,
  update,
  remove,
  findByCustomer,
  findByOrder,
  downloadExcel
}
