import axios from 'axios'
import { config } from 'variables/Config.js'

class AuthService {
  login(username, password) {
    return axios
      .post(config.SERVER_URL + '/auth/signin', { username, password })
      .then((response) => {
        if (response.data.accessToken) {
          sessionStorage.setItem('user', JSON.stringify(response.data))
        }
        return response.data
      })
  }

  logout() {
    sessionStorage.removeItem('user')
  }

  register(username, email, password) {
    return axios.post(config.SERVER_URL + 'signup', {
      username,
      email,
      password
    })
  }

  user() {
    return JSON.parse(sessionStorage.getItem('user'))
  }

  isAdmin() {
    const user = JSON.parse(sessionStorage.getItem('user'))
    return user && user.role && user.role === 'admin'
  }

  isUser() {
    const user = JSON.parse(sessionStorage.getItem('user'))
    return user && user.role && user.role === 'user'
  }

  isAgente() {
    const user = JSON.parse(sessionStorage.getItem('user'))
    return user && user.role && (user.role === 'agente' || user.role === 'tecnico_agente')
  }

  isTecnico() {
    const user = JSON.parse(sessionStorage.getItem('user'))
    return user && user.role && (user.role === 'tecnico' || user.role === 'tecnico_agente')
  }

  isTecnicoAgente() {
    const user = JSON.parse(sessionStorage.getItem('user'))
    return user && user.role && user.role === 'tecnico_agente'
  }
}

export default new AuthService()
