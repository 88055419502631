'use strict'

import http from './http-common'
import authHeader from './auth-header'

const getAll = (companyId, sales) => {
  return http.axiosClient.get(
    `/productions?companyId=${companyId}`,
    {
      headers: authHeader()
    }
  )
}

const get = (id) => {
  return http.axiosClient.get(`/productions/${id}`, {
    headers: authHeader()
  })
}

const create = (data) => {
  return http.axiosClient.post('/productions', data, {
    headers: authHeader()
  })
}

const update = (id, data) => {
  return http.axiosClient.put(`/productions/${id}`, data, {
    headers: authHeader()
  })
}

const remove = (id) => {
  return http.axiosClient.delete(`/productions/${id}`, {
    headers: authHeader()
  })
}

const findByCustomer = (customerId, sales) => {
  return http.axiosClient.get(
    `/productions?customerId=${customerId}`,
    {
      headers: authHeader()
    }
  )
}

const downloadExcel = (companyId) => {
  return http.axiosClient.get(`/productions/download/excel?companyId=${companyId}`, {
    headers: authHeader()
  })
}

export default {
  getAll,
  get,
  create,
  update,
  remove,
  findByCustomer,
  downloadExcel
}
