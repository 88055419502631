'use strict'

import http from './http-common'
import authHeader from './auth-header'
import useSWR from 'swr'

const getAll = (companyId) => {
  return http.axiosClient.get(`/visits?companyId=${companyId}`, {
    headers: authHeader()
  })
}

const revalidate = (companyId, mutate) => {
  mutate([`/visits?companyId=${companyId}`, { headers: authHeader() }])
}

const get = (id) => {
  return http.axiosClient.get(`/visits/${id}`, { headers: authHeader() })
}

const create = (data) => {
  return http.axiosClient.post('/visits', data, { headers: authHeader() })
}

const update = (id, data) => {
  return http.axiosClient.put(`/visits/${id}`, data, { headers: authHeader() })
}

const remove = (id) => {
  return http.axiosClient.delete(`/visits/${id}`, { headers: authHeader() })
}

const excel = (companyId) => {
  return http.axiosClient.get(`/visits/excel/?companyId=${companyId}`, {
    responseType: 'blob',
    headers: authHeader()
  })
}

export default {
  getAll,
  get,
  create,
  update,
  remove,
  revalidate,
  excel
}
