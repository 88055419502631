import { useEffect, useContext, useState } from 'react'
// @mui material components
import { Card, Stack, Icon, Link as MuiLink, Grid, Dialog, DialogActions, DialogTitle, Tooltip } from '@mui/material/'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDSnackbar from 'components/MDSnackbar'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'

// Material Dashboard 2 PRO React example components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import DataTable from 'components/Tables/DataTable'

// zerouno
import mainService from 'services/product.service'
import { Link } from 'react-router-dom'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import RabbitContext from 'context/rabbit.js'
import authService from 'services/auth.service'
import { config } from 'variables/Config.js'

export default function DoctorTable() {
  const [objId, setObjId] = useState(0)
  const [objects, setObjects] = useState([])
  const [free, setFree] = useState([])
  const [freeId, setFreeId] = useState([])
  const [busy, setBusy] = useState([])
  const [showSnackbar, setShowSnackbar] = useState({ sh: false, msg: '', color: 'success' })
  const [openConfirm, setOpenConfirm] = useState(false)
  const { rabbit } = useContext(RabbitContext)

  useEffect(() => {
    fetchObjects()
    fetchFree()
    fetchBusy()
  }, [rabbit.companyId])

  const fetchObjects = () => {
    mainService.getAll(rabbit.companyId).then(
      (response) => {
        setObjects(response.data)
      },
      (error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      }
    )
  }

  const fetchFree = () => {
    mainService.getFree().then(
      (response) => {
        setFree(response.data)
        const freeId = response.data.map((item) => {
          return item.id
        })
        setFreeId(freeId)
      },
      (error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      }
    )
  }

  const fetchBusy = () => {
    mainService.getBusy().then(
      (response) => {
        setBusy(response.data)
      },
      (error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      }
    )
  }

  // Delete
  const deleteObject = () => {
    setOpenConfirm(false)
    mainService.remove(objId).then(
      (response) => {
        setShowSnackbar({ sh: true, msg: 'operazione effettuata con successo', color: 'success' })
        fetchObjects()
      },
      (error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      }
    )
  }

  const columns = [
    {
      Header: 'id',
      accessor: 'id'
    },
    {
      Header: 'seriale',
      accessor: 'serial'
    },
    {
      Header: 'lotto',
      accessor: 'batch'
    },
    {
      Header: 'modello',
      accessor: (row) => row.productModel.name
    },
    {
      Header: 'anno',
      accessor: 'year'
    },
    {
      Header: 'attivo',
      accessor: 'active',
      Cell: ({ value }) => value ? <CheckBoxIcon color='info' /> : ''
    },
    {
      Header: 'stato',
      accessor: (row) => (<Tooltip title={row.notes}><MDTypography variant='inherit'>{freeId.includes(row.id) ? 'libero' : 'impegnato'}</MDTypography></Tooltip>)
    },
    {
      Header: 'azioni',
      sortable: false,
      filterable: false,
      width: 100,
      accessor: (row) => (
        <Grid container spacing={1}>
          <Grid item>
            <MDButton
              color='info'
              size='small'
              iconOnly
              component={Link}
              to={'/products/' + row.id}
            >
              <Icon>edit</Icon>
            </MDButton>
          </Grid>
          <Grid item>
            <MDButton
              iconOnly
              color='error'
              size='small'
              onClick={() => {
                setObjId(row.id)
                setOpenConfirm(true)
              }}
            >
              <Icon>delete</Icon>
            </MDButton>
          </Grid>
        </Grid>
      )
    }
  ]

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <ZUTitleForm bgColor='info' title='Dispositivi Medici' icon='widgets' />
          <Stack direction='row' justifyContent='space-between' p={3} lineHeight={1}>
            <MDButton color='info' component={Link} to='/products/new' m={1}>
              aggiungi
            </MDButton>
            {authService.isAdmin() &&
              <MDButton
                color='success'
                component={MuiLink}
                href={config.SERVER_URL + '/products/download/excel'}
                target='_blank'
                sx={{ ml: 1 }}
              >
                esporta in excel
              </MDButton>}
          </Stack>
          <DataTable table={{ columns: columns, rows: objects }} canSearch />
        </Card>
      </MDBox>
      <Footer />
      <Dialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          Confermi eliminazione?
        </DialogTitle>
        <DialogActions>
          <MDButton onClick={() => setOpenConfirm(false)}>Annulla</MDButton>
          <MDButton onClick={deleteObject} autoFocus>Conferma</MDButton>
        </DialogActions>
      </Dialog>
      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>

  )
}
