'use strict'
import { useEffect, useContext, useState } from 'react'
// @mui material components
import Autocomplete from '@mui/material/Autocomplete'
import Card from '@mui/material/Card'
import Icon from '@mui/material/Icon'
// import Grid from '@mui/material/Grid'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { Link as MuiLink, Stack } from '@mui/material'
// import Menu from '@mui/material/Menu'
// import MenuItem from '@mui/material/MenuItem'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
// import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
// import MDDropdown from 'components/MDDropdown'

// Material Dashboard 2 PRO React example components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import DataTable from 'components/Tables/DataTable'

// zerouno
import authService from 'services/auth.service'
import customerService from 'services/customer.service'
import mainService from 'services/order.service'
import enumerationService from 'services/enumeration.service'
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import utils from 'utils/utils.js'
import RabbitContext from 'context/rabbit.js'
import { config } from 'variables/Config.js'

export default function OrderTable () {
  const location = useLocation()

  const [objId, setObjId] = useState(0)
  const { idCustomer } = useParams()
  // const [enums, setEnums] = React.useState([])
  const [obj, setObj] = useState([])
  const [objects, setObjects] = useState([])
  const [filtered, setFiltered] = useState([])
  const [showSnackbar, setShowSnackbar] = useState({
    sh: false,
    msg: '',
    color: 'success'
  })
  const [openConfirm, setOpenConfirm] = useState(false)
  const {
    data: enums,
    isLoading: loadEnum,
    error: errEnums
  } = enumerationService.findByType('orderState')
  const { rabbit } = useContext(RabbitContext)
  const sales = useLocation().pathname.includes('sales')
  const [year, setYear] = useState(new Date().getFullYear())
  const [stato, setStato] = useState('tutti')

  useEffect(() => {
    fetchObjects()
  }, [sales, location, year])

  const fetchObjects = () => {
    if (idCustomer) {
      customerService
        .get(idCustomer)
        .then(response => {
          setObj(response.data)
        })
        .then(() => {
          return mainService.findByCustomer(idCustomer, sales)
        })
        .then(response2 => {
          setObjects(response2.data.filter(s => !(!rabbit.black && s.code === '50')))
          setFiltered(response2.data.filter(s => !(!rabbit.black && s.code === '50')))
        })
        .catch(error => {
          setShowSnackbar({
            sh: true,
            msg: error.response
              ? error.response.data.message
              : 'errore di ricezione dati',
            color: 'error'
          })
        })
    } else {
      mainService
        .getAll(rabbit.companyId, sales ? '1' : '0', year)
        .then(response => {
          setObjects(response.data.filter(s => !(!rabbit.black && s.code === '50')))
          setFiltered(response.data.filter(s => !(!rabbit.black && s.code === '50')))
          setStato('tutti')
        })
        .catch(error => {
          setShowSnackbar({
            sh: true,
            msg: error.response
              ? error.response.data.message
              : 'errore di ricezione dati',
            color: 'error'
          })
        })
    }
  }

  // Delete
  const deleteObject = () => {
    setOpenConfirm(false)
    mainService.remove(objId).then(
      response => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
        fetchObjects()
      },
      error => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const handleStato = (value) => {
    // options={['tutti', 'prossimi invii', 'prossimi ritiri', 'in consegna']}
    const ordini = objects.filter(s => !(!rabbit.black && s.code === '50'))
    if (value === 'tutti') {
      setFiltered(ordini)
    }
    if (value === 'prossimi invii') {
      setFiltered(ordini.filter(item => item.stateId === 1))
    }
    if (value === 'prossimi ritiri') {
      setFiltered(ordini.filter(item => item.stateId === 3 && !item.sale))
    }
    if (value === 'in consegna') {
      setFiltered(ordini.filter(item => item.stateId === 2))
    }
    setStato(value)
  }

  const columns = [
    {
      Header: 'cliente',
      accessor: row =>
        row.customer.surname.toUpperCase() +
        ' ' +
        row.customer.name.toUpperCase()
    },
    {
      Header: 'dottore',
      accessor: d => {
        return d.doctor ? d.doctor.surname + ' ' + d.doctor.name : ''
      }
    },
    {
      Header: 'numero',
      accessor: d => {
        return d.number + ' ' + d.code + (d.extension ? ' [ext]' : '')
      }
    },
    {
      Header: 'stato',
      accessor: row =>
        enumerationService.getEnumerationDescription(enums, row.stateId)
    },
    {
      Header: 'data',
      accessor: d => {
        return utils.formatDate(d.dateOrder, 'dd/MM/yyyy')
      }
    },
    {
      Header: sales ? 'consegna' : 'inizio',
      accessor: d => {
        return d.dateBegin ? utils.formatDate(d.dateBegin, 'dd/MM/yyyy') : '-'
      }
    },
    ...(!sales
      ? [
          {
            Header: 'giorni',
            accessor: 'quantity'
          }
        ]
      : []),
    {
      Header: 'prezzo',
      accessor: 'price',
      Cell: ({ value, row }) => utils.formatFloat(value.toFixed(2))
    },
    {
      Header: 'totale',
      accessor: 'totalAmount',
      Cell: ({ value, row }) => utils.formatFloat(value.toFixed(2))
    },
    {
      Header: 'da pagare',
      accessor: 'toPay',
      Cell: ({ value, row }) => utils.formatFloat(value.toFixed(2))
    },
    {
      Header: 'seriale',
      accessor: row =>
        row.product
          ? row.product.serial +
          ' | ' +
          row.product.batch +
          ' [' +
          (row.companyId === 1 ? 'T' : 'B') +
          ']'
          : '---'
    },
    ...(authService.isAdmin()
      ? [
          {
            Header: 'agente',
            accessor: d => {
              return d.agent ? d.agent.surname + ' ' + d.agent.name : ''
            }
          },
          {
            Header: 'tecnico',
            accessor: d => {
              return d.technician ? d.technician.surname + ' ' + d.technician.name : ''
            }
          },
          {
            Header: 'utente',
            accessor: row => row.user.username
          }
        ]
      : []),
    {
      Header: 'azioni',
      sortable: false,
      filterable: false,
      width: 100,
      accessor: row => (
        <>
          <MDButton
            color='info'
            size='small'
            iconOnly
            component={Link}
            to={`/customers/${row.customer.id}/orders/${row.id}`}
            sx={{ mr: 1 }}
          >
            <Icon>edit</Icon>
          </MDButton>
          {!row.sale && (
            <MDButton
              color='info'
              size='small'
              iconOnly
              component={Link}
              to={`/customers/${row.customer.id}/orders/${row.id}?extension=1`}
              sx={{ mr: 1 }}
            >
              <Icon>add</Icon>
            </MDButton>
          )}
          <MDButton
            iconOnly
            color='error'
            size='small'
            onClick={() => {
              setObjId(row.id)
              setOpenConfirm(true)
            }}
          >
            <Icon>delete</Icon>
          </MDButton>
        </>
      )
    }
  ]

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <ZUTitleForm
            bgColor='info'
            title={sales ? 'Ordini di vendita' : 'Ordini noleggio'}
            icon='list_alt'
          />
          <MDBox p={3} lineHeight={1}>
            <Stack direction='row' justifyContent='space-between'>
              {idCustomer && (
                <MDButton
                  color='secondary'
                  component={Link}
                  to='/customers'
                  sx={{ m: 1 }}
                >
                  indietro
                </MDButton>
              )}
              {idCustomer && (
                <MDButton
                  color='info'
                  component={Link}
                  to={`/customers/${idCustomer}/orders/new`}
                  m={1}
                >
                  aggiungi
                </MDButton>
              )}
              {!idCustomer && authService.isAdmin() && (
                <MDButton
                  color='success'
                  component={MuiLink}
                  href={
                  config.SERVER_URL +
                  '/orders/download/excel?companyId=' +
                  rabbit.companyId
                }
                  target='_blank'
                >
                  esporta in excel
                </MDButton>
              )}
              {!idCustomer &&
                <Autocomplete
                  disablePortal
                  id='stato'
                  value={stato}
                  options={['tutti', 'prossimi invii', 'prossimi ritiri', 'in consegna']}
                  sx={{ width: 300 }}
                  renderInput={(params) => <MDInput {...params} label='stato' />}
                  onChange={(event, value) => {
                    handleStato(value)
                  }}
                />}
              {!idCustomer &&
                <Autocomplete
                  disablePortal
                  id='anno'
                  value={year}
                  options={[2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030]}
                  sx={{ width: 300 }}
                  renderInput={(params) => <MDInput {...params} label='Anno' />}
                  onChange={(event, value) => {
                    setYear(value)
                  }}
                />}

            </Stack>
          </MDBox>
          <DataTable table={{ columns: columns, rows: filtered }} canSearch />
        </Card>
      </MDBox>
      <Footer />
      <Dialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          Confermi eliminazione?
        </DialogTitle>
        <DialogActions>
          <MDButton onClick={() => setOpenConfirm(false)}>Annulla</MDButton>
          <MDButton onClick={deleteObject} autoFocus>
            Conferma
          </MDButton>
        </DialogActions>
      </Dialog>
      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>
  )
}
