import { useState, useEffect, useContext } from 'react'
// @material-ui/core components
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
// import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
// import Select from '@mui/material/Select'
// import Icon from '@mui/material/Icon'
// import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
// import FormControl from '@mui/material/FormControl'
// import FormHelperText from '@mui/material/FormHelperText'
import FormControlLabel from '@mui/material/FormControlLabel'

// Material Dashboard 2 PRO React components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'

// zerouno
import mainService from 'services/healthDoctor.service'
import healthcareFacilityService from 'services/healthcareFacility.service'
import authService from 'services/auth.service'
import { useForm, Controller } from 'react-hook-form'
import { useParams, Link, useNavigate } from 'react-router-dom'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import ZUSelect from 'components/ZeroUno/ZUSelect'
import RabbitContext from 'context/rabbit.js'

export default function HealthDoctorForm() {
  const { idObj } = useParams()
  const [obj, setObj] = useState({})
  const [showSnackbar, setShowSnackbar] = useState({ sh: false, msg: '', color: 'success' })
  const { register, handleSubmit, formState: { errors }, setError, control, reset } = useForm()
  const navigate = useNavigate()
  const { rabbit } = useContext(RabbitContext)
  const [facilities, setFacilities] = useState([])

  useEffect(() => {
    fetchAccessorsCompany(healthcareFacilityService, setFacilities)
    fetchObject()
  }, [])

  useEffect(() => {
    reset({ ...obj })
  }, [obj])

  const fetchObject = () => {
    if (idObj === 'new') {
      setObj({
        healthcareFacilityId: 'n'
      })
    } else {
      mainService.get(idObj).then(
        (response) => {
          setObj(response.data)
        },
        (error) => {
          setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
        }
      )
    }
  }

  const fetchAccessorsCompany = (service, setFunc) => {
    service.getAll(rabbit.companyId).then(
      (response) => {
        setFunc(response.data)
      },
      (error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      }
    )
  }

  const onSubmit = (data) => {
    data.companyId = rabbit.companyId
    if (data.healthcareFacilityId === 'n') {
      setError('healthcareFacilityId', { type: 'custom', message: 'campo obbligatorio' })
      setShowSnackbar({ sh: true, msg: 'inserire la struttura sanitaria', color: 'error' })
    } else {
      if (idObj === 'new') {
        addObj(data)
      } else {
        data.id = obj.id
        updateObj(data, obj.id)
      }
    }
  }

  // Add obj
  const addObj = (data) => {
    mainService.create(data).then(
      (response) => {
        setShowSnackbar({ sh: true, msg: 'operazione effettuata con successo', color: 'success' })
        navigate('/healthDoctors')
      },
      (error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      }
    )
  }

  const updateObj = (data, id) => {
    mainService.update(id, data).then(
      (response) => {
        setShowSnackbar({ sh: true, msg: 'operazione effettuata con successo', color: 'success' })
        navigate('/healthDoctors')
      },
      (error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      }
    )
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={8}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Card>
                <ZUTitleForm bgColor='info' title='Medico Struttura Sanitaria' icon='medical_information' />
                <MDBox p={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        autoFocus
                        label='nome'
                        error={!!(errors.name)}
                        {...register('name', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        label='cognome'
                        error={!!(errors.surname)}
                        {...register('surname', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <MDInput
                        fullWidth
                        type='text'
                        label='ruolo'
                        error={!!(errors.role)}
                        {...register('role', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MDInput
                        fullWidth
                        type='text'
                        required={false}
                        label='telefono'
                        error={!!(errors.phone)}
                        {...register('phone', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MDInput
                        fullWidth
                        type='text'
                        required={false}
                        label='cellulare'
                        error={!!(errors.mobile)}
                        {...register('mobile', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MDInput
                        fullWidth
                        type='mail'
                        label='mail'
                        error={!!(errors.mail)}
                        helperText={errors.mail && errors.mail.message}
                        {...register('mail', {
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: 'mail non valida'
                          }
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      {obj.healthcareFacilityId && (
                        <ZUSelect
                          error={!!errors.healthcareFacilityId}
                          label='struttura sanitaria'
                          defaultValue={obj.healthcareFacilityId}
                          register={register}
                          name='healthcareFacilityId'
                          required
                          rules={{ required: true }}
                        >
                          <MenuItem key='0' value='n'>seleziona una struttura</MenuItem>
                          {facilities && facilities.map((item) =>
                            <MenuItem key={item.id} value={item.id}>{item.name} - {item.department}</MenuItem>
                          )}
                        </ZUSelect>
                      )}
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                      <Controller
                        control={control}
                        name='staff'
                        render={({ field: { value, onChange } }) => (
                          <FormControlLabel
                            control={<Switch checked={value || false} onChange={onChange} />}
                            label='staff'
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <MDInput
                        fullWidth
                        type='text'
                        required={false}
                        label='note'
                        error={!!(errors.notes)}
                        {...register('notes', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} />
                  </Grid>
                </MDBox>
                <MDBox p={2}>
                  <MDButton
                    variant='gradient' color='secondary' component={Link} to='/healthDoctors/'
                  >
                    Indietro
                  </MDButton>
                  <MDButton type='submit' variant='gradient' color='success' sx={{ ml: 1 }}>
                    Salva
                  </MDButton>
                </MDBox>
              </Card>
            </form>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Card>
              <MDBox p={2}>
                <MDTypography variant='h6'>Struttura Sanitaria</MDTypography>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>
  )
}
