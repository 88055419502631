'use strict'
import { useEffect, useContext, useState } from 'react'
// @mui material components
import Card from '@mui/material/Card'
import Icon from '@mui/material/Icon'
// import Grid from '@mui/material/Grid'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
// import CheckBoxIcon from '@mui/icons-material/CheckBox'
// import { Link as MuiLink } from '@mui/material'
// import Menu from '@mui/material/Menu'
// import MenuItem from '@mui/material/MenuItem'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
// import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'
import MDButton from 'components/MDButton'
import MDBadgeDot from 'components/MDBadgeDot'

// Material Dashboard 2 PRO React example components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import DataTable from 'components/Tables/DataTable'

// zerouno
import mainService from 'services/sponsorship.service'
import { useParams, Link } from 'react-router-dom'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import utils from 'utils/utils.js'
import RabbitContext from 'context/rabbit.js'
import MDTypography from 'components/MDTypography'

export default function SposorshipTable () {
  const [objId, setObjId] = useState(0)
  const { idCustomer } = useParams()
  // const [enums, setEnums] = React.useState([])
  const [obj, setObj] = useState([])
  const [objects, setObjects] = useState([])
  const [showSnackbar, setShowSnackbar] = useState({ sh: false, msg: '', color: 'success' })
  const [openConfirm, setOpenConfirm] = useState(false)
  const { rabbit } = useContext(RabbitContext)

  useEffect(() => {
    fetchObjects()
  }, [])

  const fetchObjects = () => {
    mainService.getAll(rabbit.companyId).then(
      (response) => {
        setObjects(response.data)
      })
      .catch((error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      })
  }

  const deleteObject = () => {
    setOpenConfirm(false)
    mainService.remove(objId).then(
      (response) => {
        setShowSnackbar({ sh: true, msg: 'operazione effettuata con successo', color: 'success' })
        fetchObjects()
      },
      (error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      }
    )
  }

  const columns = [
    {
      Header: 'rag. soc',
      accessor: (row) => row.customer.businessName.toUpperCase()
    },
    {
      Header: 'nominativo',
      accessor: (row) => row.customer.surname.toUpperCase() + ' ' + row.customer.name.toUpperCase()
    },
    {
      Header: 'dispositivi',
      accessor: 'number'
    },
    {
      Header: 'inizio',
      accessor: d => {
        return d.startOn ? utils.formatDate(d.startOn, 'dd/MM/yyyy') : '-'
      }
    },
    {
      Header: 'fine',
      accessor: d => {
        // return expired date (endOn) and format as red if expired
        return (
          <MDBadgeDot
            badgeContent={utils.formatDate(d.endOn, 'dd/MM/yyyy')}
            color={utils.expiredDate(d.endOn) ? 'error' : 'info'}
            size='md'
          />
        )
      }
    },
    {
      Header: 'azioni',
      sortable: false,
      filterable: false,
      width: 100,
      accessor: (row) => (
        <>
          <MDButton
            color='info'
            size='small'
            iconOnly
            component={Link}
            to={`/sponsorships/${row.id}`}
            sx={{ mr: 1 }}
          >
            <Icon>edit</Icon>
          </MDButton>
          <MDButton
            iconOnly
            color='error'
            size='small'
            onClick={() => {
              setObjId(row.id)
              setOpenConfirm(true)
            }}
          >
            <Icon>delete</Icon>
          </MDButton>
        </>
      )
    }
  ]

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <ZUTitleForm bgColor='info' title='Sponsorizzazioni' icon='card_giftcard' />
          <MDBox p={3} lineHeight={1}>
            <MDButton color='info' component={Link} to='/sponsorships/new' m={1}>
              aggiungi
            </MDButton>
          </MDBox>
          <DataTable table={{ columns: columns, rows: objects }} canSearch />
        </Card>
      </MDBox>
      <Footer />
      <Dialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          Confermi eliminazione?
        </DialogTitle>
        <DialogActions>
          <MDButton onClick={() => setOpenConfirm(false)}>Annulla</MDButton>
          <MDButton onClick={deleteObject} autoFocus>Conferma</MDButton>
        </DialogActions>
      </Dialog>
      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>

  )
}
