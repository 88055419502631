'use strict'

import http from './http-common'
import authHeader from './auth-header'
// import useSWR from 'swr'

const getAll = (orderId) => {
  return http.axiosClient.get(`/gls?orderId=${orderId}`, {
    headers: authHeader()
  })
}

const get = (id) => {
  return http.axiosClient.get(`/gls/${id}`, {
    headers: authHeader()
  })
}

const create = (data) => {
  return http.axiosClient.post('/gls', data, {
    headers: authHeader()
  })
}

const update = (id, data) => {
  return http.axiosClient.put(`/gls/${id}`, data, {
    headers: authHeader()
  })
}

const remove = (id) => {
  return http.axiosClient.delete(`/gls/${id}`, {
    headers: authHeader()
  })
}

const confirm = (id) => {
  const data = {}
  return http.axiosClient.post(`/gls/confirm/${id}`, data, {
    headers: authHeader()
  })
}

export default {
  getAll,
  get,
  create,
  update,
  remove,
  confirm
}
