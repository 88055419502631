'use strict'

import http from './http-common'
import authHeader from './auth-header'
import useSWR from 'swr'

const uploadOne = (data) => {
  return http.axiosClient.post('/uploadOne', data, { headers: authHeader() })
}

const uploadMany = (data) => {
  return http.axiosClient.post('/uploadMany', data, { headers: authHeader() })
}

const downloadFile = (file) => {
  return http.axiosClient.get(`/download/${file}`, { headers: authHeader() })
}

export default {
  uploadOne,
  uploadMany,
  downloadFile
}
