'use strict'

import { useEffect, useState, useContext } from 'react'
// @material-ui/core components
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import Select from '@mui/material/Select'
import Dialog from '@mui/material/Dialog'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
// import Icon from '@mui/material/Icon'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import { Link as MuiLink } from '@mui/material'
// import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
// import FormHelperText from '@mui/material/FormHelperText'
import FormControlLabel from '@mui/material/FormControlLabel'

// Material Dashboard 2 PRO React components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import MDAlert from 'components/MDAlert'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'
// import SelectZeroUno from 'components/ZeroUno/SelectZeroUno'

// zerouno
import customerService from 'services/customer.service'
import productModelService from 'services/productModel.service'
import productionService from 'services/production.service'
import enumerationService from 'services/enumeration.service'
import authService from 'services/auth.service'
import { useForm, Controller } from 'react-hook-form'
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import ZUAutoComplete from 'components/ZeroUno/ZUAutoComplete'
import ZUSelect from 'components/ZeroUno/ZUSelect'
import utils from 'utils/utils'
import { DateTime } from 'luxon'
import RabbitContext from 'context/rabbit.js'
import { config } from 'variables/Config.js'
import SignatureCanvas from 'react-signature-canvas'

export default function OrderForm() {
  const { idObj } = useParams()
  const { idCustomer } = useParams()
  const [obj, setObj] = useState({})
  const [productsModel, setProductsModel] = useState([])
  const [customers, setCustomers] = useState([])
  const [total, setTotal] = useState(0)
  const [showSnackbar, setShowSnackbar] = useState({
    sh: false,
    msg: '',
    color: 'success'
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
    setError,
    reset,
    watch
  } = useForm()
  const navigate = useNavigate()
  const { rabbit } = useContext(RabbitContext)
  const [openDlg, setOpenDlg] = useState(false)

  const watchFields = watch([
    'amount',
    'vat',
    'quantity',
    'paid'
  ])

  useEffect(() => {
    fetchAccessors(productModelService, setProductsModel)
    fetchCustomers()
  }, [])

  useEffect(() => {
    fetchObject()
  }, [customers, productsModel])

  useEffect(() => {
    reset({ ...obj })
    setValue('dateDdt', DateTime.fromISO(obj.dateDdt).toISODate())
  }, [obj])

  useEffect(() => {
    calculateTotal()
  }, [watchFields])

  const calculateTotal = () => {
    const amount = String(watchFields[0]).replace(',', '.')
    const vat = watchFields[1]
    const qta = watchFields[2]
    const paid = watchFields[3]
    const total = amount * qta * (1 + vat / 100)
    const toPay = total - paid
    setValue('total', total.toFixed(2).replace('.', ','))
    setValue('toPay', toPay.toFixed(2).replace('.', ','))
  }

  const fetchCustomers = () => {
    customerService
      .getAll(rabbit.companyId, true)
      .then((response) => {
        setCustomers(response.data)
      })
      .catch((error) => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      })
  }

  const fetchAccessors = (service, setFunc) => {
    service.getAll().then(
      (response) => {
        setFunc(response.data)
      },
      (error) => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const fetchObject = () => {
    if (idObj === 'new') {
      setObj({
        ddt: '',
        dateDdt: DateTime.now(),
        customerId: idCustomer || 'n',
        customer: customers.find(val => val.id === idCustomer),
        productModelId: 'n',
        productModel: null,
        amount: 0.0,
        vat: 22,
        paid: 0.0,
        serials: '',
        quantity: 1
      })
    } else {
      productionService
        .get(idObj)
        .then(
          (response) => {
            response.data.amount = response.data.amount.toFixed(2).replace(',', '').replace('.', ',')
            setObj(response.data)
          })
        , (error) => {
          setShowSnackbar({
            sh: true,
            msg: error.response
              ? error.response.data.message
              : 'errore di ricezione dati',
            color: 'error'
          })
        }
    }
  }

  const onSubmit = (data) => {
    data.amount = String(data.amount).replace(',', '.')
    data.vat = String(data.vat).replace(',', '.')
    data.paid = String(data.paid).replace(',', '.')
    if (idObj === 'new') {
      addObj(data)
    } else {
      data.id = obj.id
      updateObj(data, obj.id)
    }
  }

  // Add obj
  const addObj = (data) => {
    // data.image = obj.image
    productionService.create(data).then(
      (response) => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
        navigate('/productions')
      },
      (error) => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const updateObj = (data, id) => {
    // data.image = obj.image
    productionService.update(id, data).then(
      (response) => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
        navigate('/productions')
      },
      (error) => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Card>
                <ZUTitleForm bgColor='info' title='Produzione' icon='list_alt' />

                <MDBox p={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                      {obj.customerId && (
                        <ZUAutoComplete
                          name='customerId'
                          control={control}
                          required={false}
                          rules={{ required: false }}
                          options={customers}
                          defaultValue={obj.customer || null}
                          getOptionLabel={(item) => item.businessName + ' [' + item.name + ' ' + item.surname + ']'}
                          label='Fornitore'
                          error={!!errors.customerId}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        label='numero ddt'
                        error={!!errors.ddt}
                        {...register('ddt', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <MDInput
                        fullWidth
                        type='date'
                        required
                        label='data ddt'
                        error={!!errors.dateDdt}
                        {...register('dateDdt', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      {obj.productModelId && (
                        <ZUAutoComplete
                          name='productModelId'
                          control={control}
                          required={false}
                          rules={{ required: false }}
                          options={productsModel}
                          defaultValue={obj.productModel || null}
                          getOptionLabel={(item) => item.name}
                          label='Dispositivo'
                          error={!!errors.productModelId}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <MDInput
                        fullWidth
                        type='text'
                        label='seriali'
                        multiline
                        rows={4}
                        error={!!errors.serials}
                        {...register('serials', { required: false })}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={2}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        label='quantità'
                        error={!!errors.quantity}
                        {...register('quantity', {
                          required: true,
                          pattern: utils.integerPattern
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        label='prezzo unitario'
                        {...register('amount', {
                          required: true,
                          pattern: utils.floatPattern
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      {obj.vat && (
                        <FormControl fullWidth required error={!!errors.vat}>
                          <InputLabel>iva</InputLabel>
                          <Select
                            label='iva'
                            defaultValue={obj.vat}
                            {...register('vat', { required: true })}
                          >
                            <MenuItem key={0} value={0.0}>
                              0 %
                            </MenuItem>
                            <MenuItem key={4} value={4.0}>
                              4 %
                            </MenuItem>
                            <MenuItem key={10} value={10.0}>
                              10 %
                            </MenuItem>
                            <MenuItem key={22} value={22.0}>
                              22 %
                            </MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <MDInput
                        fullWidth
                        type='text'
                        disabled
                        label='totale'
                        {...register('total')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        label='saldato'
                        {...register('paid', {
                          required: true,
                          pattern: utils.floatPattern
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <MDInput
                        fullWidth
                        type='text'
                        disabled
                        label='toPay'
                        {...register('toPay')}
                      />
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox p={2}>
                  <MDButton
                    variant='gradient'
                    color='secondary'
                    component={Link}
                    to='/productions'
                    sx={{ m: 1 }}
                  >
                    Indietro
                  </MDButton>
                  <MDButton
                    type='submit'
                    variant='gradient'
                    color='success'
                    sx={{ m: 1 }}
                  >
                    Salva
                  </MDButton>
                </MDBox>
              </Card>
            </form>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
      <Dialog
        fullScreen
        open={openDlg}
        onClose={() => setOpenDlg(false)}
        sx={{ background: '#f1f1f1' }}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge='start'
              color='inherit'
              onClick={() => setOpenDlg(false)}
              aria-label='close'
            >
              <CloseIcon />
            </IconButton>
            <MDTypography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
              Firma
            </MDTypography>
            <MDButton
              color='secondary'
              onClick={(e) => clearCanvas()}
              sx={{ m: 1 }}
            >
              pulisci
            </MDButton>
            <MDButton
              autoFocus
              color='success'
              onClick={(e) => trimCanvas()}
              sx={{ m: 1 }}
            >
              salva
            </MDButton>
          </Toolbar>
        </AppBar>
        <MDBox
          p={2}
          bgColor='#cccccc'
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          <MDBox bgColor='#FFFFFF' width='300px' height='200px'>
            <SignatureCanvas
              canvasProps={{ width: '300', height: '200' }}
              penColor='#00ADEF'
              ref={(ref) => {
                setSigPad(ref)
              }}
            />
          </MDBox>
        </MDBox>
      </Dialog>
    </DashboardLayout>
  )
}
