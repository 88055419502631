'use strict'
import { useEffect, useContext, useState } from 'react'
// @mui material components
import Card from '@mui/material/Card'
import Icon from '@mui/material/Icon'
import Grid from '@mui/material/Grid'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Paper from '@mui/material/Paper'
// import CheckBoxIcon from '@mui/icons-material/CheckBox'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDSnackbar from 'components/MDSnackbar'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'
import breakpoints from 'assets/theme/base/breakpoints'

// Material Dashboard 2 PRO React example components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import DataTable from 'components/Tables/DataTable'

// zerouno
import { useForm, Controller } from 'react-hook-form'

import mainService from 'services/order.service'
import authService from 'services/auth.service'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import utils from 'utils/utils.js'
import RabbitContext from 'context/rabbit.js'
import { DateTime } from 'luxon'

export default function Statistics() {
  const [tabsOrientation, setTabsOrientation] = useState('horizontal')
  const [stats01, setStats01] = useState([])
  const [stats02, setStats02] = useState([])
  const [stats03, setStats03] = useState([])
  const [stats04, setStats04] = useState([])
  const [stats05, setStats05] = useState([])
  const [stats06, setStats06] = useState([])
  const [showSnackbar, setShowSnackbar] = useState({ sh: false, msg: '', color: 'success' })
  const { rabbit } = useContext(RabbitContext)
  const { register, handleSubmit, formState: { errors }, watch, reset } = useForm()
  const [tab, setTab] = useState(0)

  useEffect(() => {
    const from = DateTime.local().startOf('day').startOf('year').toISODate()
    const to = DateTime.local().endOf('day').toISODate()
    fetchStats01(from, to)
    fetchStats02(from, to)
    fetchStats03(from, to)
    fetchStats04(from, to)
    fetchStats05(from, to)
    fetchStats06(from, to)
    reset({
      from: from,
      to: to
    })
  }, [rabbit.companyId])

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation('vertical')
        : setTabsOrientation('horizontal')
    }

    /**
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener('resize', handleTabsOrientation)

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation()

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleTabsOrientation)
  }, [tabsOrientation])

  const fetchStats01 = (from, to) => {
    mainService.statsProduct(rabbit.companyId, from, to).then(
      (response) => {
        setStats01(response.data)
      })
      .catch((error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      })
  }
  const fetchStats02 = (from, to) => {
    mainService.statsPathology(rabbit.companyId, from, to).then(
      (response) => {
        setStats02(response.data)
      })
      .catch((error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      })
  }
  const fetchStats03 = (from, to) => {
    mainService.statsDoctor(rabbit.companyId, from, to).then(
      (response) => {
        setStats03(response.data)
      })
      .catch((error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      })
  }
  const fetchStats04 = (from, to) => {
    mainService.statsAgent(rabbit.companyId, from, to).then(
      (response) => {
        setStats04(response.data)
      })
      .catch((error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      })
  }

  const fetchStats05 = (from, to) => {
    mainService.statsByUser(rabbit.companyId, from, to).then(
      (response) => {
        setStats05(response.data)
      })
      .catch((error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      })
  }

  const fetchStats06 = (from, to) => {
    mainService.statsTechnician(rabbit.companyId, from, to).then(
      (response) => {
        setStats06(response.data)
      })
      .catch((error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      })
  }

  const onSubmit = (data) => {
    fetchStats01(data.from, data.to)
    fetchStats02(data.from, data.to)
    fetchStats03(data.from, data.to)
    fetchStats04(data.from, data.to)
    fetchStats05(data.from, data.to)
    fetchStats06(data.from, data.to)
  }

  const handleChangeTab = (event, newValue) => {
    setTab(newValue)
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && (
          <MDBox sx={{ p: 3 }}>
            {children}
          </MDBox>
        )}
      </div>
    )
  }

  const columns = [
    {
      Header: 'nome',
      accessor: 'name'
    },
    {
      Header: 'euro',
      accessor: 'euros',
      Cell: ({ value, row }) => utils.formatFloat(value.toFixed(2))
    },
    {
      Header: 'ordini',
      accessor: 'num'
    },
    {
      Header: 'giorni',
      accessor: 'days'
    }
  ]

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <ZUTitleForm bgColor='info' title='Statistiche Ordini' icon='leaderboard' />
            <MDBox p={3} lineHeight={1}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={3}>
                  <MDInput
                    fullWidth
                    type='date'
                    required
                    label='dalla data'
                    error={!!(errors.from)}
                    {...register('from', { required: true })}
                  />

                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <MDInput
                    fullWidth
                    type='date'
                    required
                    label='alla data'
                    error={!!(errors.to)}
                    {...register('to', { required: true })}
                  />

                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <MDButton type='submit' variant='gradient' color='success' sx={{ ml: 1 }}>
                    calcola
                  </MDButton>
                </Grid>
              </Grid>

            </MDBox>
            <MDBox p={3} lineHeight={1}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <Tabs
                    orientation={tabsOrientation}
                    value={tab}
                    onChange={handleChangeTab}
                  // sx={{ bgcolor: '#5EB562', ml: 3 }}
                  >
                    <Tab label='prodotti' />
                    <Tab label='patologie' />
                    <Tab label='dottori' />
                    {authService.isAdmin() &&
                      <Tab label='agenti' />}
                    {authService.isAdmin() &&
                      <Tab label='tecnici' />}
                    {authService.isAdmin() &&
                      <Tab label='utenti' />}
                  </Tabs>
                </Grid>
              </Grid>
              <TabPanel value={tab} index={0}>
                <Paper elevation={3}>
                  <DataTable table={{ columns: columns, rows: stats01 }} canSearch />
                </Paper>
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <Paper elevation={3}>
                  <DataTable table={{ columns: columns, rows: stats02 }} canSearch />
                </Paper>
              </TabPanel>
              <TabPanel value={tab} index={2}>
                <Paper elevation={3}>
                  <DataTable table={{ columns: columns, rows: stats03 }} canSearch />
                </Paper>
              </TabPanel>
              <TabPanel value={tab} index={3}>
                <Paper elevation={3}>
                  <DataTable table={{ columns: columns, rows: stats04 }} canSearch />
                </Paper>
              </TabPanel>
              <TabPanel value={tab} index={4}>
                <Paper elevation={3}>
                  <DataTable table={{ columns: columns, rows: stats06 }} canSearch />
                </Paper>
              </TabPanel>
              <TabPanel value={tab} index={5}>
                <Paper elevation={3}>
                  <DataTable table={{ columns: columns, rows: stats05 }} canSearch />
                </Paper>
              </TabPanel>
            </MDBox>
          </Card>
        </form>
      </MDBox>
      <Footer />

      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>

  )
}
