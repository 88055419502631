'use strict'

import { useEffect, useContext, useState } from 'react'
// @material-ui/core components
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import FormControlLabel from '@mui/material/FormControlLabel'

// Material Dashboard 2 PRO React components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'

// zerouno
import customerService from 'services/customer.service'
import invoiceService from 'services/invoice.service'
import invoiceTransactionService from 'services/invoiceTransaction.service'
import enumerationService from 'services/enumeration.service'
import { useForm, Controller } from 'react-hook-form'
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import utils from 'utils/utils'
import { DateTime } from 'luxon'
import RabbitContext from 'context/rabbit.js'

export default function InvoiceTransactionForm() {
  const { idObj } = useParams()
  const { idCustomer } = useParams()
  const [invoiceId, setInvoiceId] = useState(new URLSearchParams(useLocation().search).get('invoiceId'))
  const { data: enums, isLoading: loadEnum, error: errEnums } = enumerationService.findByType('account')
  const [showSnackbar, setShowSnackbar] = useState({ sh: false, msg: '', color: 'success' })
  const navigate = useNavigate()
  const { rabbit } = useContext(RabbitContext)
  const { register, handleSubmit, formState: { errors }, watch, control, getValues, setValue, reset } = useForm()
  const [customer, setCustomer] = useState([])
  const [invoices, setInvoices] = useState([])
  const [invoice, setInvoice] = useState({})
  const [obj, setObj] = useState({})

  const invoiceIdWatch = watch('invoiceId')
  const accountIdWatch = watch('accountId')

  useEffect(() => {
    fetchAll()
  }, [])

  const fetchAll = () => {
    if (idObj === 'new') {
      customerService.get(idCustomer).then(
        (response) => {
          setCustomer(response.data)
        }).then(() => {
          invoiceService.findByCustomer(idCustomer).then(
            (response1) => {
              setInvoices(response1.data)
              let myInvoice = {}
              if (invoiceId) {
                myInvoice = utils.getObjectFromArray(response1.data, invoiceId)
                setInvoice(myInvoice)
              }
              setObj({
                invoiceId: invoiceId || 'n',
                accountId: '31',
                dateTransaction: DateTime.now(),
                amount: invoiceId ? utils.formatFloat(myInvoice.totalAmount - myInvoice.paid) : '0,0'
              })
            }).catch((error) => {
              setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
            })
        }).catch((error) => {
          setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
        })
    } else {
      invoiceTransactionService.get(idObj).then(
        (response1) => {
          response1.data.amount = utils.formatFloat(response1.data.amount)
          return response1.data
        }).then(trans => {
          customerService.get(idCustomer).then(
            (response2) => {
              setCustomer(response2.data)
              return trans
            }).then(trans => {
              invoiceService.findByCustomer(idCustomer).then(
                (response3) => {
                  setInvoices(response3.data)
                  setInvoice(utils.getObjectFromArray(response3.data, trans.invoiceId))
                  setObj(trans)
                }).catch((error) => {
                  setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
                })
            })
        })
    }
  }

  useEffect(() => {
    reset({ ...obj, accountId: accountIdWatch })
    setValue('dateTransaction', DateTime.fromISO(obj.dateTransaction).toISODate())
    setValue('amount', utils.formatFloat(obj.amount))
  }, [obj])

  useEffect(() => {
    const myInvoice = utils.getObjectFromArray(invoices, invoiceIdWatch)
    setInvoice(myInvoice)
    setObj({
      ...obj,
      invoiceId: myInvoice ? myInvoice.id : 'n',
      amount: myInvoice.totalAmount - myInvoice.paid
    })
  }, [invoiceIdWatch])

  useEffect(() => {
    setObj({ ...obj, accountId: accountIdWatch })
  }, [accountIdWatch])

  const onSubmit = (data) => {
    data.amount = data.amount.replace(',', '.')
    if (idObj === 'new') {
      addObj(data)
    } else {
      data.id = obj.id
      updateObj(data, obj.id)
    }
  }

  // Add obj
  const addObj = (data) => {
    invoiceTransactionService.create(data).then(
      (response) => {
        setShowSnackbar({ sh: true, msg: 'operazione effettuata con successo', color: 'success' })
        navigate(`/suppliers/${idCustomer}/invoiceTransactions`)
      },
      (error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      }
    )
  }

  const updateObj = (data, id) => {
    // data.image = obj.image
    invoiceTransactionService.update(id, data).then(
      (response) => {
        setShowSnackbar({ sh: true, msg: 'operazione effettuata con successo', color: 'success' })
        navigate(`/suppliers/${idCustomer}/invoiceTransactions`)
      },
      (error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      }
    )
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={8}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Card>
                <ZUTitleForm bgColor='info' title='Pagamento Fattura' icon='euro' />
                <MDBox p={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      {customer && (
                        <MDTypography>fornitore {customer.surname} {customer.name} {customer.businessName}</MDTypography>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      {obj.invoiceId && (
                        <FormControl
                          fullWidth
                          required
                          error={!!errors.invoiceId}
                        >
                          <InputLabel>Fattura</InputLabel>
                          <Select
                            label='Fattura'
                            value={obj.invoiceId}
                            {...register('invoiceId', { required: true })}
                          >
                            {invoices && invoices.map((item) =>
                              <MenuItem key={item.id} value={item.id}>n. {item.number} da pagare:  € {utils.formatFloat(item.totalAmount - item.paid)}/{utils.formatFloat(item.totalAmount)}</MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <MDInput
                        fullWidth
                        type='date'
                        required
                        label='data'
                        error={!!(errors.dateTransaction)}
                        {...register('dateTransaction', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        label='importo'
                        error={!!(errors.amount)}
                        helperText={errors.amount && 'dati non corretti'}
                        {...register('amount', { required: true, pattern: utils.floatPattern })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <FormControl
                        fullWidth
                        required
                        error={!!errors.accountId}
                      >
                        <InputLabel>modalità pagamento</InputLabel>
                        <Select
                          label='modalità pagamento'
                          value={obj.accountId || '31'}
                          {...register('accountId', { required: true })}
                        >
                          {enums && enums.map((item) =>
                            <MenuItem key={item.id} value={item.id}>{item.description}</MenuItem>
                          )}
                        </Select>
                      </FormControl>

                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <MDInput
                        fullWidth
                        type='text'
                        required={false}
                        label='note'
                        error={!!(errors.notes)}
                        {...register('notes', { required: false })}
                      />
                    </Grid>

                  </Grid>
                </MDBox>
                <MDBox p={2}>
                  <MDButton
                    variant='gradient' color='secondary' component={Link} to={`/suppliers/${idCustomer}/invoiceTransactions`}
                  >
                    pagamenti fornitore
                  </MDButton>
                  <MDButton
                    variant='gradient' color='secondary' component={Link} to='/invoiceTransactions' sx={{ ml: 1 }}
                  >
                    tutti i pagamenti
                  </MDButton>
                  <MDButton type='submit' variant='gradient' color='success' sx={{ ml: 1 }}>
                    Salva
                  </MDButton>
                </MDBox>
              </Card>
            </form>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Card>
              <MDBox p={2}>
                <MDTypography variant='h6'>Menù</MDTypography>
                <Stack spacing={2}>
                  <MDButton
                    color='info'
                    component={Link}
                    to={'/suppliers/' + idCustomer}
                  >
                    fornitore
                  </MDButton>
                  <MDButton
                    color='info'
                    component={Link}
                    to={'/suppliers/' + idCustomer + '/invoices'}
                  >
                    fatture
                  </MDButton>
                  <MDButton
                    color='info'
                    component={Link}
                    to={'/suppliers/' + idCustomer + '/invoiceTransactions'}
                  >
                    pagamenti
                  </MDButton>
                </Stack>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>
  )
}
