'use strict'

import http from './http-common'
import authHeader from './auth-header'
import useSWR from 'swr'

const getAll = () => {
  return http.axiosClient.get('/pathologies', { headers: authHeader() })
}

const get = (id) => {
  return http.axiosClient.get(`/pathologies/${id}`, { headers: authHeader() })
}

const create = (data) => {
  return http.axiosClient.post('/pathologies', data, { headers: authHeader() })
}

const update = (id, data) => {
  return http.axiosClient.put(`/pathologies/${id}`, data, { headers: authHeader() })
}

const remove = (id) => {
  return http.axiosClient.delete(`/pathologies/${id}`, { headers: authHeader() })
}

const findByType = (typeId) => {
  return http.axiosClient.get(`/pathologies?typeId=${typeId}`, { headers: authHeader() })
}

export default {
  getAll,
  get,
  create,
  update,
  remove,
  findByType
}
