import React, { useState } from 'react'
import GoogleMapReact from 'google-map-react'
import Tooltip from '@mui/material/Tooltip'
import PlaceIcon from '@mui/icons-material/Place'
import { config } from '../../variables/Config'

const ZUMapView = ({ locations, zoom, centerLat, centerLng, height, width }) => {
  return (
    <div style={{ height: height ?? '500px', width: width ?? '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: config.GOOGLE_MAPS_API }}
        defaultCenter={{ lat: centerLat, lng: centerLng }}
        defaultZoom={zoom}
      >
        {locations.map((location) => (

          <Tooltip
            lat={location.lat}
            lng={location.lng}
            title={location.description}
            arrow
            style={{ position: 'absolute', transform: 'translate(-50%, -100%)' }}
          >
            <PlaceIcon color='info' fontSize='large' />
          </Tooltip>
        ))}
      </GoogleMapReact>
    </div>
  )
}

export default ZUMapView
