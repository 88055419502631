'use strict'

import { useEffect, useState, useContext } from 'react'
// @material-ui/core components
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import Select from '@mui/material/Select'
import Dialog from '@mui/material/Dialog'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
// import Icon from '@mui/material/Icon'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import { Link as MuiLink } from '@mui/material'
// import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
// import FormHelperText from '@mui/material/FormHelperText'
import FormControlLabel from '@mui/material/FormControlLabel'

// Material Dashboard 2 PRO React components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import MDAlert from 'components/MDAlert'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'
import DataTable from 'components/Tables/DataTable'

// import SelectZeroUno from 'components/ZeroUno/SelectZeroUno'

// zerouno
import customerService from 'services/customer.service'
import invoiceService from 'services/invoice.service'
import authService from 'services/auth.service'
import { useForm, Controller } from 'react-hook-form'
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import utils from 'utils/utils'
import { DateTime } from 'luxon'
import RabbitContext from 'context/rabbit.js'
import { config } from 'variables/Config.js'
import SignatureCanvas from 'react-signature-canvas'

export default function InvoiceForm() {
  const { idObj } = useParams()
  const { idCustomer } = useParams()
  const [obj, setObj] = useState({
    taxable: 0.0,
    vat: 0.0,
    totalAmount: 0.0,
    paid: 0.0,
    invoiceRows: []
  })
  const [showSnackbar, setShowSnackbar] = useState({
    sh: false,
    msg: '',
    color: 'success'
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
    setError,
    reset,
    watch
  } = useForm()
  const navigate = useNavigate()
  const { rabbit } = useContext(RabbitContext)
  const [openDlg, setOpenDlg] = useState(false)

  useEffect(() => {
    fetchObject()
  }, [])

  const fetchObject = () => {
    invoiceService
      .get(idObj)
      .then(
        (response) => {
          setObj(response.data)
        })
      , (error) => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
  }

  const onSubmit = (data) => {
    if (idObj === 'new') {
      addObj(data)
    } else {
      data.id = obj.id
      updateObj(data, obj.id)
    }
  }

  // Add obj
  const addObj = (data) => {
    // data.image = obj.image
    invoiceService.create(data).then(
      (response) => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
        navigate('/invoices')
      },
      (error) => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const updateObj = (data, id) => {
    // data.image = obj.image
    invoiceService.update(id, data).then(
      (response) => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
        navigate('/invoices')
      },
      (error) => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const columns = [
    {
      Header: 'codice',
      accessor: 'code'
    },
    {
      Header: 'descrizione',
      accessor: 'description'
    },
    {
      Header: 'quantità',
      accessor: d => d.quantity
    },
    {
      Header: 'imponibile.',
      accessor: d => d.taxable.toFixed(2).replace('.', ',')
    },
    {
      Header: 'iva',
      accessor: d => d.vat.toFixed(2).replace('.', ',') + ' %'
    },
    {
      Header: 'totale',
      accessor: d => d.totalAmount.toFixed(2).replace('.', ',')
    }
  ]

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Card>
                <ZUTitleForm bgColor='info' title='Fattura' icon='list_alt' />

                <MDBox p={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                      <MDTypography variant='inherited'>{obj.creditNote ? 'Nota Credito' : 'Fattura'}</MDTypography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <MDTypography variant='inherited'>{obj.issued ? 'Emessa' : 'Entrante'}</MDTypography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <MDTypography variant='inherited'>n. {obj.number}{obj.sub ? '/' + obj.sub : ''} - {obj.year}</MDTypography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <MDTypography variant='inherited'>{obj.dateInvoice}</MDTypography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <MDTypography variant='inherited'>{obj.billToFrom}</MDTypography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <MDTypography variant='inherited'>{obj.address} {obj.cap} {obj.city} {obj.prov}</MDTypography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <MDTypography variant='inherited'>p. iva: {obj.vatNumber}</MDTypography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <MDTypography variant='inherited'>c. f: {obj.fiscalCode}</MDTypography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <MDTypography variant='inherited'>{obj.taxable.toFixed(2).replace('.', ',')}</MDTypography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <MDTypography variant='inherited'>{obj.vat.toFixed(2).replace('.', ',')}</MDTypography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <MDTypography variant='inherited'>{obj.totalAmount.toFixed(2).replace('.', ',')}</MDTypography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <MDTypography variant='inherited'>{obj.paid.toFixed(2).replace('.', ',')}</MDTypography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <MDTypography variant='inherited'>{(obj.totalAmount - obj.paid).toFixed(2).replace('.', ',')}</MDTypography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <DataTable table={{ columns: columns, rows: obj.invoiceRows }} canSearch={false} />
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox p={2}>
                  <MDButton
                    variant='gradient'
                    color='secondary'
                    component={Link}
                    to='/invoices'
                    sx={{ m: 1 }}
                  >
                    Indietro
                  </MDButton>
                  {false && <MDButton
                    type='submit'
                    variant='gradient'
                    color='success'
                    sx={{ m: 1 }}
                  >
                    Salva
                  </MDButton>}
                </MDBox>
              </Card>
            </form>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>
  )
}
