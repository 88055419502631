'use strict'

import http from './http-common'
import authHeader from './auth-header'

const getAll = (companyId) => {
  return http.axiosClient.get(`/sponsorships?companyId=${companyId}`, {
    headers: authHeader()
  })
}

const get = (id) => {
  return http.axiosClient.get(`/sponsorships/${id}`, {
    headers: authHeader()
  })
}

const create = (data) => {
  return http.axiosClient.post('/sponsorships', data, {
    headers: authHeader()
  })
}

const update = (id, data) => {
  return http.axiosClient.put(`/sponsorships/${id}`, data, {
    headers: authHeader()
  })
}

const remove = (id) => {
  return http.axiosClient.delete(`/sponsorships/${id}`, {
    headers: authHeader()
  })
}

const findByCustomer = (customerId) => {
  return http.axiosClient.get(`/sponsorships?customerId=${customerId}`, { headers: authHeader() })
}

export default {
  getAll,
  get,
  create,
  update,
  remove,
  findByCustomer
}
