import { forwardRef } from 'react'

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'

const ZUSelect = forwardRef(
  (
    { name, label, register, defaultValue, required, rules, error, children },
    ref
  ) => (
    <FormControl fullWidth required={required} error={error}>
      <InputLabel>{label}</InputLabel>
      <Select
        label={label}
        defaultValue={defaultValue}
        {...register(name, { rules })}
        MenuProps={{ sx: { maxHeight: 250 } }}
      >
        {children}
      </Select>
    </FormControl>
  )
)

// Setting default values for the props
ZUSelect.defaultProps = {
  required: false,
  error: false
}

// Typechecking props for the ZUTitleForm
ZUSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  defaultValue: PropTypes.any,
  rules: PropTypes.object.isRequired,
  required: PropTypes.bool,
  error: PropTypes.bool,
  children: PropTypes.node
}

export default ZUSelect
