'use strict'

import { useEffect, useState, useContext } from 'react'
// @material-ui/core components
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import Select from '@mui/material/Select'
import Dialog from '@mui/material/Dialog'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
// import Autocomplete from '@mui/material/Autocomplete'
// import Icon from '@mui/material/Icon'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import { Link as MuiLink } from '@mui/material'
// import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
// import FormHelperText from '@mui/material/FormHelperText'
import FormControlLabel from '@mui/material/FormControlLabel'

// Material Dashboard 2 PRO React components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import MDAlert from 'components/MDAlert'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'
// import SelectZeroUno from 'components/ZeroUno/SelectZeroUno'

// zerouno
import customerService from 'services/customer.service'
import orderService from 'services/order.service'
import productService from 'services/product.service'
import glsService from 'services/gls.service'
import doctorService from 'services/doctor.service'
import enumerationService from 'services/enumeration.service'
import pathologyService from 'services/pathology.service'
import authService from 'services/auth.service'
import { useForm, Controller } from 'react-hook-form'
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import ZUAutoComplete from 'components/ZeroUno/ZUAutoComplete'
import ZUSelect from 'components/ZeroUno/ZUSelect'
import utils from 'utils/utils'
import { DateTime } from 'luxon'
import RabbitContext from 'context/rabbit.js'
import { config } from 'variables/Config.js'
import SignatureCanvas from 'react-signature-canvas'
import userService from 'services/user.service'
import { useDebouncedCallback } from 'use-debounce'

export default function OrderForm () {
  const { idObj } = useParams()
  const { idCustomer } = useParams()
  const [obj, setObj] = useState({})
  const [pathology, setPathology] = useState({})
  const [lastNumber, setLastNumber] = useState()
  const [products, setProducts] = useState([])
  const [glsShip, setGlsShip] = useState()
  const [glsPickup, setGlsPickup] = useState()
  const [alertProducts, setAlertProducts] = useState(false)
  const [customers, setCustomers] = useState([])
  const [agents, setAgents] = useState([])
  const [technicians, setTechnicians] = useState([])
  const [pathologies, setPathologies] = useState([])
  const [showSnackbar, setShowSnackbar] = useState({
    sh: false,
    msg: '',
    color: 'success'
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
    setError,
    reset,
    watch
  } = useForm()
  const navigate = useNavigate()
  const { rabbit } = useContext(RabbitContext)
  const [sigPad, setSigPad] = useState({})
  const [signature, setSignature] = useState()
  const [openDlg, setOpenDlg] = useState(false)

  const {
    data: doctors,
    isLoading,
    error
  } = doctorService.getAll(rabbit.companyId)
  const {
    data: enums,
    isLoading: loadEnum,
    error: errEnums
  } = enumerationService.findByType('orderState')
  const {
    data: accounts,
    isLoading: loadAccounts,
    error: errAccounts
  } = enumerationService.findByType('account')

  const watchFields = watch([
    'quantity',
    'totalAmount',
    'vat',
    'shippingCosts',
    'shippingVat'
  ])
  const watchPathology = watch('pathologyId')
  const watchSale = watch('sale')
  const extension = new URLSearchParams(useLocation().search).get('extension')

  const clearCanvas = () => {
    if (sigPad !== {}) {
      sigPad.clear()
    }
  }
  const trimCanvas = () => {
    if (sigPad !== {}) {
      setSignature(sigPad.getTrimmedCanvas().toDataURL('image/png'))
      setOpenDlg(false)
    }
  }

  useEffect(() => {
    // fetchAccessorsCompany(agentService, setAgents)
    fetchAgentsAndTechnicians()
    fetchAccessors(pathologyService, setPathologies)
    fetchAccessorsCompany(customerService, setCustomers)
    fetchObject()
    fetchGls()
    fetchLastNumber()
  }, [])

  useEffect(() => {
    reset({ ...obj })
    setValue('dateOrder', DateTime.fromISO(obj.dateOrder).toISODate())
    setValue('dateBegin', DateTime.fromISO(obj.dateBegin).toISODate())
    setValue('dateReturn', DateTime.fromISO(obj.dateReturn).toISODate())
    setValue('dateEnd', DateTime.fromISO(obj.dateEnd).toISODate())
  }, [obj, products])

  useEffect(() => {
    calculateTotal()
  }, [watchFields])

  useEffect(() => {
    findPathology(watchPathology)
  }, [watchPathology])

  useEffect(() => {
    setObj({
      ...obj,
      sale: watchSale
      // invoiceDescription: watchSale
      //   ? 'vendita osteoplus'
      //   : 'trattamento osteoplus'
    })
    calculateTotal()
  }, [watchSale])

  const findPathology = (id) => {
    const pathology = utils.getObjectFromArray(pathologies, watchPathology)
    setPathology(pathology)
  }
  const calculateTotal = () => {
    const totalAmount = String(watchFields[1]).replace(',', '.')
    const vat = watchFields[2]
    const shc = String(watchFields[3]).replace(',', '.')
    const shv = watchFields[4]
    const qta = String(watchFields[0]).replace(',', '.')
    let price = (totalAmount - shc * (1 + shv / 100)) / (qta * (1 + vat / 100))
    if (obj.sale) {
      price = (totalAmount - shc * (1 + shv / 100)) / (1 + vat / 100)
    }
    setValue('price', String(price.toFixed(2)).replace('.', ','))
  }

  const fetchProducts = () => {
    productService.getFree()
      .then((response1) => {
        setProducts(response1.data)
      }).catch((error) => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      })
  }

  const fetchAgentsAndTechnicians = () => {
    userService.getAll()
      .then((response) => {
        const users = response.data
        setAgents(users.filter((item) => item.id.toString() !== '5' && (item.role === 'agente' || item.role === 'tecnico_agente' || item.role === 'admin')))
        setTechnicians(users.filter((item) => item.role === 'tecnico' || item.role === 'tecnico_agente' || item.role === 'admin'))
      }).catch((error) => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      })
  }

  const fetchGls = () => {
    if (idObj !== 'new') {
      glsService
        .getAll(idObj)
        .then((response) => {
          const sss = response.data.filter((item) => {
            return !item.pickup
          })
          if (sss) {
            setGlsShip(sss[0])
          }
          const ppp = response.data.filter((item) => {
            return item.pickup
          })
          if (ppp) {
            setGlsPickup(ppp[0])
          }
        })
        .catch((error) => {
          setShowSnackbar({
            sh: true,
            msg: error.response
              ? error.response.data.message
              : 'errore di ricezione dati',
            color: 'error'
          })
        })
    }
  }

  const fetchAccessors = (service, setFunc) => {
    service.getAll().then(
      (response) => {
        setFunc(response.data)
      },
      (error) => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const fetchAccessorsCompany = (service, setFunc) => {
    service.getAll(rabbit.companyId).then(
      (response) => {
        setFunc(response.data)
      },
      (error) => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const fetchLastNumber = () => {
    orderService
      .lastOrderNumber(rabbit.companyId)
      .then((response) => {
        const ln = response.data
        if (!ln[0]) {
          setLastNumber([{ number: '0', invoiceNumber: 'FPR 00' }])
        }
        setLastNumber(response.data)
      })
      .catch(() => {
        setLastNumber([{ number: '0', invoiceNumber: 'FPR 00' }])
      })
  }

  const fetchObject = () => {
    if (idObj === 'new') {
      customerService
        .get(idCustomer)
        .then(
          (response) => {
            const cust = response.data
            setObj({
              number: '',
              customerId: idCustomer,
              customer: cust,
              productId: 'n',
              alimentatoreId: 'n',
              stateId: '1',
              agentId: authService.isAgente() ? authService.user().id : 'n',
              technicianId: authService.isTecnico() ? authService.user().id : 'n',
              doctorId: 'n',
              pathologyId: 'n',
              dateOrder: DateTime.now(),
              // dateBegin: DateTime.now().plus({ days: 1 }),
              dateReturn: null,
              quantity: 20,
              price: '10,0',
              vat: 4.0,
              shippingCosts: 0.0,
              shippingVat: 22.0,
              totalAmount: 208.0,
              extension: false,
              address: cust.address,
              city: cust.city,
              cap: cust.cap,
              prov: cust.prov,
              notes: cust.notes,
              invoiceNumber: 'FPR 00',
              invoiceName: cust.invoiceName ? cust.invoiceName : cust.name,
              invoiceSurname: cust.invoiceSurname
                ? cust.invoiceSurname
                : cust.surname,
              invoiceBusiness: cust.invoiceBusiness
                ? cust.invoiceBusiness
                : cust.businessName,
              invoiceAddress: cust.invoiceAddress
                ? cust.invoiceAddress
                : cust.address,
              invoiceCity: cust.invoiceCity ? cust.invoiceCity : cust.city,
              invoiceCap: cust.invoiceCap ? cust.invoiceCap : cust.cap,
              invoiceProv: cust.invoiceProv ? cust.invoiceProv : cust.prov,
              invoiceDescription: 'trattamento osteoplus ',
              vatNumber: cust.vat,
              fiscalCode: cust.fiscalCode,
              pec: cust.pec,
              codeInvoice: cust.codeInvoice,
              accountId: '31'
            })
          },
          (error) => {
            setShowSnackbar({
              sh: true,
              msg: error.response
                ? error.response.data.message
                : 'errore di ricezione dati',
              color: 'error'
            })
          }
        )
        .then(fetchProducts())
    } else if (extension != null) {
      orderService
        .get(idObj)
        .then(
          (response) => {
            const orig = response.data
            orig.price = utils.formatFloat(orig.price)
            orig.shippingCosts = utils.formatFloat(orig.shippingCosts)
            setObj({
              number: orig.number + '_ext',
              customerId: idCustomer,
              customer: orig.customer,
              productId: orig.productId,
              product: orig.product,
              alimentatoreId: orig.palimentatoreId,
              alimentatore: orig.alimentatore,
              stateId: '3',
              agentId: orig.agentId,
              agent: orig.agent,
              technicianId: orig.technicianId,
              technician: orig.technician,
              doctorId: orig.doctorId,
              doctor: orig.doctor,
              pathologyId: orig.pathologyId,
              pathology: orig.pathology,
              dateOrder: DateTime.now(),
              dateBegin: DateTime.fromISO(orig.dateBegin).plus({
                days: orig.quantity
              }),
              dateReturn: null,
              quantity: 20,
              price: 0.0,
              vat: orig.vat,
              shippingCosts: 0.0,
              shippingVat: 22.0,
              totalAmount: 0.0,
              extension: true,
              address: orig.address,
              city: orig.city,
              cap: orig.cap,
              prov: orig.prov,
              notes: orig.notes,
              invoiceNumber: 'FPR 00',
              invoiceName: orig.invoiceName,
              invoiceSurname: orig.invoiceSurname,
              invoiceBusiness: orig.invoiceBusiness,
              invoiceAddress: orig.invoiceAddress,
              invoiceCity: orig.invoiceCity,
              invoiceCap: orig.invoiceCap,
              invoiceProv: orig.invoiceProv,
              invoiceDescription: 'trattamento osteoplus ',
              vatNumber: orig.vatNumber,
              fiscalCode: orig.fiscalCode,
              pec: orig.pec,
              codeInvoice: orig.codeInvoice,
              accountId: orig.accountId ? orig.accountId : 'n'
            })
            setProducts((products) => [orig.product, ...products])
          },
          (error) => {
            setShowSnackbar({
              sh: true,
              msg: error.response
                ? error.response.data.message
                : 'errore di ricezione dati',
              color: 'error'
            })
          }
        )
        .then(fetchProducts())
    } else {
      orderService
        .get(idObj)
        .then(
          (response) => {
            response.data.price = utils.formatFloat(response.data.price)
            response.data.shippingCosts = utils.formatFloat(
              response.data.shippingCosts
            )
            if (response.data.doctorId == null) {
              response.data.doctorId = 'n'
            }
            if (response.data.agentId == null) {
              response.data.agentId = 'n'
            }
            if (response.data.technicianId == null) {
              response.data.technicianId = 'n'
            }
            if (response.data.accountId == null) {
              response.data.accountId = 'n'
            }
            const ppp = []
            if (response.data.productId == null) {
              response.data.productId = 'n'
            } else {
              ppp.push(response.data.product)
            }
            if (!response.data.alimentatoreId) {
              response.data.alimentatoreId = 'n'
            } else {
              ppp.push(response.data.alimentatore)
            }
            setProducts(ppp)
            setObj(response.data)
            setSignature(response.data.signature)
          },
          (error) => {
            setShowSnackbar({
              sh: true,
              msg: error.response
                ? error.response.data.message
                : 'errore di ricezione dati',
              color: 'error'
            })
          }
        )
        .then(fetchProducts())
    }
  }

  const onSubmit = useDebouncedCallback(async (data) => {
    data.price = String(data.price).replace(',', '.')
    data.totalAmount = String(data.totalAmount).replace(',', '.')
    data.shippingCosts = String(data.shippingCosts).replace(',', '.')
    const s = data.stateId
    const d = data.dateBegin
    const r = data.dateReturn
    if ((s === 1 || s === 2) && (d || d === '')) {
      data.dateBegin = null
    }
    if (s !== 4 && (r || r === '')) {
      data.dateReturn = null
    }
    if (data.doctorId === 'n') {
      data.doctorId = null
    }
    if (data.productId === 'n') {
      data.productId = null
    }
    if (data.alimentatoreId === 'n') {
      data.alimentatoreId = null
    }
    data.signature = signature
    if (validate(data)) {
      if (idObj === 'new' || extension != null) {
        data.customerId = idCustomer
        addObj(data)
      } else {
        data.id = obj.id
        updateObj(data, obj.id)
      }
    }
  }, 2000)

  const validate = (order) => {
    // if (order.agentId === 'n') {
    //   setError('agentId', { type: 'custom', message: 'campo obbligatorio' })
    //   setShowSnackbar({ sh: true, msg: 'inserire un agente', color: 'error' })
    //   return false
    // }
    // if (order.technicianId === 'n') {
    //   setError('technicianId', { type: 'custom', message: 'campo obbligatorio' })
    //   setShowSnackbar({ sh: true, msg: 'inserire un tecnico', color: 'error' })
    //   return false
    // }
    if (!order.sale && order.pathologyId === 'n') {
      setError('pathologyId', { type: 'custom', message: 'campo obbligatorio' })
      setShowSnackbar({
        sh: true,
        msg: 'inserire la patologia',
        color: 'error'
      })
      return false
    }
    if (order.accountId === 'n') {
      setError('accountId', { type: 'custom', message: 'campo obbligatorio' })
      setShowSnackbar({
        sh: true,
        msg: 'inserire la modalità di pagamento',
        color: 'error'
      })
      return false
    }
    // stato aperto 1 data inizio = null
    // stato spedito 2 data inizio = null
    // stato consegnato 3 data inizio !=null
    // stato chiuso 4 data inizio !=null
    // stato annullato 5 fai che cazzo vuoi
    const s = order.stateId
    const d = order.dateBegin
    const r = order.dateReturn
    if ((s === 3 || s === 4) && !d) {
      setShowSnackbar({
        sh: true,
        msg: 'inserire la data di inizio',
        color: 'error'
      })
      return false
    }
    if (!order.sale && s === 4 && !r) {
      setShowSnackbar({
        sh: true,
        msg: 'inserire la data di rientro',
        color: 'error'
      })
      return false
    }
    return true
  }
  // Add obj
  const addObj = (data) => {
    // data.image = obj.image
    orderService.create(data).then(
      (response) => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
        if (data.sale) {
          navigate(`/customers/${idCustomer}/salesorders`)
        } else {
          navigate(`/customers/${idCustomer}/orders`)
        }
      },
      (error) => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const updateObj = (data, id) => {
    // data.image = obj.image
    orderService.update(id, data).then(
      (response) => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
        if (data.sale) {
          navigate(`/customers/${idCustomer}/salesorders`)
        } else {
          navigate(`/customers/${idCustomer}/orders`)
        }
      },
      (error) => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const sendMail = () => {
    orderService.sendMail(idObj).then(
      (response) => {
        setShowSnackbar({
          sh: true,
          msg: response.data.message,
          color: 'success'
        })
      },
      (error) => {
        setShowSnackbar({
          sh: true,
          msg: error.response ? error.response.data.message : 'errore invio',
          color: 'error'
        })
      }
    )
  }

  const confirmShipGls = () => {
    glsService.confirm(glsShip.id).then(
      (response) => {
        setShowSnackbar({
          sh: true,
          msg: response.data.message,
          color: 'success'
        })
      },
      (error) => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const delShipGls = () => {
    glsService.remove(glsShip.id).then(
      (response) => {
        setShowSnackbar({
          sh: true,
          msg: response.data.message,
          color: 'success'
        })
        setGlsShip()
      },
      (error) => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const delPickupGls = () => {
    glsService.remove(glsPickup.id).then(
      (response) => {
        setShowSnackbar({
          sh: true,
          msg: response.data.message,
          color: 'success'
        })
        setGlsPickup()
      },
      (error) => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={10}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Card>
                <ZUTitleForm bgColor='info' title='Ordine' icon='list_alt' />

                <MDBox p={2}>
                  {alertProducts && (
                    <MDAlert color='error'>
                      Non ci sono prodotti disponibili
                    </MDAlert>
                  )}

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={2}>
                      <MDInput
                        fullWidth
                        type='text'
                        required={false}
                        disabled
                        label='numero ordine'
                        error={!!errors.number}
                        {...register('number', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <MDInput
                        fullWidth
                        type='text'
                        required={false}
                        label='suffisso'
                        error={!!errors.code}
                        {...register('code', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                      {idObj == 'new' && lastNumber && lastNumber[0] && (
                        <MDTypography variant='subtitle2'>
                          ultimo ordine: {lastNumber[0].number}, ultima fattura:{' '}
                          {lastNumber[0].invoiceNumber}
                        </MDTypography>
                      )}
                    </Grid>
                    <Grid item xs={12} />
                    <Grid item xs={12} sm={12} md={12}>
                      <Controller
                        control={control}
                        name='sale'
                        render={({ field: { value, onChange } }) => (
                          <FormControlLabel
                            control={
                              <Switch
                                checked={value || false}
                                onChange={onChange}
                              />
                            }
                            label={obj.sale ? 'vendita' : 'noleggio'}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      {obj.customerId && (
                        <MDTypography variant='subtitle'>
                          {obj.customer.surname} {obj.customer.name} {obj.customer.businessName}
                        </MDTypography>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      {obj.customerId && (
                        <MDTypography variant='subtitle2'>
                          {obj.customer.email ? ' email: ' + obj.customer.email : ''}
                        </MDTypography>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      {obj.customerId && (
                        <MDTypography variant='subtitle2'>
                          {obj.customer.phone ? ' cel. ' + obj.customer.phone : ''}
                        </MDTypography>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      {obj.customerId && (
                        <MDTypography variant='subtitle2'>
                          {obj.customer.mobile ? ' tel: ' + obj.customer.mobile : ''}
                        </MDTypography>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                      {obj.productId && (
                        <ZUAutoComplete
                          name='productId'
                          control={control}
                          required={false}
                          rules={{ required: false }}
                          options={products.filter((item) => item.productModelId !== 14)}
                          defaultValue={obj.product}
                          getOptionLabel={(item) =>
                            `${item.productModel?.name} ser. ${item.serial} ${item.batch} [${item.companyId === 1 ? 'T' : 'B'}] - ${item.id}`}
                          label='Dispositivo'
                          error={!!errors.productId}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      {obj.alimentatoreId && (
                        <ZUAutoComplete
                          name='alimentatoreId'
                          control={control}
                          required={false}
                          rules={{ required: false }}
                          options={products.filter((item) => item.productModelId === 14)}
                          defaultValue={obj.alimentatore}
                          getOptionLabel={(item) =>
                            `${item.productModel?.name} ser. ${item.serial} ${item.batch} [${item.companyId === 1 ? 'T' : 'B'}] - ${item.id}`}
                          label='Alimentatore'
                          error={!!errors.alimentatoreId}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      {obj.agentId && (
                        <ZUAutoComplete
                          name='agentId'
                          control={control}
                          required
                          rules={{ required: true }}
                          options={agents}
                          defaultValue={obj.agent}
                          getOptionLabel={(item) => `${item.surname} ${item.name}`}
                          label='Agente'
                          error={!!errors.agentId}
                        />
                      )}
                      {/* {false && obj.agentId && (
                        <ZUSelect
                          error={!!errors.agentId}
                          label='Agente'
                          defaultValue={obj.agentId}
                          register={register}
                          name='agentId'
                          required
                          rules={{ required: true }}
                        >
                          <MenuItem key='0' value='n'>
                            seleziona un agente
                          </MenuItem>
                          {agents &&
                            agents.map((item) => (
                              <MenuItem key={item.id} value={item.id}>
                                {item.surname} {item.name}
                              </MenuItem>
                            ))}
                        </ZUSelect>
                      )} */}
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      {obj.technicianId && (
                        <ZUAutoComplete
                          name='technicianId'
                          control={control}
                          required
                          rules={{ required: true }}
                          options={technicians}
                          defaultValue={obj.technician}
                          getOptionLabel={(item) => `${item.surname} ${item.name}`}
                          label='Tecnico'
                          error={!!errors.technicianId}
                        />
                      )}
                      {/* {obj.technicianId && (
                        <ZUSelect
                          error={!!errors.technicianId}
                          label='Tecnico'
                          defaultValue={obj.technicianId}
                          register={register}
                          name='technicianId'
                          required
                          rules={{ required: true }}
                        >
                          <MenuItem key='0' value='n'>
                            seleziona un tecnico
                          </MenuItem>
                          {technicians &&
                            technicians.map((item) => (
                              <MenuItem key={item.id} value={item.id}>
                                {item.surname} {item.name}
                              </MenuItem>
                            ))}
                        </ZUSelect>
                      )} */}
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      {obj.doctorId && (
                        <ZUAutoComplete
                          name='doctorId'
                          control={control}
                          required={false}
                          rules={{ required: false }}
                          options={doctors}
                          defaultValue={obj.doctor}
                          getOptionLabel={(item) => `${item.surname} ${item.name} [id: ${item.id}]`}
                          label='Medico'
                          error={!!errors.doctorId}
                        />
                      )}
                      {/* {obj.doctorId && (
                        <ZUSelect
                          error={!!errors.doctorId}
                          label='Medico'
                          required={false}
                          defaultValue={obj.doctorId}
                          register={register}
                          name='doctorId'
                          rules={{ required: false }}
                        >
                          <MenuItem key='0' value='n'>
                            seleziona un medico
                          </MenuItem>
                          {doctors &&
                            doctors.map((item) => (
                              <MenuItem key={item.id} value={item.id}>
                                {item.surname} {item.name}
                              </MenuItem>
                            ))}
                        </ZUSelect>
                      )} */}
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      {!obj.sale && obj.pathologyId && (
                        <ZUAutoComplete
                          name='pathologyId'
                          control={control}
                          required
                          rules={{ required: true }}
                          options={pathologies}
                          defaultValue={obj.pathology}
                          getOptionLabel={(item) => `${item.name} [id: ${item.id}]`}
                          label='Patologia'
                          error={!!errors.pathologyId}
                        />
                      )}
                      {/* {!obj.sale && obj.pathologyId && (
                        <ZUSelect
                          error={!!errors.pathologyId}
                          label='Patologia'
                          defaultValue={obj.pathologyId}
                          register={register}
                          required
                          name='pathologyId'
                          rules={{ required: true }}
                        >
                          <MenuItem key='0' value='n'>
                            seleziona una patologia
                          </MenuItem>
                          {pathologies &&
                            pathologies.map((item) => (
                              <MenuItem key={item.id} value={item.id}>
                                {item.name}
                              </MenuItem>
                            ))}
                        </ZUSelect>
                      )}                       */}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <MDInput
                        fullWidth
                        type='text'
                        label='note'
                        error={!!errors.notes}
                        {...register('notes', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <MDTypography variant='body2'>
                        Dati di spedizione
                      </MDTypography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        label='indirizzo'
                        error={!!errors.address}
                        {...register('address', { required: true })}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={4}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        label='città'
                        error={!!errors.city}
                        {...register('city', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        label='prov'
                        inputProps={{
                          maxLength: 2
                        }}
                        error={!!errors.prov}
                        {...register('prov', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        label='cap'
                        inputProps={{
                          maxLength: 5
                        }}
                        error={!!errors.cap}
                        {...register('cap', { required: true })}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={3}>
                      <MDInput
                        fullWidth
                        type='date'
                        required
                        label='data'
                        error={!!errors.dateOrder}
                        {...register('dateOrder', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <MDInput
                        fullWidth
                        type='date'
                        required={false}
                        label={obj.sale ? 'data consegna' : 'data inizio'}
                        error={!!errors.dateBegin}
                        {...register('dateBegin', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      {!obj.sale && (
                        <MDInput
                          fullWidth
                          type='date'
                          required={false}
                          label='data rientro'
                          error={!!errors.dateReturn}
                          {...register('dateReturn', { required: false })}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} />
                    <Grid item xs={12} sm={12} md={12}>
                      <MDTypography variant='body2'>
                        Calcolo prezzo:
                      </MDTypography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      {!obj.sale && (
                        <MDInput
                          fullWidth
                          type='text'
                          required
                          label='giorni'
                          error={!!errors.quantity}
                          {...register('quantity', {
                            required: true,
                            pattern: utils.integerPattern
                          })}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        label='totale'
                        defaultValue={obj.totalAmount}
                        {...register('totalAmount', {
                          required: true,
                          pattern: utils.floatPattern
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <MDInput
                        fullWidth
                        type='text'
                        disabled
                        label='prezzo'
                        error={!!errors.price}
                        helperText={errors.price && 'dati non corretti'}
                        {...register('price')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      {obj.vat && (
                        <FormControl fullWidth required error={!!errors.vat}>
                          <InputLabel>iva</InputLabel>
                          <Select
                            label='iva'
                            defaultValue={obj.vat}
                            {...register('vat', { required: true })}
                          >
                            <MenuItem key={0} value={0.0}>
                              0 %
                            </MenuItem>
                            <MenuItem key={4} value={4.0}>
                              4 %
                            </MenuItem>
                            <MenuItem key={10} value={10.0}>
                              10 %
                            </MenuItem>
                            <MenuItem key={22} value={22.0}>
                              22 %
                            </MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        label='costi spedizione'
                        error={!!errors.shippingCosts}
                        helperText={errors.shippingCosts && 'dati non corretti'}
                        {...register('shippingCosts', {
                          required: true,
                          pattern: utils.floatPattern
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      {obj.vat && (
                        <FormControl fullWidth required error={!!errors.vat}>
                          <InputLabel>iva spedizione</InputLabel>
                          <Select
                            label='iva spedizione'
                            defaultValue={obj.shippingVat}
                            {...register('shippingVat', { required: true })}
                          >
                            <MenuItem key={0} value={0.0}>
                              0 %
                            </MenuItem>
                            <MenuItem key={4} value={4.0}>
                              4 %
                            </MenuItem>
                            <MenuItem key={10} value={10.0}>
                              10 %
                            </MenuItem>
                            <MenuItem key={22} value={22.0}>
                              22 %
                            </MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    </Grid>

                    <Grid item xs={12} sm={6} md={2}>
                      {!obj.sale && (
                        <Controller
                          control={control}
                          name='extension'
                          render={({ field: { value, onChange } }) => (
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={value || false}
                                  onChange={onChange}
                                />
                              }
                              label='estensione'
                            />
                          )}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      {obj.stateId && (
                        <FormControl
                          fullWidth
                          required
                          error={!!errors.stateId}
                        >
                          <InputLabel>stato</InputLabel>
                          <Select
                            label='stato'
                            defaultValue={obj.stateId}
                            {...register('stateId', { required: true })}
                          >
                            {enums &&
                              enums.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                  {item.description}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xs={12} />
                    <Grid item xs={12} sm={12} md={12}>
                      <MDTypography variant='body2'>
                        Dati di fatturazione:
                      </MDTypography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        label='num fattura'
                        error={!!errors.invoiceNumber}
                        {...register('invoiceNumber', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <MDInput
                        fullWidth
                        type='text'
                        required={false}
                        label='cognome'
                        error={!!errors.invoiceSurname}
                        {...register('invoiceSurname', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <MDInput
                        fullWidth
                        type='text'
                        required={false}
                        label='nome'
                        error={!!errors.invoiceName}
                        {...register('invoiceName', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <MDInput
                        fullWidth
                        type='text'
                        required={false}
                        label='ragione sociale'
                        error={!!errors.invoiceBusiness}
                        {...register('invoiceBusiness', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        label='indirizzo'
                        error={!!errors.invoiceAddress}
                        {...register('invoiceAddress', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        label='città'
                        error={!!errors.invoiceCity}
                        {...register('invoiceCity', { required: true })}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={2}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        label='provincia'
                        inputProps={{
                          maxLength: 2
                        }}
                        error={!!errors.invoiceProv}
                        {...register('invoiceProv', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        label='cap'
                        inputProps={{
                          maxLength: 5
                        }}
                        error={!!errors.invoiceCap}
                        {...register('invoiceCap', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                      <MDInput
                        fullWidth
                        type='text'
                        required={false}
                        label='partita iva'
                        error={!!errors.vatNumber}
                        {...register('vatNumber', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                      <MDInput
                        fullWidth
                        type='text'
                        required={false}
                        label='codice fiscale'
                        error={!!errors.fiscalCode}
                        {...register('fiscalCode', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                      <MDInput
                        fullWidth
                        type='email'
                        required={false}
                        label='pec'
                        error={!!errors.pec}
                        {...register('pec', {
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: 'mail non valida'
                          }
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                      <MDInput
                        fullWidth
                        type='text'
                        label='codice destinatario'
                        required={false}
                        error={!!errors.codeInvoice}
                        {...register('codeInvoice', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      {obj.accountId && (
                        <FormControl
                          fullWidth
                          required
                          error={!!errors.accountId}
                        >
                          <InputLabel>modalità pagamento</InputLabel>
                          <Select
                            label='modalità pagamento'
                            defaultValue={obj.accountId}
                            {...register('accountId', { required: false })}
                          >
                            {accounts &&
                              accounts.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                  {item.description}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      )}
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={6}>
                      {obj.invoiceAddressId && (
                        <FormControl
                          fullWidth
                          required
                          error={!!errors.addressId}
                        >
                          <InputLabel>indirizzo di fatturazione</InputLabel>
                          <Select
                            label='indirizzo di fatturazione'
                            defaultValue={obj.invoiceAddressId}
                            {...register('invoiceAddressId', { required: true })}
                          >
                            {addresses && addresses.map((item) =>
                              <MenuItem key={item.id} value={item.id}>{item.city} - {item.address} {item.civicNumber}</MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      )}
                    </Grid> */}
                    <Grid item xs={12} sm={12} md={6}>
                      <MDInput
                        fullWidth
                        type='text'
                        label='descrizione fattura'
                        required={false}
                        error={!!errors.invoiceDescription}
                        {...register('invoiceDescription', { required: false })}
                      />
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox p={2}>
                  <MDButton
                    variant='gradient'
                    color='secondary'
                    component={Link}
                    to={`/customers/${idCustomer}/orders`}
                    sx={{ m: 1 }}
                  >
                    Indietro
                  </MDButton>
                  <MDButton
                    variant='gradient'
                    color='secondary'
                    component={Link}
                    to='/dashboard/main'
                    sx={{ m: 1 }}
                  >
                    Dashboard
                  </MDButton>
                  <MDButton
                    type='submit'
                    variant='gradient'
                    color='success'
                    sx={{ m: 1 }}
                  >
                    Salva
                  </MDButton>
                </MDBox>
              </Card>
            </form>
          </Grid>
          <Grid item xs={12} sm={12} md={2}>
            <Card>
              <MDBox p={2}>
                <MDTypography variant='h6'>Ordine</MDTypography>
                <Stack spacing={2}>
                  {idObj !== 'new' && obj.product && (
                    <>
                      <MDButton
                        color='success'
                        component={MuiLink}
                        href={config.SERVER_URL + '/orders/ddt/' + obj.id}
                        target='_blank'
                      >
                        pdf ordine / ddt
                      </MDButton>
                      <MDButton
                        color='success'
                        onClick={() => {
                          sendMail()
                        }}
                      // component={MuiLink}
                      // href={config.SERVER_URL + '/orders/mailddt/' + obj.id}
                      // target='_blank'
                      >
                        invia pdf ordine in mail
                      </MDButton>
                    </>
                  )}
                  {idObj !== 'new' && obj.product && (
                    <MDButton
                      color='success'
                      component={MuiLink}
                      href={config.SERVER_URL + '/orders/invoice/' + obj.id}
                      target='_blank'
                    >
                      fattura xml
                    </MDButton>
                  )}
                  {authService.isAdmin() && (
                    <>
                      <MDButton
                        color='success'
                        component={Link}
                        to={`/customers/${idCustomer}/orders/${idObj}/gls/${glsShip ? glsShip.id : 'new'
                          }`}
                      >
                        crea spedizione GLS
                      </MDButton>
                      {glsShip && (
                        <>
                          <MDTypography variant='h6'>
                            numero spedizione: {glsShip.number}
                          </MDTypography>
                          <MDButton
                            color='info'
                            onClick={() => {
                              confirmShipGls()
                            }}
                          >
                            conferma spedizione
                          </MDButton>
                          <MDButton
                            color='error'
                            onClick={() => {
                              delShipGls()
                            }}
                          >
                            elimina spedizione
                          </MDButton>
                        </>
                      )}
                      {glsShip && glsShip.pdf && (
                        <>
                          <embed
                            src={`data:application/pdf;base64,${glsShip.pdf}`}
                            height='300'
                          />
                        </>
                      )}

                      <MDButton
                        color='success'
                        component={Link}
                        to={`/customers/${idCustomer}/orders/${idObj}/gls/${glsPickup ? glsPickup.id : 'new'
                          }?pickup=1`}
                      >
                        ritiro GLS
                      </MDButton>

                      {glsPickup && (
                        <MDButton
                          color='error'
                          onClick={() => {
                            delPickupGls()
                          }}
                        >
                          elimina ritiro GLS
                        </MDButton>
                      )}

                      {glsPickup && (
                        <MDTypography variant='h6'>
                          numero ritiro: {glsPickup.number}
                        </MDTypography>
                      )}
                    </>
                  )}

                  <MDButton
                    color='info'
                    component={Link}
                    to={'/customers/' + idCustomer}
                  >
                    cliente
                  </MDButton>
                  <MDButton
                    color='info'
                    component={Link}
                    to={'/customers/' + idCustomer + '/orders'}
                  >
                    ordini noleggio
                  </MDButton>
                  <MDButton
                    color='info'
                    component={Link}
                    to={'/customers/' + idCustomer + '/salesorders'}
                  >
                    ordini vendita
                  </MDButton>

                  <MDButton
                    color='info'
                    component={Link}
                    to={'/customers/' + idCustomer + '/payments'}
                  >
                    pagamenti
                  </MDButton>
                  <MDButton color='info' onClick={() => setOpenDlg(true)}>
                    nuova firma
                  </MDButton>

                  <MDTypography variant='h6'>firma cliente</MDTypography>
                  {signature ? <img src={signature} /> : null}
                </Stack>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
      <Dialog
        fullScreen
        open={openDlg}
        onClose={() => setOpenDlg(false)}
        sx={{ background: '#f1f1f1' }}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge='start'
              color='inherit'
              onClick={() => setOpenDlg(false)}
              aria-label='close'
            >
              <CloseIcon />
            </IconButton>
            <MDTypography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
              Firma
            </MDTypography>
            <MDButton
              color='secondary'
              onClick={(e) => clearCanvas()}
              sx={{ m: 1 }}
            >
              pulisci
            </MDButton>
            <MDButton
              color='success'
              onClick={(e) => trimCanvas()}
              sx={{ m: 1 }}
            >
              salva
            </MDButton>
          </Toolbar>
        </AppBar>
        <MDBox
          p={2}
          bgColor='#cccccc'
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          <MDBox bgColor='#FFFFFF' width='300px' height='200px'>
            <SignatureCanvas
              canvasProps={{ width: '300', height: '200' }}
              penColor='#00ADEF'
              ref={(ref) => {
                setSigPad(ref)
              }}
            />
          </MDBox>
        </MDBox>
      </Dialog>
    </DashboardLayout>
  )
}
