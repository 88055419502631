'use strict'

import { useEffect, useContext, useState } from 'react'
// @material-ui/core components
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import FormControlLabel from '@mui/material/FormControlLabel'

// Material Dashboard 2 PRO React components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'

// zerouno
import customerService from 'services/customer.service'
import orderService from 'services/order.service'
import transactionService from 'services/transaction.service'
import enumerationService from 'services/enumeration.service'
import { useForm, Controller } from 'react-hook-form'
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import utils from 'utils/utils'
import { DateTime } from 'luxon'
import RabbitContext from 'context/rabbit.js'

export default function TransactionForm() {
  const { idObj } = useParams()
  const { idCustomer } = useParams()
  const [orderId, setOrderId] = useState(new URLSearchParams(useLocation().search).get('orderId'))
  const { data: enums, isLoading: loadEnum, error: errEnums } = enumerationService.findByType('account')
  const [showSnackbar, setShowSnackbar] = useState({ sh: false, msg: '', color: 'success' })
  const navigate = useNavigate()
  const { rabbit } = useContext(RabbitContext)
  const { register, handleSubmit, formState: { errors }, watch, control, getValues, setValue, reset } = useForm()
  const [customer, setCustomer] = useState([])
  const [orders, setOrders] = useState([])
  const [order, setOrder] = useState({})
  const [obj, setObj] = useState({})

  const orderIdWatch = watch('orderId')

  useEffect(() => {
    fetchAll()
  }, [])

  const fetchAll = () => {
    if (idObj === 'new') {
      customerService.get(idCustomer).then(
        (response) => {
          setCustomer(response.data)
        }).then(() => {
          orderService.findByCustomer(idCustomer).then(
            (response1) => {
              setOrders(response1.data)
              let myOrder = {}
              if (orderId) {
                myOrder = utils.getObjectFromArray(response1.data, orderId)
                setOrder(myOrder)
              }
              setObj({
                customerId: idCustomer,
                orderId: orderId || 'n',
                accountId: orderId ? myOrder.accountId : 'n',
                dateTransaction: DateTime.now(),
                dateInvoice: DateTime.now(),
                invoice: orderId ? myOrder.invoiceNumber : 'FPR 00',
                amount: orderId ? utils.formatFloat(myOrder.toPay) : '0,0'
              })
            }).catch((error) => {
              setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
            })
        }).catch((error) => {
          setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
        })
    } else {
      transactionService.get(idObj).then(
        (response1) => {
          response1.data.amount = utils.formatFloat(response1.data.amount)
          return response1.data
        }).then(trans => {
          customerService.get(idCustomer).then(
            (response2) => {
              setCustomer(response2.data)
              return trans
            }).then(trans => {
              orderService.findByCustomer(idCustomer).then(
                (response3) => {
                  setOrders(response3.data)
                  setOrder(utils.getObjectFromArray(response3.data, trans.orderId))
                  setObj(trans)
                }).catch((error) => {
                  setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
                })
            })
        })
    }
  }

  useEffect(() => {
    reset({ ...obj })
    setValue('dateTransaction', DateTime.fromISO(obj.dateTransaction).toISODate())
    setValue('dateInvoice', DateTime.fromISO(obj.dateInvoice).toISODate())
    setValue('invoice', obj.invoice)
    setValue('amount', utils.formatFloat(obj.amount))
  }, [obj])

  useEffect(() => {
    // setOrderId(orderIdWatch)
    const myOrder = utils.getObjectFromArray(orders, orderIdWatch)
    setOrder(myOrder)
    // setObj(obj => ({
    //   ...obj,
    //   orderId: myOrder ? myOrder.id : 'n',
    //   accountId: myOrder.accountId,
    //   invoice: myOrder.invoiceNumber,
    //   amount: myOrder.toPay
    // }))
    setObj({
      ...obj,
      orderId: myOrder ? myOrder.id : 'n',
      accountId: myOrder.accountId,
      invoice: myOrder.invoiceNumber,
      amount: myOrder.toPay
    })
  }, [orderIdWatch])

  const onSubmit = (data) => {
    data.amount = data.amount.replace(',', '.')
    if (data.dateInvoice === '') {
      data.dateInvoice = null
    }
    if (validate(data)) {
      if (idObj === 'new') {
        data.customerId = idCustomer
        addObj(data)
      } else {
        data.id = obj.id
        updateObj(data, obj.id)
      }
    }
  }

  const validate = (order) => {
    const inv = order.invoice
    const d = order.dateInvoice
    if ((inv === '' && d !== null) || (inv !== '' && d === null)) {
      setShowSnackbar({ sh: true, msg: 'inserire tutti i dati della fattura o nessuno', color: 'error' })
      return false
    }
    return true
  }

  // Add obj
  const addObj = (data) => {
    // data.image = obj.image
    transactionService.create(data).then(
      (response) => {
        setShowSnackbar({ sh: true, msg: 'operazione effettuata con successo', color: 'success' })
        navigate(`/customers/${idCustomer}/payments`)
      },
      (error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      }
    )
  }

  const updateObj = (data, id) => {
    // data.image = obj.image
    transactionService.update(id, data).then(
      (response) => {
        setShowSnackbar({ sh: true, msg: 'operazione effettuata con successo', color: 'success' })
        navigate(`/customers/${idCustomer}/payments`)
      },
      (error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      }
    )
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={8}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Card>
                <ZUTitleForm bgColor='info' title='Pagamento' icon='euro' />

                <MDBox p={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                      {customer && (
                        <MDTypography>cliente {customer.surname} {customer.name} {customer.businessName}</MDTypography>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      {obj.orderId && (
                        <FormControl
                          fullWidth
                          required
                          error={!!errors.orderId}
                        >
                          <InputLabel>Ordine</InputLabel>
                          <Select
                            label='Ordine'
                            value={obj.orderId}
                            {...register('orderId', { required: true })}
                          >
                            {orders && orders.map((item) =>
                              <MenuItem key={item.id} value={item.id}>n. {item.number} da pagare:  € {utils.formatFloat(item.toPay)}/{utils.formatFloat(item.totalAmount)}</MenuItem>
                            )}
                          </Select>
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <MDInput
                        fullWidth
                        type='date'
                        required
                        label='data'
                        error={!!(errors.dateTransaction)}
                        {...register('dateTransaction', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        label='importo'
                        error={!!(errors.amount)}
                        helperText={errors.amount && 'dati non corretti'}
                        {...register('amount', { required: true, pattern: utils.floatPattern })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <MDInput
                        fullWidth
                        type='date'
                        label='data fattura'
                        error={!!(errors.dateInvoice)}
                        {...register('dateInvoice')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <MDInput
                        fullWidth
                        type='text'
                        label='num fattura'
                        error={!!(errors.invoice)}
                        {...register('invoice')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <MDInput
                        fullWidth
                        type='text'
                        required={false}
                        label='note'
                        error={!!(errors.notes)}
                        {...register('notes', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <FormControl
                        fullWidth
                        required
                        error={!!errors.accountId}
                      >
                        <InputLabel>modalità pagamento</InputLabel>
                        <Select
                          label='modalità pagamento'
                          value={obj.accountId || ''}
                          {...register('accountId', { required: true })}
                        >
                          {enums && enums.map((item) =>
                            <MenuItem key={item.id} value={item.id}>{item.description}</MenuItem>
                          )}
                        </Select>
                      </FormControl>

                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox p={2}>
                  <MDButton
                    variant='gradient' color='secondary' component={Link} to={`/customers/${idCustomer}/payments`}
                  >
                    pagamenti del cliente
                  </MDButton>
                  <MDButton
                    variant='gradient' color='secondary' component={Link} to='/payments' sx={{ ml: 1 }}
                  >
                    tutti i pagamenti
                  </MDButton>
                  <MDButton type='submit' variant='gradient' color='success' sx={{ ml: 1 }}>
                    Salva
                  </MDButton>
                </MDBox>
              </Card>
            </form>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Card>
              <MDBox p={2}>
                <MDTypography variant='h6'>Ordine</MDTypography>
                <Stack spacing={2}>
                  <MDButton
                    color='info'
                    component={Link}
                    to={'/customers/' + idCustomer}
                  >
                    cliente
                  </MDButton>
                  {/* <MDButton
                    color='info'
                    component={Link}
                    to={'/customers/' + idCustomer + '/addresses'}
                  >
                    indirizzi
                  </MDButton> */}
                  <MDButton
                    color='info'
                    component={Link}
                    to={'/customers/' + idCustomer + '/orders'}
                  >
                    ordini
                  </MDButton>
                  <MDButton
                    color='info'
                    component={Link}
                    to={'/customers/' + idCustomer + '/payments'}
                  >
                    pagamenti
                  </MDButton>
                </Stack>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>
  )
}
