'use strict'

import { useEffect, useState, useContext } from 'react'
// @material-ui/core components
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Autocomplete from '@mui/material/Autocomplete'
import Select from '@mui/material/Select'
// import Dialog from '@mui/material/Dialog'
// import AppBar from '@mui/material/AppBar'
// import Toolbar from '@mui/material/Toolbar'
// import IconButton from '@mui/material/IconButton'
// import CloseIcon from '@mui/icons-material/Close'
// import Icon from '@mui/material/Icon'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import { Link as MuiLink } from '@mui/material'
// import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
// import FormHelperText from '@mui/material/FormHelperText'
// import FormControlLabel from '@mui/material/FormControlLabel'

// Material Dashboard 2 PRO React components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
// import MDAlert from 'components/MDAlert'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
// import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'
// import SelectZeroUno from 'components/ZeroUno/SelectZeroUno'

// zerouno
import customerService from 'services/customer.service'
import mainService from 'services/sponsorship.service'
import { useForm, Controller } from 'react-hook-form'
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import ZUSelect from 'components/ZeroUno/ZUSelect'
import utils from 'utils/utils'
import { DateTime } from 'luxon'
import RabbitContext from 'context/rabbit.js'
import MDTypography from 'components/MDTypography'

export default function OrderForm() {
  const { idObj } = useParams()
  const { idCustomer } = useParams()
  const [obj, setObj] = useState({})
  const [customers, setCustomers] = useState([])
  const [customer, setCustomer] = useState({})

  const [showSnackbar, setShowSnackbar] = useState({
    sh: false,
    msg: '',
    color: 'success'
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
    setError,
    reset,
    watch
  } = useForm()
  const navigate = useNavigate()
  const { rabbit } = useContext(RabbitContext)
  const [openDlg, setOpenDlg] = useState(false)

  useEffect(() => {
    fetchAccessorsCompany(customerService, setCustomers)
    fetchObject()
  }, [])

  useEffect(() => {
    reset({ ...obj })
    setValue('startOn', DateTime.fromISO(obj.startOn).toISODate())
    setValue('endOn', DateTime.fromISO(obj.endOn).toISODate())
  }, [obj])

  const fetchAccessorsCompany = (service, setFunc) => {
    service.getAll(rabbit.companyId).then(
      response => {
        setFunc(response.data)
      },
      error => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const fetchObject = () => {
    if (idObj === 'new') {
      const ooo = {
        customerId: 'n',
        customer: { id: 'n' },
        startOn: DateTime.now(),
        endOn: DateTime.now().plus({ days: 365 }),
        notes: '',
        number: 1
      }
      setObj(ooo)
      setValue('startOn', DateTime.fromISO(ooo.startOn).toISODate())
      setValue('endOn', DateTime.fromISO(ooo.endOn).toISODate())
    } else {
      mainService
        .get(idObj)
        .then(response => {
          const sp = response.data
          return sp
        })
        .then(sp => {
          customerService.get(sp.customerId).then(response2 => {
            sp.customer = response2.data
            setObj(sp)
            setCustomer(sp.customer)
            setValue('startOn', DateTime.fromISO(sp.startOn).toISODate())
            setValue('endOn', DateTime.fromISO(sp.endOn).toISODate())
          })
        })
        .catch(error => {
          setShowSnackbar({
            sh: true,
            msg: error.response
              ? error.response.data.message
              : 'errore di ricezione dati',
            color: 'error'
          })
        })
    }
  }

  const onSubmit = data => {
    data.customerId = customer.id
    data.customer = customer
    if (idObj === 'new') {
      addObj(data)
    } else {
      data.id = obj.id
      updateObj(data, obj.id)
    }
  }

  // Add obj
  const addObj = data => {
    if (data.customerId === 'n') {
      setShowSnackbar({ sh: true, msg: 'seleziona un cliente', color: 'error' })
      return
    }
    mainService.create(data).then(
      response => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
        navigate('/sponsorships')
      },
      error => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const updateObj = (data, id) => {
    mainService.update(id, data).then(
      response => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
        navigate('/sponsorships')
      },
      error => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Card>
                <ZUTitleForm
                  bgColor='info'
                  title='Sponsorizzazione'
                  icon='card_giftcard'
                />
                <MDBox p={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4}>
                      <Controller
                        name='customer'
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <Autocomplete
                            options={customers}
                            value={customer || null}
                            onChange={(_, item) => {
                              setCustomer(item)
                              onChange(item ? item.id : null)
                            }}
                            getOptionLabel={item =>
                              item.id
                                ? `${item.surname} ${item.name} [${item.businessName}]`
                                : 'seleziona un cliente'}
                            isOptionEqualToValue={(item, val) => {
                              return val && item && item.id == val.id
                            }}
                            renderInput={params => (
                              <MDInput
                                {...params}
                                fullWidth
                                required
                                label='Cliente'
                                error={!!errors.customer}
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        label='numero dispositivi'
                        error={!!errors.number}
                        {...register('number', {
                          required: true,
                          pattern: utils.integerPattern
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <MDInput
                        fullWidth
                        type='date'
                        required
                        label='data inizio'
                        error={!!errors.startOn}
                        {...register('startOn', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <MDInput
                        fullWidth
                        type='date'
                        required
                        label='data fine'
                        error={!!errors.endOn}
                        {...register('endOn', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <MDTypography variant='h6'>Dirigente</MDTypography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        label='nome'
                        error={!!errors.nameManager}
                        {...register('nameManager', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        label='cognome'
                        error={!!errors.surnameManager}
                        {...register('surnameManager', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <MDInput
                        fullWidth
                        type='text'
                        required={false}
                        label='telefono'
                        error={!!errors.phoneManager}
                        {...register('phoneManager', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <MDInput
                        fullWidth
                        type='mail'
                        label='mail'
                        error={!!errors.mailManager}
                        helperText={
                          errors.mailManager && errors.mailManager.message
                        }
                        {...register('mailManager', {
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: 'mail non valida'
                          }
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <MDTypography variant='h6'>Medico Sportivo</MDTypography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        label='nome'
                        error={!!errors.nameSportDoctor}
                        {...register('nameSportDoctor', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        label='cognome'
                        error={!!errors.surnameSportDoctor}
                        {...register('surnameSportDoctor', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <MDInput
                        fullWidth
                        type='text'
                        required={false}
                        label='telefono'
                        error={!!errors.phoneSportDoctor}
                        {...register('phoneSportDoctor', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <MDInput
                        fullWidth
                        type='mail'
                        label='mail'
                        error={!!errors.mailSportDoctor}
                        helperText={
                          errors.mailSportDoctor &&
                          errors.mailSportDoctor.message
                        }
                        {...register('mailSportDoctor', {
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: 'mail non valida'
                          }
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <MDInput
                        fullWidth
                        type='text'
                        label='note'
                        multiline
                        error={!!errors.notes}
                        {...register('notes', { required: false })}
                      />
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox p={2}>
                  <MDButton
                    variant='gradient'
                    color='secondary'
                    component={Link}
                    to='/sponsorships'
                    sx={{ m: 1 }}
                  >
                    Indietro
                  </MDButton>
                  <MDButton
                    type='submit'
                    variant='gradient'
                    color='success'
                    sx={{ m: 1 }}
                  >
                    Salva
                  </MDButton>
                </MDBox>
              </Card>
            </form>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>
  )
}
