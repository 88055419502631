'use strict'
import { useEffect, useContext, useState } from 'react'
// @mui material components
import Card from '@mui/material/Card'
import Icon from '@mui/material/Icon'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { Link as MuiLink } from '@mui/material'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
// import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'
import MDButton from 'components/MDButton'

// Material Dashboard 2 PRO React example components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import DataTable from 'components/Tables/DataTable'

// zerouno
import authService from 'services/auth.service'
import customerService from 'services/customer.service'
import mainService from 'services/production.service'
import enumerationService from 'services/enumeration.service'
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import utils from 'utils/utils.js'
import RabbitContext from 'context/rabbit.js'
import { config } from 'variables/Config.js'

export default function OrderTable () {
  const location = useLocation()

  const [objId, setObjId] = useState(0)
  const { idCustomer } = useParams()
  const [obj, setObj] = useState([])
  const [objects, setObjects] = useState([])
  const [showSnackbar, setShowSnackbar] = useState({
    sh: false,
    msg: '',
    color: 'success'
  })
  const [openConfirm, setOpenConfirm] = useState(false)
  const {
    data: enums,
    isLoading: loadEnum,
    error: errEnums
  } = enumerationService.findByType('orderState')
  const { rabbit } = useContext(RabbitContext)
  
  useEffect(() => {
    fetchObjects()
  }, [])

  const fetchObjects = () => {
    if (idCustomer) {
      customerService
        .get(idCustomer)
        .then(response => {
          setObj(response.data)
        })
        .then(() => {
          return mainService.findByCustomer(idCustomer)
        })
        .then(response2 => {
          setObjects(response2.data)
        })
        .catch(error => {
          setShowSnackbar({
            sh: true,
            msg: error.response
              ? error.response.data.message
              : 'errore di ricezione dati',
            color: 'error'
          })
        })
    } else {
      mainService
        .getAll(rabbit.companyId)
        .then(response => {
          setObjects(response.data)
        })
        .catch(error => {
          setShowSnackbar({
            sh: true,
            msg: error.response
              ? error.response.data.message
              : 'errore di ricezione dati',
            color: 'error'
          })
        })
    }
  }

  // Delete
  const deleteObject = () => {
    setOpenConfirm(false)
    mainService.remove(objId).then(
      response => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
        fetchObjects()
      },
      error => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const columns = [
    {
      Header: 'fornitore',
      accessor: row =>
      row.customer.businessName?.toUpperCase() +
      ' [' +
      row.customer.surname.toUpperCase() +
        ' ' +
        row.customer.name.toUpperCase()
        + ']'
    },
    {
      Header: 'ddt',
      accessor: 'ddt'
    },
    {
      Header: 'data',
      accessor: d => {
        return utils.formatDate(d.dateDdt, 'dd/MM/yyyy')
      }
    },
    {
      Header: 'prodotto',
      accessor: d => {
        return d.productModel?.name
      }
    },
    {
      Header: 'quantità',
      accessor: 'quantity'
    },
    {
      Header: 'prezzo u.',
      accessor: d => d.amount.toFixed(2).replace('.',',')
    },
    {
      Header: 'imponibile.',
      accessor: d => (d.amount * d.quantity).toFixed(2).replace('.',',')
    },
    {
      Header: 'iva',
      accessor: d => d.vat.toFixed(2).replace('.',',')
    },
    {
      Header: 'totale',
      accessor: d => ((d.amount * d.quantity) * (1 + d.vat/100)).toFixed(2).replace('.',',')
    },
    {
      Header: 'saldato',
      accessor: d => d.paid.toFixed(2).replace('.',',')
    },
    {
      Header: 'da pagare',
      accessor: d => ((d.amount * d.quantity) * (1 + d.vat/100) - d.paid).toFixed(2).replace('.',',')
    },
    {
      Header: 'azioni',
      sortable: false,
      filterable: false,
      width: 100,
      accessor: row => (
        <>
          <MDButton
            color='info'
            size='small'
            iconOnly
            component={Link}
            to={`/productions/${row.id}`}
            sx={{ mr: 1 }}
          >
            <Icon>edit</Icon>
          </MDButton>
          <MDButton
            iconOnly
            color='error'
            size='small'
            onClick={() => {
              setObjId(row.id)
              setOpenConfirm(true)
            }}
          >
            <Icon>delete</Icon>
          </MDButton>
        </>
      )
    }
  ]

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <ZUTitleForm
            bgColor='info'
            title='Produzione'
            icon='list_alt'
          />
          <MDBox p={3} lineHeight={1}>
            {idCustomer && (
              <MDButton
                color='secondary'
                component={Link}
                to='/suppliers'
                sx={{ m: 1 }}
              >
                indietro
              </MDButton>
            )}
              <MDButton
                color='info'
                component={Link}
                to={`/productions/new`}
                sx={{ m: 1 }}
              >
                aggiungi
              </MDButton>
            {!idCustomer && authService.isAdmin() && (
              <MDButton
                color='success'
                component={MuiLink}
                href={
                  config.SERVER_URL +
                  '/productions/download/excel?companyId=' +
                  rabbit.companyId
                }
                target='_blank'                
                sx={{ m: 1 }}
              >
                esporta in excel
              </MDButton>
            )}
          </MDBox>
          <DataTable table={{ columns: columns, rows: objects }} canSearch />
        </Card>
      </MDBox>
      <Footer />
      <Dialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          Confermi eliminazione?
        </DialogTitle>
        <DialogActions>
          <MDButton onClick={() => setOpenConfirm(false)}>Annulla</MDButton>
          <MDButton onClick={deleteObject} autoFocus>
            Conferma
          </MDButton>
        </DialogActions>
      </Dialog>
      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>
  )
}
