'use strict'

import http from './http-common'
import authHeader from './auth-header'
import useSWR from 'swr'

const getAll = () => {
  return http.axiosClient.get('/companies', { headers: authHeader() })
}

const get = (id) => {
  return http.axiosClient.get(`/companies/${id}`, { headers: authHeader() })
}

// const create = (data) => {
//   return http.axiosClient.post('/companies', data, { headers: authHeader() })
// }

const update = (id, data) => {
  return http.axiosClient.put(`/companies/${id}`, data, { headers: authHeader() })
}

// const remove = (id) => {
//   return http.axiosClient.delete(`/companies/${id}`, { headers: authHeader() })
// }

const findByName = (name) => {
  return http.axiosClient.get(`/companies?name=${name}`, { headers: authHeader() })
}

export default {
  getAll,
  get,
  // create,
  update,
  // remove,
  findByName
}
