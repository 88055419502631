import { useEffect, useContext, useState } from 'react'
// @material-ui/core components
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
// import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import Autocomplete from '@mui/material/Autocomplete'
import Select from '@mui/material/Select'
import Icon from '@mui/material/Icon'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
// import FormHelperText from '@mui/material/FormHelperText'
import FormControlLabel from '@mui/material/FormControlLabel'

// Material Dashboard 2 PRO React components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'
// import MDAvatar from 'components/MDAvatar'
// import SelectZeroUno from 'components/ZeroUno/SelectZeroUno'

// zerouno
import mainService from 'services/product.service'
import productModelService from 'services/productModel.service'
// import uploadService from 'services/upload.service'
import { useForm, Controller } from 'react-hook-form'
import { useParams, Link, useNavigate } from 'react-router-dom'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import RabbitContext from 'context/rabbit.js'

export default function ProductForm () {
  const { idObj } = useParams()
  const [obj, setObj] = useState({})
  const [models, setModels] = useState([])
  const [showSnackbar, setShowSnackbar] = useState({ sh: false, msg: '', color: 'success' })
  const { register, handleSubmit, formState: { errors }, reset, control, setValue } = useForm()
  const navigate = useNavigate()
  const { rabbit } = useContext(RabbitContext)

  useEffect(() => {
    fetchObject()
    fetchModels()
  }, [])

  useEffect(() => {
    reset({ ...obj })
  }, [obj])

  const fetchModels = () => {
    productModelService.getAll().then(
      (response) => {
        setModels(response.data)
      },
      (error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      }
    )
  }

  const fetchObject = () => {
    if (idObj === 'new') {
      setObj({ productModelId: 'new', active: true })
    } else {
      mainService.get(idObj).then(
        (response) => {
          setObj(response.data)
        },
        (error) => {
          setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
        }
      )
    }
  }

  const onSubmit = (data) => {
    data.companyId = rabbit.companyId
    if (idObj === 'new') {
      addObj(data)
    } else {
      data.id = obj.id
      updateObj(data, obj.id)
    }
  }

  // Add obj
  const addObj = (data) => {
    mainService.create(data).then(
      (response) => {
        setShowSnackbar({ sh: true, msg: 'operazione effettuata con successo', color: 'success' })
        navigate('/products')
      },
      (error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      }
    )
  }

  const updateObj = (data, id) => {
    mainService.update(id, data).then(
      (response) => {
        setShowSnackbar({ sh: true, msg: 'operazione effettuata con successo', color: 'success' })
        navigate('/products')
      },
      (error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      }
    )
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={8}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Card>
                <ZUTitleForm bgColor='info' title='Dispositivo Medico' icon='widgets' />

                <MDBox p={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        autoFocus
                        label='seriale'
                        error={!!(errors.serial)}
                        {...register('serial', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      {obj.productModelId && (
                        <Controller
                          name='productModelId'
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <Autocomplete
                              options={models}
                              value={obj.productModel}
                              onChange={(_, item) => onChange(item.id)}
                              getOptionLabel={(item) => `${item.name}`}
                              isOptionEqualToValue={(item, val) => {
                                return item.id == val.id
                              }}
                              renderInput={params => (
                                <MDInput
                                  {...params}
                                  fullWidth
                                  label='Modello'
                                  error={!!(errors.productModelId)}
                                />
                              )}
                            />
                          )}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <MDInput
                        fullWidth
                        type='text'
                        required={false}
                        label='lotto'
                        error={!!(errors.batch)}
                        {...register('batch', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MDInput
                        fullWidth
                        type='number'
                        required
                        label='anno'
                        error={!!(errors.year)}
                        {...register('year', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <MDInput
                        fullWidth
                        type='text'
                        required={false}
                        label='note'
                        inputProps={{
                          maxLength: 255
                        }}
                        error={!!(errors.notes)}
                        {...register('notes', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Controller
                        control={control}
                        name='active'
                        render={({ field: { value, onChange } }) => (
                          <FormControlLabel
                            control={<Switch checked={value || false} onChange={onChange} />}
                            label='attivo'
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox p={2}>
                  <MDButton variant='gradient' color='secondary' component={Link} to='/products'>
                    Indietro
                  </MDButton>
                  <MDButton type='submit' variant='gradient' color='success' sx={{ ml: 1 }}>
                    Salva
                  </MDButton>
                </MDBox>
              </Card>
            </form>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Card>
              <MDBox p={2}>
                {/* <MDAvatar
                src={obj.image ? config.SERVER_URL + '/download/' + obj.image : avatar}
                alt='profile-image'
                size='xl'
                shadow='sm'
              /> */}
              </MDBox>
              <MDBox p={2}>
                <MDTypography variant='h6'>Apparato Medicale</MDTypography>

              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>
  )
}
