'use strict'

import http from './http-common'
import authHeader from './auth-header'
import useSWR from 'swr'

const getAll = (companyId) => {
  return http.axiosClient.get(`/products?companyId=${companyId}`, { headers: authHeader() })
}
// const getFree = (companyId) => {
//   return http.axiosClient.get(`/products/free?companyId=${companyId}`, { headers: authHeader() })
// }

const getFree = () => {
  return http.axiosClient.get('/products/free', { headers: authHeader() })
}

// const getBusy = (companyId) => {
//   return http.axiosClient.get(`/products/busy?companyId=${companyId}`, { headers: authHeader() })
// }

const getBusy = () => {
  return http.axiosClient.get('/products/busy', { headers: authHeader() })
}

const get = (id) => {
  return http.axiosClient.get(`/products/${id}`, { headers: authHeader() })
}

const create = (data) => {
  return http.axiosClient.post('/products', data, { headers: authHeader() })
}

const update = (id, data) => {
  return http.axiosClient.put(`/products/${id}`, data, { headers: authHeader() })
}

const remove = (id) => {
  return http.axiosClient.delete(`/products/${id}`, { headers: authHeader() })
}

export default {
  getAll,
  getFree,
  getBusy,
  get,
  create,
  update,
  remove
}
