import React, { useEffect, useContext } from 'react'
// @mui material components
import Card from '@mui/material/Card'
import Icon from '@mui/material/Icon'
import Grid from '@mui/material/Grid'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'
import MDButton from 'components/MDButton'
import MDDropdown from 'components/MDDropdown'

// Material Dashboard 2 PRO React example components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import DataTable from 'components/Tables/DataTable'

// zerouno
import customerService from 'services/customer.service'
import authService from 'services/auth.service'
import { Link, useLocation } from 'react-router-dom'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import RabbitContext from 'context/rabbit.js'
import { Tooltip } from '@mui/material'
export default function CustomerTable() {
  const [objId, setObjId] = React.useState(0)
  const [objects, setObjects] = React.useState([])
  const location = useLocation()
  const suppliers = useLocation().pathname.includes('suppliers')
  const [showSnackbar, setShowSnackbar] = React.useState({
    sh: false,
    msg: '',
    color: 'success'
  })
  const [openConfirm, setOpenConfirm] = React.useState(false)
  const [openMenuActions, setOpenMenuActions] = React.useState(false)
  const { rabbit } = useContext(RabbitContext)

  useEffect(() => {
    fetchObjects()
  }, [rabbit.companyId, suppliers])

  const fetchObjects = () => {
    customerService.getAll(rabbit.companyId, suppliers).then(
      (response) => {
        setObjects(response.data)
      },
      (error) => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  // Delete
  const deleteObject = () => {
    setOpenConfirm(false)
    customerService.remove(objId).then(
      (response) => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
        fetchObjects()
      },
      (error) => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const columns = [
    {
      Header: 'cognome',
      accessor: 'surname',
      width: 150
    },
    {
      Header: 'nome',
      accessor: 'name',
      width: 150
    },
    ...(suppliers
      ? [
        {
          Header: 'ragione sociale',
          accessor: 'businessName',
          width: 150
        }]
      : []),
    {
      Header: 'email',
      accessor: 'email',
      width: 150
    },
    {
      Header: 'cell.',
      accessor: 'mobile'
    },
    {
      Header: 'c.f.',
      accessor: 'fiscalCode'
    },
    {
      Header: 'p. iva',
      accessor: 'vat'
    },
    // ...(authService.isAdmin()
    // ? [
    {
      Header: 'utente',
      accessor: (row) => row.user.username
    },
    // ]
    // : []),
    {
      Header: 'azioni',
      sortable: false,
      filterable: false,
      width: 100,
      accessor: 'id',
      Cell: ({ value, row }) => (
        <Grid container spacing={1}>
          {!suppliers &&
            <>
              <Grid item>
                <Tooltip title='noleggi'>
                  <MDButton
                    color='success'
                    size='small'
                    component={Link}
                    iconOnly
                    to={'/customers/' + value + '/orders'}
                  >
                    <Icon>hourglass_top</Icon>
                  </MDButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title='vendite'>
                  <MDButton
                    color='info'
                    size='small'
                    component={Link}
                    iconOnly
                    to={'/customers/' + value + '/salesorders'}
                  >
                    <Icon>shopping_cart</Icon>
                  </MDButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title='pagamenti'>
                  <MDButton
                    color='primary'
                    size='small'
                    component={Link}
                    iconOnly
                    to={'/customers/' + value + '/payments'}
                  >
                    <Icon>payments</Icon>
                  </MDButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <MDButton
                  color='info'
                  size='small'
                  iconOnly
                  component={Link}
                  to={'/customers/' + value}
                >
                  <Icon>edit</Icon>
                </MDButton>
              </Grid>
            </>}
          {suppliers &&
            <>
              <Grid item>
                <MDButton
                  color='info'
                  size='small'
                  iconOnly
                  component={Link}
                  to={'/suppliers/' + value}
                >
                  <Icon>edit</Icon>
                </MDButton>
              </Grid>
              <Grid item>
                <MDButton
                  color='primary'
                  size='small'
                  iconOnly
                  component={Link}
                  to={'/suppliers/' + value + '/invoiceTransactions'}
                >
                  <Icon>payments</Icon>
                </MDButton>
              </Grid>
            </>}
          <Grid item>
            <MDButton
              iconOnly
              color='error'
              size='small'
              onClick={() => {
                setObjId(value)
                setOpenConfirm(true)
              }}
            >
              <Icon>delete</Icon>
            </MDButton>
          </Grid>
        </Grid>
      )
    }
  ]

  const handleOpenMenuActions = (event) => {
    if (openMenuActions && openMenuActions.contains(event.target)) {
      setOpenMenuActions(null)
    } else {
      setOpenMenuActions(event.currentTarget)
    }
  }
  const handleCloseMenuActions = () => setOpenMenuActions(null)
  const renderMenuActions = (value) => (
    <Menu
      id='basic-menu'
      anchorEl={openMenuActions}
      open={Boolean(openMenuActions)}
      onClose={handleCloseMenuActions}
      MenuListProps={{
        'aria-labelledby': 'basic-button'
      }}
      sx={{ mt: 2 }}
    >
      <MenuItem
        key='sedi'
        component={Link}
        to={'/customers/' + value + '/addresses'}
      >
        Sedi
      </MenuItem>
      <MenuItem
        key='doc'
        component={Link}
        to={'/customers/' + value + '/documents'}
      >
        Documenti
      </MenuItem>
      <MenuItem
        key='orders'
        component={Link}
        to={'/customers/' + value + '/orders'}
      >
        Progetti
      </MenuItem>
    </Menu>
  )

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <ZUTitleForm bgColor='info' title={suppliers ? 'fornitori' : 'clienti'} icon='groups' />

          <MDBox p={3} lineHeight={1}>
            {suppliers && (
              <MDButton color='info' component={Link} to='/suppliers/new' m={1}>
                aggiungi
              </MDButton>
            )}
            {!suppliers && (
              <MDButton color='info' component={Link} to='/customers/new' m={1}>
                aggiungi
              </MDButton>

            )}

          </MDBox>
          <DataTable table={{ columns: columns, rows: objects }} canSearch />
        </Card>
      </MDBox>
      <Footer />
      <Dialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          Confermi eliminazione?
        </DialogTitle>
        <DialogActions>
          <MDButton onClick={() => setOpenConfirm(false)}>Annulla</MDButton>
          <MDButton onClick={deleteObject} autoFocus>
            Conferma
          </MDButton>
        </DialogActions>
      </Dialog>
      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>
  )
}
