import { useState, useEffect, useContext } from 'react'
// @material-ui/core components
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'

// Material Dashboard 2 PRO React components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'

// zerouno
import mainService from 'services/congress.service'

import doctorService from 'services/doctor.service'
import { useForm } from 'react-hook-form'
import { useParams, Link, useNavigate } from 'react-router-dom'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import RabbitContext from 'context/rabbit.js'
import utils from 'utils/utils'
import { config } from 'variables/Config.js'

import { DateTime } from 'luxon'
import uploadService from 'services/upload.service'

import ZUMapGeocoder from 'components/ZeroUno/ZUMapGeocoder.js'
import ZUAutoComplete from 'components/ZeroUno/ZUAutoComplete'

export default function CongressForm () {
  const { idObj } = useParams()
  // const [google, setGoogle] = useState({})
  // const [mapValue, setMapValue] = useState({})
  const [obj, setObj] = useState({})
  const [showSnackbar, setShowSnackbar] = useState({ sh: false, msg: '', color: 'success' })
  const { register, handleSubmit, control, formState: { errors }, reset, setValue } = useForm()
  const navigate = useNavigate()
  const { rabbit } = useContext(RabbitContext)
  const { data: doctors, isLoading, error } = doctorService.getAll(rabbit.companyId)

  useEffect(() => {
    fetchObject()
  }, [])

  useEffect(() => {
    reset({ ...obj })
  }, [obj])

  // useEffect(() => {
  // setValue('address', mapValue.address)
  // setValue('civicNumber', mapValue.civicNumber)
  // setValue('cap', mapValue.postalCode)
  // setValue('prov', mapValue.province)
  // setValue('city', mapValue.city)
  // setValue('latitude', mapValue.markerPosition ? mapValue.markerPosition.lat : '0.0')
  // setValue('longitude', mapValue.markerPosition ? mapValue.markerPosition.lng : '0.0')
  // }, [mapValue])

  const fetchObject = () => {
    if (idObj === 'new') {
      setObj({
        doctorId: 'n',
        latitude: '',
        longitude: ''
      })
    } else {
      mainService.get(idObj).then(
        (response) => {
          response.data.contributo = utils.formatFloat(response.data.contributo)
          if (response.data.doctorId == null) {
            response.data.doctorId = 'n'
          }
          setObj(response.data)
        },
        (error) => {
          setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
        }
      )
    }
  }

  const onSubmit = (data) => {
    data.companyId = rabbit.companyId
    data.contributo = String(data.contributo).replace(',', '.')
    if (data.doctorId === 'n') {
      data.doctorId = null
    }
    if (idObj === 'new') {
      addObj(data)
    } else {
      data.id = obj.id
      updateObj(data, obj.id)
    }
  }

  // Add obj
  const addObj = (data) => {
    mainService.create(data).then(
      (response) => {
        setShowSnackbar({ sh: true, msg: 'operazione effettuata con successo', color: 'success' })
        navigate('/congresses')
      },
      (error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      }
    )
  }

  const updateObj = (data, id) => {
    mainService.update(id, data).then(
      (response) => {
        setShowSnackbar({ sh: true, msg: 'operazione effettuata con successo', color: 'success' })
        navigate('/congresses')
      },
      (error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      }
    )
  }

  const uploadContratto = files => {
    const file = files[0]
    if (file) {
      if (file.size <= 15 * 1024 * 1000) {
        const formData = new FormData()
        formData.append('file', file)
        uploadService.uploadOne(formData).then(
          response => {
            const contratto = response.data.filename
            setObj({ ...obj, contratto: contratto })
          },
          error => {
            setShowSnackbar({
              sh: true,
              msg: error.response
                ? error.response.data.message
                : 'errore di ricezione dati',
              color: 'error'
            })
          }
        )
      } else {
        setShowSnackbar({ sh: true, msg: 'file troppo grande', color: 'error' })
      }
    }
  }

  const removeContratto = () => {
    const formData = new FormData()
    formData.delete('contratto')
    setObj({ ...obj, contratto: '' })
  }

  const uploadProgramma = files => {
    const file = files[0]
    if (file) {
      if (file.size <= 15 * 1024 * 1000) {
        const formData = new FormData()
        formData.append('file', file)
        uploadService.uploadOne(formData).then(
          response => {
            const programma = response.data.filename
            setObj({ ...obj, programma: programma })
          },
          error => {
            setShowSnackbar({
              sh: true,
              msg: error.response
                ? error.response.data.message
                : 'errore di ricezione dati',
              color: 'error'
            })
          }
        )
      } else {
        setShowSnackbar({ sh: true, msg: 'file troppo grande', color: 'error' })
      }
    }
  }

  const removeProgramma = () => {
    const formData = new FormData()
    formData.delete('programma')
    setObj({ ...obj, programma: '' })
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={8}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Card>
                <ZUTitleForm bgColor='info' title='Congresso' icon='groups' />
                <MDBox p={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        label='nome'
                        error={!!(errors.nome)}
                        {...register('nome', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MDInput
                        fullWidth
                        type='date'
                        required
                        label='data'
                        error={!!(errors.data)}
                        {...register('data', { required: true })}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        label='città'
                        error={!!(errors.comune)}
                        {...register('comune', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        label='sede congresso'
                        error={!!(errors.sede)}
                        {...register('sede', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <MDInput
                        fullWidth
                        type='text'
                        required={false}
                        label='indirizzo'
                        error={!!(errors.indirizzo)}
                        {...register('indirizzo', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <MDInput
                        fullWidth
                        type='text'
                        label='organizzato da'
                        error={!!(errors.organizzatoDa)}
                        {...register('organizzatoDa', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      {obj.doctorId && (
                        <ZUAutoComplete
                          name='doctorId'
                          control={control}
                          required={false}
                          rules={{ required: false }}
                          options={doctors}
                          defaultValue={obj.doctor || null}
                          getOptionLabel={(item) => `${item.surname} ${item.name} [id: ${item.id}]`}
                          label='Medico'
                          error={!!errors.doctorId}
                        />)}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <MDInput
                        fullWidth
                        type='text'
                        label='focus'
                        error={!!(errors.focus)}
                        {...register('focus', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <MDInput
                        fullWidth
                        type='text'
                        required={false}
                        label='tipologia attività'
                        error={!!(errors.tipologia)}
                        {...register('tipologia', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <MDInput
                        fullWidth
                        type='text'
                        required={false}
                        label='contributo'
                        error={!!(errors.contributo)}
                        {...register('contributo', { required: false, pattern: utils.floatPattern })}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                      {!obj.contratto && (
                        <label htmlFor='contained-button-file'>
                          <MDInput
                            accept='*/*'
                            id='contained-button-file'
                            type='file'
                            sx={{ display: 'none' }}
                            onChange={target => uploadContratto(target.target.files)}
                          />
                          <MDButton
                            variant='gradient'
                            color='info'
                            component='span'
                          >
                            upload contratto
                          </MDButton>
                        </label>
                      )}
                      {obj.contratto && (
                        <>
                          <MDButton
                            color='success'
                            sx={{ mx: 2 }}
                            href={config.SERVER_URL + '/download/' + obj.contratto}
                            target='_blank'
                          >
                            download contratto
                          </MDButton>
                          <MDButton color='error' onClick={() => removeContratto()}>
                            elimina
                          </MDButton>
                        </>
                      )}
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                      {!obj.programma && (
                        <label htmlFor='contained-button-file'>
                          <MDInput
                            accept='*/*'
                            id='contained-button-file'
                            type='file'
                            sx={{ display: 'none' }}
                            onChange={target => uploadProgramma(target.target.files)}
                          />
                          <MDButton
                            variant='gradient'
                            color='info'
                            component='span'
                          >
                            upload programma
                          </MDButton>
                        </label>
                      )}
                      {obj.programma && (
                        <>
                          <MDButton
                            color='success'
                            sx={{ mx: 2 }}
                            href={config.SERVER_URL + '/download/' + obj.programma}
                            target='_blank'
                          >
                            download programma
                          </MDButton>
                          <MDButton color='error' onClick={() => removeProgramma()}>
                            elimina
                          </MDButton>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox p={2}>
                  <MDButton
                    variant='gradient' color='secondary' component={Link} to='/congresses/'
                  >
                    Indietro
                  </MDButton>
                  <MDButton type='submit' variant='gradient' color='success' sx={{ ml: 1 }}>
                    Salva
                  </MDButton>
                </MDBox>
              </Card>
            </form>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Card>
              <MDBox p={2}>
                <MDTypography variant='h6'>Congresso</MDTypography>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content='errore di ricezione dati'
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        // close={() => ''}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>
  )
}
