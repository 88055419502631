'use strict'

import http from './http-common'
import authHeader from './auth-header'
import useSWR from 'swr'

const getAll = (companyId, supplier) => {
  return http.axiosClient.get(`/customers?companyId=${companyId}${supplier ? '&supplier=1' : ''}`, { headers: authHeader() })
}

const get = (id) => {
  return http.axiosClient.get(`/customers/${id}`, { headers: authHeader() })
}

const getWithOrders = (id) => {
  return http.axiosClient.get(`/customers/${id}?include[]=order`, { headers: authHeader() })
}

const create = (data) => {
  return http.axiosClient.post('/customers', data, { headers: authHeader() })
}

const update = (id, data) => {
  return http.axiosClient.put(`/customers/${id}`, data, { headers: authHeader() })
}

const remove = (id) => {
  return http.axiosClient.delete(`/customers/${id}`, { headers: authHeader() })
}

const findByName = (name, companyId) => {
  return http.axiosClient.get(`/customers?companyId=${companyId}&name=${name}`, { headers: authHeader() })
}

const findByCf = (cf, companyId) => {
  return http.axiosClient.get(`/customers/cf/${cf}?companyId=${companyId}`, { headers: authHeader() })
}

const findByVat = (vat, companyId) => {
  return http.axiosClient.get(`/customers/vat/${vat}?companyId=${companyId}`, { headers: authHeader() })
}

export default {
  getAll,
  get,
  getWithOrders,
  create,
  update,
  remove,
  findByName,
  findByCf,
  findByVat
}
