const prod = {
  SERVER_URL: 'https://osteoplus.01rabbit.it/api',
  SAMPLE_DATA: false,
  APP_NAME: 'OsteoPlus Manager',
  GOOGLE_MAPS_API: 'AIzaSyDzLaAoYXD2m5okWolHhLP_Mhtxc9iURrQ',
  MAPBOX_APIKEY: 'xxx'
}

const dev = {
  SERVER_URL: 'http://localhost:8080/api',
  SAMPLE_DATA: false,
  APP_NAME: 'OsteoPlus Manager dev.',
  GOOGLE_MAPS_API: 'AIzaSyABiMtlFCASo01-e2IJp-0nPngep_2DE7k',
  MAPBOX_APIKEY: 'pk.eyJ1IjoiZW5yaWNvZGV2aXRhIiwiYSI6ImNsZ2d1aXh2ZzBmNjgzc282N25tdXYyczAifQ.U7Ou7pwRPJGv1CTgMH2Xtw'
}

export const config = process.env.NODE_ENV === 'production' ? prod : dev
