import { useEffect, useContext, useState } from 'react'
// @mui material components
import Card from '@mui/material/Card'
import Icon from '@mui/material/Icon'
import Grid from '@mui/material/Grid'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'
import MDButton from 'components/MDButton'
import MDDropdown from 'components/MDDropdown'

// Material Dashboard 2 PRO React example components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import DataTable from 'components/Tables/DataTable'

// zerouno
import mainService from 'services/agent.service'
import { Link } from 'react-router-dom'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import RabbitContext from 'context/rabbit.js'

export default function AgentTable () {
  const [objId, setObjId] = useState(0)
  const [objects, setObjects] = useState([])
  const [showSnackbar, setShowSnackbar] = useState({ sh: false, msg: '', color: 'success' })
  const [openConfirm, setOpenConfirm] = useState(false)
  const [openMenuActions, setOpenMenuActions] = useState(false)
  const { rabbit } = useContext(RabbitContext)

  useEffect(() => {
    fetchObjects()
  }, [rabbit.companyId])

  const fetchObjects = () => {
    mainService.getAll(rabbit.companyId).then(
      (response) => {
        setObjects(response.data)
      },
      (error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      }
    )
  }

  // Delete
  const deleteObject = () => {
    setOpenConfirm(false)
    mainService.remove(objId).then(
      (response) => {
        setShowSnackbar({ sh: true, msg: 'operazione effettuata con successo', color: 'success' })
        fetchObjects()
      },
      (error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      }
    )
  }

  const columns = [
    {
      Header: 'cognome',
      accessor: 'surname'
    },
    {
      Header: 'nome',
      accessor: 'name'
    },
    {
      Header: 'telefono',
      accessor: 'phone'
    },
    {
      Header: 'cellulare',
      accessor: 'mobile'
    },
    {
      Header: 'mail',
      accessor: 'mail'
    },
    {
      Header: 'azioni',
      sortable: false,
      filterable: false,
      width: 100,
      accessor: 'id',
      Cell: ({ value, row }) => (
        <Grid container spacing={1}>
          <Grid item>
            <MDButton
              color='info'
              size='small'
              iconOnly
              component={Link}
              to={'/agents/' + value}
            >
              <Icon>edit</Icon>
            </MDButton>
          </Grid>
          <Grid item>
            <MDButton
              iconOnly
              color='error'
              size='small'
              onClick={() => {
                setObjId(value)
                setOpenConfirm(true)
              }}
            >
              <Icon>delete</Icon>
            </MDButton>
          </Grid>
        </Grid>
      )
    }
  ]

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <ZUTitleForm bgColor='info' title='Agenti' icon='work' />

          <MDBox p={3} lineHeight={1}>
            <MDButton color='info' component={Link} to='/agents/new' m={1}>
              aggiungi
            </MDButton>
          </MDBox>
          <DataTable table={{ columns: columns, rows: objects }} canSearch />
        </Card>
      </MDBox>
      <Footer />
      <Dialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          Confermi eliminazione?
        </DialogTitle>
        <DialogActions>
          <MDButton onClick={() => setOpenConfirm(false)}>Annulla</MDButton>
          <MDButton onClick={deleteObject} autoFocus>Conferma</MDButton>
        </DialogActions>
      </Dialog>
      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>

  )
}
