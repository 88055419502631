import { createContext } from 'react'

const defaultValue = {
  companyId: 1,
  company: {},
  black: false
}

const RabbitContext = createContext(defaultValue)
export default RabbitContext
