'use strict'

import http from './http-common'
import authHeader from './auth-header'

const getAll = (companyId) => {
  return http.axiosClient.get(`/congresses?companyId=${companyId}`, {
    headers: authHeader()
  })
}

const revalidate = (companyId, mutate) => {
  mutate([`/congresses?companyId=${companyId}`, { headers: authHeader() }])
}

const get = (id) => {
  return http.axiosClient.get(`/congresses/${id}`, { headers: authHeader() })
}

const create = (data) => {
  return http.axiosClient.post('/congresses', data, { headers: authHeader() })
}

const update = (id, data) => {
  return http.axiosClient.put(`/congresses/${id}`, data, { headers: authHeader() })
}

const remove = (id) => {
  return http.axiosClient.delete(`/congresses/${id}`, { headers: authHeader() })
}

export default {
  getAll,
  get,
  create,
  update,
  remove,
  revalidate
}
