'use strict'

import http from './http-common'
import authHeader from './auth-header'

const getAll = (companyId) => {
  return http.axiosClient.get(
    `/invoices?companyId=${companyId}`,
    {
      headers: authHeader()
    }
  )
}

const get = (id) => {
  return http.axiosClient.get(`/invoices/${id}`, {
    headers: authHeader()
  })
}

const create = (data) => {
  return http.axiosClient.post('/invoices', data, {
    headers: authHeader()
  })
}

const update = (id, data) => {
  return http.axiosClient.put(`/invoices/${id}`, data, {
    headers: authHeader()
  })
}

const importXml = (companyId, data) => {
  return http.axiosClient.post(`/invoices/importXml/${companyId}`, data, {
    headers: authHeader()
  })
}

const remove = (id) => {
  return http.axiosClient.delete(`/invoices/${id}`, {
    headers: authHeader()
  })
}

const findByCustomer = (customerId) => {
  return http.axiosClient.get(
    `/invoices?customerId=${customerId}`,
    {
      headers: authHeader()
    }
  )
}

const downloadExcel = (companyId) => {
  return http.axiosClient.get(`/invoices/download/excel?companyId=${companyId}`, {
    headers: authHeader()
  })
}

export default {
  getAll,
  get,
  create,
  update,
  importXml,
  remove,
  findByCustomer,
  downloadExcel
}
