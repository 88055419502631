'use strict'

import { useEffect, useContext, useState } from 'react'
// @material-ui/core components
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import FormControlLabel from '@mui/material/FormControlLabel'

// Material Dashboard 2 PRO React components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'

// zerouno
import customerService from 'services/customer.service'
import invoiceService from 'services/invoice.service'
import invoiceTransactionService from 'services/invoiceTransaction.service'
import enumerationService from 'services/enumeration.service'
import { useForm, Controller } from 'react-hook-form'
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import utils from 'utils/utils'
import { DateTime } from 'luxon'
import RabbitContext from 'context/rabbit.js'
import ZUSelect from 'components/ZeroUno/ZUSelect'

export default function InvoiceTransactionForm() {
  const { idObj } = useParams()
  const { idInvoice } = useParams()
  const { data: enums, isLoading: loadEnum, error: errEnums } = enumerationService.findByType('account')
  const [showSnackbar, setShowSnackbar] = useState({ sh: false, msg: '', color: 'success' })
  const navigate = useNavigate()
  const { rabbit } = useContext(RabbitContext)
  const { register, handleSubmit, formState: { errors }, watch, control, getValues, setValue, reset } = useForm()
  const [invoice, setInvoice] = useState({})
  const [obj, setObj] = useState({})

  useEffect(() => {
    fetchAll()
  }, [])

  const fetchAll = () => {
    if (idObj === 'new') {
      invoiceService.get(idInvoice).then(
        (response) => {
          setInvoice(response.data)
          return response.data
        }).then((inv) => {
          setObj({
            invoiceId: idInvoice,
            accountId: '31',
            dateTransaction: DateTime.now().toISODate(),
            note: '',
            amount: utils.formatFloat(inv.totalAmount - inv.paid)
          })
        }).catch((error) => {
          setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
        })
    } else {
      invoiceTransactionService.get(idObj).then(
        (response1) => {
          response1.data.amount = utils.formatFloat(response1.data.amount)
          // response1.data.dateTransaction = DateTime.fromISO(obj.dateTransaction).toISODate()
          return response1.data
        }).then(trans => {
          invoiceService.get(trans.invoiceId).then(
            (response2) => {
              setInvoice(response2.data)
            }
          )
          setObj(trans)
        }).catch((error) => {
          setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
        })
    }
  }

  useEffect(() => {
    reset({ ...obj })
    setValue('dateTransaction', DateTime.fromISO(obj.dateTransaction).toISODate())
    setValue('amount', utils.formatFloat(obj.amount))
    if (idObj != 'new') {
      setValue('acountId', obj.accountId)
    }
  }, [obj, enums])

  const onSubmit = (data) => {
    data.invoiceId = idInvoice
    data.amount = data.amount.replace(',', '.')

    // data.accountId = data.account?.id
    if (idObj === 'new') {
      addObj(data)
    } else {
      data.id = obj.id
      updateObj(data, obj.id)
    }
  }

  // Add obj
  const addObj = (data) => {
    invoiceTransactionService.create(data).then(
      (response) => {
        setShowSnackbar({ sh: true, msg: 'operazione effettuata con successo', color: 'success' })
        navigate(`/invoices/${idInvoice}/invoiceTransactions`)
      },
      (error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      }
    )
  }

  const updateObj = (data, id) => {
    invoiceTransactionService.update(id, data).then(
      (response) => {
        setShowSnackbar({ sh: true, msg: 'operazione effettuata con successo', color: 'success' })
        navigate(`/invoices/${idInvoice}/invoiceTransactions`)
      },
      (error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      }
    )
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={8}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Card>
                <ZUTitleForm bgColor='info' title='Pagamento Fattura' icon='euro' />
                <MDBox p={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      {invoice && (
                        <MDTypography>fornitore: {invoice.customer?.businessName}</MDTypography>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      {invoice && (
                        <MDTypography>fattura: {invoice.number} {invoice.sub} / {invoice.year}</MDTypography>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      {invoice && (
                        <MDTypography variant='h6'>importo da pagare:  € {utils.formatFloat(invoice.totalAmount - invoice.paid)}/{utils.formatFloat(invoice.totalAmount)}</MDTypography>
                      )}
                    </Grid>

                    <Grid item xs={12} sm={12} md={3}>
                      <MDInput
                        fullWidth
                        type='date'
                        required
                        label='data'
                        error={!!(errors.dateTransaction)}
                        {...register('dateTransaction', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        label='importo'
                        error={!!(errors.amount)}
                        helperText={errors.amount && 'dati non corretti'}
                        {...register('amount', { required: true, pattern: utils.floatPattern })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      {obj && obj.accountId && enums && (
                        <ZUSelect
                          error={!!errors.accountId}
                          label='modalità pagamento'
                          defaultValue={obj.accountId ?? ''}
                          register={register}
                          name='accountId'
                          required
                          rules={{ required: true }}
                        >
                          {enums &&
                            enums.map((item) => (
                              <MenuItem key={item.id} value={item.id}>
                                {item.description}
                              </MenuItem>
                            ))}
                        </ZUSelect>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <MDInput
                        fullWidth
                        type='text'
                        required={false}
                        label='note'
                        error={!!(errors.notes)}
                        {...register('notes', { required: false })}
                      />
                    </Grid>

                  </Grid>
                </MDBox>
                <MDBox p={2}>
                  <MDButton
                    variant='gradient' color='secondary' component={Link} to='/invoices'
                  >
                    fatture
                  </MDButton>
                  <MDButton
                    variant='gradient' color='secondary' component={Link} to={`/invoices/${idInvoice}/invoiceTransactions`}
                  >
                    pagamenti fattura
                  </MDButton>
                  <MDButton
                    variant='gradient' color='secondary' component={Link} to='/invoiceTransactions' sx={{ ml: 1 }}
                  >
                    tutti i pagamenti
                  </MDButton>
                  <MDButton type='submit' variant='gradient' color='success' sx={{ ml: 1 }}>
                    Salva
                  </MDButton>
                </MDBox>
              </Card>
            </form>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Card>
              <MDBox p={2}>
                <MDTypography variant='h6'>Menù</MDTypography>
                <Stack spacing={2}>
                  {invoice && (
                    <MDButton
                      color='info'
                      component={Link}
                      to={'/suppliers/' + invoice.customerId}
                    >
                      fornitore
                    </MDButton>

                  )}
                  <MDButton
                    color='info'
                    component={Link}
                    to='/invoices'
                  >
                    fatture
                  </MDButton>
                  <MDButton
                    color='info'
                    component={Link}
                    to={'/invoices/' + idInvoice + '/invoiceTransactions'}
                  >
                    pagamenti
                  </MDButton>
                </Stack>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>
  )
}
