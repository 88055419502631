import { useState, useEffect, useContext } from 'react'
// @material-ui/core components
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Link as MuiLink } from '@mui/material'

// Material Dashboard 2 PRO React components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'

// zerouno
import mainService from 'services/maintenance.service'
import customerService from 'services/customer.service'
import productService from 'services/product.service'

import { useForm, Controller } from 'react-hook-form'
import { useParams, Link, useNavigate } from 'react-router-dom'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import RabbitContext from 'context/rabbit.js'
import utils from 'utils/utils'
import { config } from 'variables/Config.js'

import { DateTime } from 'luxon'
import uploadService from 'services/upload.service'

import ZUAutoComplete from 'components/ZeroUno/ZUAutoComplete'

export default function MaintenanceForm () {
  const { idObj } = useParams()
  const [obj, setObj] = useState({})
  const [products, setProducts] = useState([])
  const [customers, setCustomers] = useState([])
  const [showSnackbar, setShowSnackbar] = useState({ sh: false, msg: '', color: 'success' })
  const { register, handleSubmit, control, formState: { errors }, reset, setValue } = useForm()
  const navigate = useNavigate()
  const { rabbit } = useContext(RabbitContext)

  useEffect(() => {
    fetchProducts()
    fetchCustomers()
    fetchObject()
  }, [])

  useEffect(() => {
    reset({ ...obj })
  }, [obj, products, customers])

  const fetchObject = () => {
    if (idObj === 'new') {
      setObj({
        productId: 'n',
        customerId: 'n',
        data: DateTime.now().toISODate()
      })
    } else {
      mainService.get(idObj).then(
        (response) => {
          setObj(response.data)
        },
        (error) => {
          setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
        }
      )
    }
  }

  const fetchProducts = () => {
    productService.getAll()
      .then((response1) => {
        setProducts(response1.data)
      }).catch((error) => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      })
  }

  const fetchCustomers = () => {
    customerService
      .getAll(rabbit.companyId, false)
      .then((response) => {
        setCustomers(response.data)
      })
      .catch((error) => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      })
  }

  const onSubmit = (data) => {
    data.companyId = rabbit.companyId
    if (data.productId === 'n') {
      setShowSnackbar({ sh: true, msg: 'prodotto obbligatorio', color: 'error' })
      return
    }
    if (data.customerId === 'n') {
      setShowSnackbar({ sh: true, msg: 'cliente obbligatorio', color: 'error' })
      return
    }
    if (idObj === 'new') {
      addObj(data)
    } else {
      data.id = obj.id
      updateObj(data, obj.id)
    }
  }

  // Add obj
  const addObj = (data) => {
    mainService.create(data).then(
      (response) => {
        setShowSnackbar({ sh: true, msg: 'operazione effettuata con successo', color: 'success' })
        navigate('/maintenances')
      },
      (error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      }
    )
  }

  const updateObj = (data, id) => {
    mainService.update(id, data).then(
      (response) => {
        setShowSnackbar({ sh: true, msg: 'operazione effettuata con successo', color: 'success' })
        navigate('/maintenances')
      },
      (error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      }
    )
  }

  const uploadFile = files => {
    const file = files[0]
    if (file) {
      if (file.size <= 3 * 1024 * 1000) {
        const formData = new FormData()
        formData.append('file', file)
        uploadService.uploadOne(formData).then(
          response => {
            const allegato = response.data.filename
            setObj({ ...obj, allegato: allegato })
          },
          error => {
            setShowSnackbar({
              sh: true,
              msg: error.response
                ? error.response.data.message
                : 'errore di ricezione dati',
              color: 'error'
            })
          }
        )
      } else {
        setShowSnackbar({ sh: true, msg: 'file troppo grande, max 3 mb', color: 'error' })
      }
    }
  }

  const removeFile = () => {
    const formData = new FormData()
    formData.delete('allegato')
    setObj({ ...obj, allegato: '' })
  }

  const sendMail = () => {
    mainService.sendMail(idObj).then(
      (response) => {
        setShowSnackbar({
          sh: true,
          msg: response.data.message,
          color: 'success'
        })
      },
      (error) => {
        setShowSnackbar({
          sh: true,
          msg: error.response ? error.response.data.message : 'errore invio',
          color: 'error'
        })
      }
    )
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={8}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Card>
                <ZUTitleForm bgColor='info' title='Manutenzione' icon='handyman' />
                <MDBox p={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                      {obj.productId && (
                        <ZUAutoComplete
                          name='productId'
                          control={control}
                          required
                          rules={{ required: true }}
                          options={products}
                          defaultValue={obj.product || null}
                          getOptionLabel={(item) => `${item.serial} ${item.productModel.name}`}
                          label='prodotto'
                          error={!!errors.productId}
                        />)}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      {obj.customerId && (
                        <ZUAutoComplete
                          name='customerId'
                          control={control}
                          required
                          rules={{ required: true }}
                          options={customers}
                          defaultValue={obj.customer || null}
                          getOptionLabel={(item) => `${item.surname} ${item.name}`}
                          label='cliente'
                          error={!!errors.customerId}
                        />)}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MDInput
                        fullWidth
                        type='date'
                        required
                        label='data'
                        error={!!(errors.data)}
                        {...register('data', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <MDInput
                        fullWidth
                        type='text'
                        required={false}
                        label='ddt'
                        error={!!(errors.ddt)}
                        {...register('ddt', { required: false })}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4} md={4}>
                      <Controller
                        control={control}
                        name='chiusa'
                        render={({ field: { value, onChange } }) => (
                          <FormControlLabel
                            control={<Switch checked={value || false} onChange={onChange} />}
                            label='chiusa'
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <MDInput
                        fullWidth
                        type='text'
                        label='accessori'
                        multiline
                        rows={4}
                        error={!!errors.accessori}
                        {...register('accessori', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <MDInput
                        fullWidth
                        type='text'
                        label='descrizione'
                        multiline
                        required
                        rows={4}
                        error={!!errors.descrizione}
                        {...register('descrizione', { required: true })}
                      />
                    </Grid>

                    {idObj !== 'new' && (
                      <Grid item xs={12} sm={12} md={12}>
                        {!obj.allegato && (
                          <label htmlFor='contained-button-file'>
                            <MDInput
                              accept='*/*'
                              id='contained-button-file'
                              type='file'
                              sx={{ display: 'none' }}
                              onChange={target => uploadFile(target.target.files)}
                            />
                            <MDButton
                              variant='gradient'
                              color='info'
                              component='span'
                            >
                              upload modulo manutenzione firmato
                            </MDButton>
                          </label>
                        )}
                        {obj.allegato && (
                          <>
                            <MDButton
                              color='success'
                              sx={{ mx: 2 }}
                              href={config.SERVER_URL + '/download/' + obj.allegato}
                              target='_blank'
                            >
                              download modulo manutenzione
                            </MDButton>
                            <MDButton color='error' onClick={() => removeFile()}>
                              elimina
                            </MDButton>
                          </>
                        )}
                      </Grid>
                    )}
                  </Grid>
                </MDBox>
                <MDBox p={2}>
                  <MDButton
                    variant='gradient' color='secondary' component={Link} to='/maintenances/'
                  >
                    Indietro
                  </MDButton>
                  <MDButton type='submit' variant='gradient' color='success' sx={{ ml: 1 }}>
                    Salva
                  </MDButton>
                </MDBox>
              </Card>
            </form>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Card>
              <MDBox p={2}>
                <MDTypography variant='h6'>Manutenzione</MDTypography>
                {idObj !== 'new' && obj.product && (
                  <Stack direction='column' spacing={1}>
                    <MDButton
                      color='success'
                      component={MuiLink}
                      href={config.SERVER_URL + '/maintenances/pdf/' + obj.id}
                      target='_blank'
                    >
                      genera pdf
                    </MDButton>
                    <MDButton
                      color='success'
                      onClick={() => {
                        sendMail()
                      }}
                    >
                      invia pdf in mail
                    </MDButton>
                  </Stack>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content='errore di ricezione dati'
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        // close={() => ''}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>
  )
}
