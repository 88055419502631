'use strict'

import http from './http-common'
import authHeader from './auth-header'
import useSWR from 'swr'

const getAll = (companyId) => {
  return http.axiosClient.get(`/agents?companyId=${companyId}`, { headers: authHeader() })
}

const get = (id) => {
  return http.axiosClient.get(`/agents/${id}`, { headers: authHeader() })
}

const create = (data) => {
  return http.axiosClient.post('/agents', data, { headers: authHeader() })
}

const update = (id, data) => {
  return http.axiosClient.put(`/agents/${id}`, data, { headers: authHeader() })
}

const remove = (id) => {
  return http.axiosClient.delete(`/agents/${id}`, { headers: authHeader() })
}

const findByName = (name, companyId) => {
  return http.axiosClient.get(`/agents?companyId=${companyId}&name=${name}`, { headers: authHeader() })
}

export default {
  getAll,
  get,
  create,
  update,
  remove,
  findByName
}
