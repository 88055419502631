import React, { useEffect } from 'react'
// @material-ui/core components
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import Select from '@mui/material/Select'
import Icon from '@mui/material/Icon'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import FormControlLabel from '@mui/material/FormControlLabel'

// Material Dashboard 2 PRO React components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'
import MDAvatar from 'components/MDAvatar'
import SelectZeroUno from 'components/ZeroUno/SelectZeroUno'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'

// zerouno
import companyService from 'services/company.service'
import uploadService from 'services/upload.service'
import { useForm, Controller } from 'react-hook-form'
import { useParams, Link, useNavigate } from 'react-router-dom'

export default function CompanyForm () {
  const { idCompany } = useParams()
  const [obj, setObj] = React.useState({})
  const [showSnackbar, setShowSnackbar] = React.useState({ sh: false, msg: '', color: 'success' })
  const { register, handleSubmit, formState: { errors }, control, getValues, reset } = useForm()
  const navigate = useNavigate()

  useEffect(() => {
    fetchObject()
  }, [])

  useEffect(() => {
    reset({ ...obj })
  }, [obj])

  const fetchObject = () => {
    if (idCompany === 'new') {
      setObj({})
    } else {
      companyService.get(idCompany).then(
        (response) => {
          setObj(response.data)
        },
        (error) => {
          setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
        }
      )
    }
  }

  const onSubmit = (data) => {
    if (idCompany === 'new') {
      addObj(data)
    } else {
      data.id = obj.id
      updateObj(data, obj.id)
    }
  }

  // Add obj
  const addObj = (data) => {
    // data.image = obj.image
    companyService.create(data).then(
      (response) => {
        setShowSnackbar({ sh: true, msg: 'operazione effettuata con successo', color: 'success' })
        navigate('/companies')
      },
      (error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      }
    )
  }

  const updateObj = (data, id) => {
    // data.image = obj.image
    companyService.update(id, data).then(
      (response) => {
        setShowSnackbar({ sh: true, msg: 'operazione effettuata con successo', color: 'success' })
        navigate('/companies')
      },
      (error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      }
    )
  }

  // const uploadFile = (files) => {
  //   const file = files[0]
  //   if (file) {
  //     if (file.size <= 1024000) {
  //       const formData = new FormData()
  //       formData.append('file', file)
  //       uploadService.uploadOne(formData).then(
  //         (response) => {
  //           const image = response.data.filename
  //           setObj({ ...obj, image: image })
  //         },
  //         (error) => {
  //           setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
  //         }
  //       )
  //     } else {
  //       setShowSnackbar({ sh: true, msg: 'file troppo grande', color: 'error' })
  //     }
  //   }
  // }

  // const removeFile = () => {
  //   const formData = new FormData()
  //   formData.delete('file')
  //   setObj({ ...obj, image: '' })
  // }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={8}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Card>
                <ZUTitleForm bgColor='info' title='Azienda' icon='language' />
                <MDBox p={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        autoFocus
                        label='ragione sociale'
                        error={!!(errors.name)}
                        {...register('name', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={9}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        label='indirizzo'
                        error={!!(errors.address)}
                        {...register('address', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        label='civico'
                        error={!!(errors.civicNumber)}
                        {...register('civicNumber', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        label='città'
                        error={!!(errors.city)}
                        {...register('city', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        label='prov'
                        error={!!(errors.prov)}
                        {...register('prov', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        autoFocus
                        label='cap'
                        error={!!(errors.cap)}
                        {...register('cap', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <MDInput
                        fullWidth
                        type='text'
                        required={false}
                        autoFocus
                        label='partita iva'
                        error={!!(errors.vat)}
                        {...register('vat', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <MDInput
                        fullWidth
                        type='text'
                        required={false}
                        autoFocus
                        label='codice fiscale'
                        error={!!(errors.fiscalCode)}
                        {...register('fiscalCode', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <MDInput
                        fullWidth
                        type='email'
                        required={false}
                        autoFocus
                        label='pec'
                        error={!!(errors.pec)}
                        {...register('pec', {
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: 'mail non valida'
                          }
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <MDInput
                        fullWidth
                        type='text'
                        label='codice destinatario'
                        required={false}
                        error={!!(errors.codeInvoice)}
                        {...register('codeInvoice', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} />
                  </Grid>
                </MDBox>
                <MDBox p={2}>
                  <MDButton
                    variant='gradient' color='secondary' component={Link} to='/companies/'
                  >
                    Indietro
                  </MDButton>
                  <MDButton type='submit' variant='gradient' color='success' sx={{ ml: 1 }}>
                    Salva
                  </MDButton>
                </MDBox>
              </Card>
            </form>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Card>
              <MDBox p={2}>
                <MDTypography variant='h6'>Cliente</MDTypography>
                <MDTypography variant='h4'>{obj.name}</MDTypography>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>

  )
}
