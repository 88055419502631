'use strict'

import http from './http-common'
import authHeader from './auth-header'
import useSWR from 'swr'

const getAll = (companyId) => {
  return http.axiosClient.get(`/healthDoctors?companyId=${companyId}`, {
    headers: authHeader()
  })
}

const revalidate = (companyId, mutate) => {
  mutate([`/healthDoctors?companyId=${companyId}`, { headers: authHeader() }])
}

const get = (id) => {
  return http.axiosClient.get(`/healthDoctors/${id}`, { headers: authHeader() })
}

const create = (data) => {
  return http.axiosClient.post('/healthDoctors', data, { headers: authHeader() })
}

const update = (id, data) => {
  return http.axiosClient.put(`/healthDoctors/${id}`, data, { headers: authHeader() })
}

const remove = (id) => {
  return http.axiosClient.delete(`/healthDoctors/${id}`, { headers: authHeader() })
}

const downloadExcel = (companyId) => {
  return http.axiosClient.get(`/healthDoctors/download/excel?companyId=${companyId}`, {
    headers: authHeader()
  })
}

export default {
  getAll,
  get,
  create,
  update,
  remove,
  revalidate,
  downloadExcel
}
