import { useState, useEffect, useContext } from 'react'
// @material-ui/core components
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
// import Stack from '@mui/material/Stack'
// import Switch from '@mui/material/Switch'
// import Select from '@mui/material/Select'
// import Icon from '@mui/material/Icon'
// import InputLabel from '@mui/material/InputLabel'
// import MenuItem from '@mui/material/MenuItem'
// import FormControl from '@mui/material/FormControl'
// import FormHelperText from '@mui/material/FormHelperText'
// import FormControlLabel from '@mui/material/FormControlLabel'

// Material Dashboard 2 PRO React components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'
// import SelectZeroUno from 'components/ZeroUno/SelectZeroUno'

// zerouno
import mainService from 'services/healthcareFacility.service'
import { useForm } from 'react-hook-form'
import { useParams, Link, useNavigate } from 'react-router-dom'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import RabbitContext from 'context/rabbit.js'
import ZUMapGeocoder from 'components/ZeroUno/ZUMapGeocoder.js'

export default function HealthcareFacilityForm() {
  const { idObj } = useParams()
  const [google, setGoogle] = useState({})
  const [mapValue, setMapValue] = useState({})
  const [obj, setObj] = useState({})
  const [showSnackbar, setShowSnackbar] = useState({ sh: false, msg: '', color: 'success' })
  const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm()
  const navigate = useNavigate()
  const { rabbit } = useContext(RabbitContext)

  useEffect(() => {
    fetchObject()
  }, [])

  useEffect(() => {
    reset({ ...obj })
  }, [obj])

  useEffect(() => {
    setValue('address', mapValue.address)
    setValue('civicNumber', mapValue.civicNumber)
    setValue('cap', mapValue.postalCode)
    setValue('prov', mapValue.province)
    setValue('city', mapValue.city)
    setValue('latitude', mapValue.markerPosition ? mapValue.markerPosition.lat : '0.0')
    setValue('longitude', mapValue.markerPosition ? mapValue.markerPosition.lng : '0.0')
  }, [mapValue])

  const fetchObject = () => {
    if (idObj === 'new') {
      setObj({
        latitude: '40.8359215',
        longitude: '14.2474822'
      })
    } else {
      mainService.get(idObj).then(
        (response) => {
          setObj(response.data)
        },
        (error) => {
          setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
        }
      )
    }
  }

  const onSubmit = (data) => {
    data.companyId = rabbit.companyId
    // data.image = obj.image

    if (idObj === 'new') {
      addObj(data)
    } else {
      data.id = obj.id
      updateObj(data, obj.id)
    }
  }

  // Add obj
  const addObj = (data) => {
    mainService.create(data).then(
      (response) => {
        setShowSnackbar({ sh: true, msg: 'operazione effettuata con successo', color: 'success' })
        navigate('/healthcareFacilities')
      },
      (error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      }
    )
  }

  const updateObj = (data, id) => {
    mainService.update(id, data).then(
      (response) => {
        setShowSnackbar({ sh: true, msg: 'operazione effettuata con successo', color: 'success' })
        navigate('/healthcareFacilities')
      },
      (error) => {
        setShowSnackbar({ sh: true, msg: error.response ? error.response.data.message : 'errore di ricezione dati', color: 'error' })
      }
    )
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={8}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Card>
                <ZUTitleForm bgColor='info' title='Struttura Sanitaria' icon='medical_information' />
                <MDBox p={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        autoFocus
                        label='nome'
                        error={!!(errors.name)}
                        {...register('name', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <MDInput
                        fullWidth
                        type='text'
                        required
                        label='Reparto'
                        error={!!(errors.department)}
                        {...register('department', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      {obj && obj.latitude &&
                        <ZUMapGeocoder
                          mapValue={mapValue}
                          updateMapValue={setMapValue}
                          google={google}
                          center={{ lat: parseFloat(obj.latitude), lng: parseFloat(obj.longitude) }}
                          height='300px'
                          zoom={15}
                        />}
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} />
                    <Grid item xs={12} sm={12} md={12} />
                    <Grid item xs={12} sm={6} md={4}>
                      <MDInput
                        fullWidth
                        type='text'
                        required={false}
                        label='indirizzo'
                        error={!!(errors.address)}
                        {...register('address', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MDInput
                        fullWidth
                        type='text'
                        required={false}
                        label='civico'
                        error={!!(errors.civicNumber)}
                        {...register('civicNumber', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MDInput
                        fullWidth
                        type='text'
                        required={false}
                        label='cap'
                        error={!!(errors.cap)}
                        {...register('cap', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MDInput
                        fullWidth
                        type='text'
                        required={false}
                        label='comune'
                        error={!!(errors.city)}
                        {...register('city', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MDInput
                        fullWidth
                        type='text'
                        required={false}
                        label='provincia'
                        error={!!(errors.prov)}
                        {...register('prov', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MDInput
                        fullWidth
                        type='text'
                        required={false}
                        label='latitudine'
                        error={!!(errors.latitude)}
                        {...register('latitude', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <MDInput
                        fullWidth
                        type='text'
                        required={false}
                        label='longitudine'
                        error={!!(errors.longitude)}
                        {...register('longitude', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} />
                  </Grid>
                </MDBox>
                <MDBox p={2}>
                  <MDButton
                    variant='gradient' color='secondary' component={Link} to='/healthcareFacilities/'
                  >
                    Indietro
                  </MDButton>
                  <MDButton type='submit' variant='gradient' color='success' sx={{ ml: 1 }}>
                    Salva
                  </MDButton>
                </MDBox>
              </Card>
            </form>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Card>
              <MDBox p={2}>
                <MDTypography variant='h6'>Struttura Sanitaria</MDTypography>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content='errore di ricezione dati'
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        // close={() => ''}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>
  )
}
