import React, { useEffect } from 'react'
// @material-ui/core components
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Switch from '@mui/material/Switch'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import FormControlLabel from '@mui/material/FormControlLabel'

// Material Dashboard 2 PRO React components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'
import MDAvatar from 'components/MDAvatar'
import SelectZeroUno from 'components/ZeroUno/SelectZeroUno'

// zerouno
import avatar from 'assets/images/avatar.png'
import { config } from 'variables/Config.js'
import userService from 'services/user.service'
import uploadService from 'services/upload.service'
import { useForm, Controller } from 'react-hook-form'
import { useParams, Link, useNavigate } from 'react-router-dom'

export default function UserForm() {
  const { idUser } = useParams()
  const [obj, setObj] = React.useState({})
  const [showSnackbar, setShowSnackbar] = React.useState({
    sh: false,
    msg: '',
    color: 'success'
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    reset
  } = useForm()
  const navigate = useNavigate()

  useEffect(() => {
    fetchObject()
  }, [])

  useEffect(() => {
    reset({ ...obj, password: '' })
  }, [obj])

  const fetchObject = () => {
    if (idUser === 'new') {
      setObj({ role: 'user' })
    } else {
      userService.get(idUser).then(
        response => {
          setObj(response.data)
        },
        error => {
          setShowSnackbar({
            sh: true,
            msg: error.response
              ? error.response.data.message
              : 'errore di ricezione dati',
            color: 'error'
          })
        }
      )
    }
  }

  const onSubmit = data => {
    if (idUser === 'new') {
      addObj(data)
    } else {
      data.id = obj.id
      updateObj(data, obj.id)
    }
  }

  // Add obj
  const addObj = data => {
    data.image = obj.image
    userService.create(data).then(
      response => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
        navigate('/users')
      },
      error => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const updateObj = (data, id) => {
    data.image = obj.image
    userService.update(id, data).then(
      response => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
        navigate('/users')
      },
      error => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const uploadFile = files => {
    const file = files[0]
    if (file) {
      if (file.size <= 1024000) {
        const formData = new FormData()
        formData.append('file', file)
        uploadService.uploadOne(formData).then(
          response => {
            const image = response.data.filename
            setObj({ ...obj, image: image })
          },
          error => {
            setShowSnackbar({
              sh: true,
              msg: error.response
                ? error.response.data.message
                : 'errore di ricezione dati',
              color: 'error'
            })
          }
        )
      } else {
        setShowSnackbar({ sh: true, msg: 'file troppo grande', color: 'error' })
      }
    }
  }

  const removeFile = () => {
    const formData = new FormData()
    formData.delete('file')
    setObj({ ...obj, image: '' })
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={8}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Card>
              <MDBox p={2}>
                <Grid container spacing={2}>
                  <Grid item xs='auto'>
                    <MDAvatar
                      src={
                        obj.image
                          ? config.SERVER_URL + '/download/' + obj.image
                          : avatar
                      }
                      alt='profile-image'
                      size='md'
                      shadow='md'
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <MDTypography fontWeight='regular'>
                      {obj.username}
                    </MDTypography>
                    <MDTypography variant='subtitle2'>
                      {obj.name} {obj.surname}
                    </MDTypography>
                    <MDTypography variant='subtitle2'>{obj.role}</MDTypography>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox p={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12}>
                    <MDInput
                      fullWidth
                      type='text'
                      required
                      autoFocus
                      label='username'
                      error={!!errors.username}
                      InputProps={{
                        readOnly: obj.id !== undefined
                      }}
                      {...register('username', { required: true })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDInput
                      fullWidth
                      type='text'
                      label='nome'
                      required
                      error={!!errors.name}
                      {...register('name', { required: true })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDInput
                      fullWidth
                      type='text'
                      label='cognome'
                      required
                      error={!!errors.surname}
                      {...register('surname', { required: true })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDInput
                      fullWidth
                      type='password'
                      label='Password'
                      error={!!errors.password}
                      autoComplete='new-password'
                      {...register('password')}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <MDInput
                      fullWidth
                      type='password'
                      label='conferma password'
                      error={!!errors.confirmPassword}
                      autoComplete='new-password'
                      {...register('confirmPassword', {
                        validate: () =>
                          getValues('confirmPassword') === getValues('password')
                      })}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <MDInput
                      fullWidth
                      type='email'
                      label='email'
                      required
                      error={!!errors.email}
                      {...register('email', {
                        required: true,
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: 'mail non valida'
                        }
                      })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    {obj.role && (
                      <FormControl fullWidth required error={!!errors.role}>
                        <InputLabel>Ruolo</InputLabel>
                        <Select
                          label='Ruolo'
                          defaultValue={obj.role}
                          {...register('role', { required: true })}
                        >
                          <MenuItem value=''>seleziona un ruolo</MenuItem>
                          <MenuItem value='agente'>agente</MenuItem>
                          <MenuItem value='tecnico'>tecnico</MenuItem>
                          <MenuItem value='tecnico_agente'>tecnico_agente</MenuItem>
                          <MenuItem value='admin'>admin</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Controller
                      control={control}
                      name='active'
                      render={({ field: { value, onChange } }) => (
                        <FormControlLabel
                          control={
                            <Switch
                              checked={value || false}
                              onChange={onChange}
                            />
                          }
                          label='attivo'
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} />
                  <Grid item xs={6} sm={6} md={4}>
                    {(obj.image === null ||
                      obj.image === undefined ||
                      obj.image === '') && (
                        <label htmlFor='contained-button-file'>
                          <MDInput
                            accept='image/*'
                            id='contained-button-file'
                            type='file'
                            sx={{ display: 'none' }}
                            onChange={target => uploadFile(target.target.files)}
                          />
                          <MDButton
                            variant='gradient'
                            color='info'
                            component='span'
                          >
                            Upload
                          </MDButton>
                        </label>
                      )}
                    {obj.image && (
                      <>
                        <img
                          src={config.SERVER_URL + '/download/' + obj.image}
                          alt='immagine profilo'
                          width='160'
                          style={{ marginTop: '30px' }}
                        />
                        <MDButton color='error' onClick={() => removeFile()}>
                          elimina
                        </MDButton>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} />
                </Grid>
              </MDBox>
              <MDBox p={2}>
                <MDButton
                  variant='gradient'
                  color='secondary'
                  component={Link}
                  to='/users/'
                >
                  Indietro
                </MDButton>
                <MDButton type='submit' variant='gradient' color='success'>
                  Salva Profilo
                </MDButton>
              </MDBox>
            </Card>
          </form>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Card>
            <MDBox p={2}>
              <MDAvatar
                src={
                  obj.image
                    ? config.SERVER_URL + '/download/' + obj.image
                    : avatar
                }
                alt='profile-image'
                size='xl'
                shadow='sm'
              />
            </MDBox>
            <MDBox p={2}>
              <MDTypography variant='h6'>{obj.username}</MDTypography>
              <MDTypography variant='h4'>
                {obj.name} {obj.surname}
              </MDTypography>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
      <MDBox mb={2} />
      <Footer />

      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>
  )
}
