import { useEffect, useContext, useState, useMemo } from 'react'
// @mui material components
import Card from '@mui/material/Card'
import Icon from '@mui/material/Icon'
import Grid from '@mui/material/Grid'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
// import Menu from '@mui/material/Menu'
// import MenuItem from '@mui/material/MenuItem'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
// import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'
import MDButton from 'components/MDButton'
// import MDDropdown from 'components/MDDropdown'

// Material Dashboard 2 PRO React example components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import DataTable from 'components/Tables/DataTable'

// zerouno
import mainService from 'services/visit.service'
import utils from 'utils/utils.js'
import { Link } from 'react-router-dom'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import RabbitContext from 'context/rabbit.js'
import { useSWRConfig } from 'swr'
import MDTypography from 'components/MDTypography'
import { DateTime } from 'luxon'
import ZUMapView from 'components/ZeroUno/ZUMapView.js'
import PlaceIcon from '@mui/icons-material/Place'
import authService from 'services/auth.service'

export default function VisitTable() {
  const [objId, setObjId] = useState(0)
  const [showSnackbar, setShowSnackbar] = useState({
    sh: false,
    msg: '',
    color: 'success'
  })
  const [openConfirm, setOpenConfirm] = useState(false)
  const { rabbit } = useContext(RabbitContext)
  const { mutate } = useSWRConfig()

  const [todo, setTodo] = useState(true)
  const [today, setToday] = useState(true)
  const [filtered, setFiltered] = useState([])
  const [objects, setObjects] = useState([])
  const [todayData, setTodayData] = useState([])

  useEffect(() => {
    fetchObjects()
  }, [])

  const fetchObjects = () => {
    mainService
      .getAll(rabbit.companyId)
      .then((response) => {
        setObjects(response.data)
        return response.data
      })
      .then((data) => {
        const todayData = filterData(data, true, true)
        setTodayData(
          todayData.map((item, index) => ({
            id: index,
            lat: Number(item.healthDoctor.healthcareFacility.latitude),
            lng: Number(item.healthDoctor.healthcareFacility.longitude),
            description:
              item.healthDoctor.name +
              ' ' +
              item.healthDoctor.surname +
              ' [' +
              item.healthDoctor.healthcareFacility.name +
              ']'
          }))
        )
      })
      .catch((error) => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      })
  }

  const filterData = (objects, today, todo) => {
    const todayStr = utils.formatDate(DateTime.now(), 'dd/MM/yyyy')
    const data = objects.filter((item) => {
      let ret = true
      if (todo && item.done) {
        ret = false
      }
      if (
        ret &&
        today &&
        todayStr !== utils.formatDate(item.scheduledOn, 'dd/MM/yyyy')
      ) {
        ret = false
      }
      return ret
    })
    setFiltered(data)
    setToday(today)
    setTodo(todo)
    return data
  }

  const downloadExcel = () => {
    mainService
      .excel(rabbit.companyId)
      .then((response) => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        )
        const link = document.createElement('a')
        link.href = downloadUrl
        link.setAttribute('download', 'visite.xlsx') // any other extension
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
      .catch((error) => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      })
  }

  // Delete
  const deleteObject = () => {
    setOpenConfirm(false)
    mainService.remove(objId).then(
      (response) => {
        // mainService.revalidate(rabbit.companyId, mutate)
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
        fetchObjects()
      },
      (error) => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const columns = [
    ...(authService.isAdmin()
      ? [
        {
          Header: 'utente',
          accessor: (row) => row.user.username
        }
      ]
      : []),
    {
      Header: 'data',
      accessor: (row) => utils.formatDate(row.scheduledOn, 'dd/MM/yyyy')
    },
    {
      Header: 'ora',
      accessor: (row) => row.scheduledOnTime
    },
    {
      Header: 'da fare',
      accessor: (row) => (row.done ? '' : 'da fare')
    },
    {
      Header: 'Medico',
      accessor: (row) => {
        return (
          <MDBox>
            <MDTypography variant='inherit' color='inherit'>
              {row.healthDoctor.name} {row.healthDoctor.surname}
            </MDTypography>
          </MDBox>
        )
      }
    },
    {
      Header: 'struttura',
      accessor: (row) => {
        return (
          <MDBox>
            <MDTypography variant='inherit' color='inherit'>
              {row.healthDoctor.healthcareFacility.name}
            </MDTypography>
            <MDTypography variant='inherit' color='inherit'>
              {row.healthDoctor.healthcareFacility.department}
            </MDTypography>
          </MDBox>
        )
      }
    },
    {
      Header: 'mail',
      accessor: (row) => row.healthDoctor.mail
    },
    {
      Header: 'phone',
      accessor: (row) => row.healthDoctor.phone
    },
    {
      Header: 'mobile',
      accessor: (row) => row.healthDoctor.mobile
    },
    {
      Header: 'azioni',
      sortable: false,
      filterable: false,
      width: 100,
      accessor: 'id',
      Cell: ({ value, row }) => (
        <Grid container spacing={1}>
          <Grid item>
            <MDButton
              color='info'
              size='small'
              iconOnly
              component={Link}
              to={'/visits/' + value}
            >
              <Icon>edit</Icon>
            </MDButton>
          </Grid>
          <Grid item>
            <MDButton
              iconOnly
              color='error'
              size='small'
              onClick={() => {
                setObjId(value)
                setOpenConfirm(true)
              }}
            >
              <Icon>delete</Icon>
            </MDButton>
          </Grid>
        </Grid>
      )
    }
  ]

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <ZUTitleForm
            bgColor='info'
            title='Visite Strutture Sanitarie'
            icon='medical_information'
          />

          <MDBox p={3} lineHeight={1}>
            <Stack direction='row' spacing={2}>
              <MDButton color='info' component={Link} to='/visits/new' m={1}>
                aggiungi
              </MDButton>
              <MDButton color='success' onClick={() => downloadExcel()} m={1}>
                esporta in excel
              </MDButton>
              <FormControlLabel
                control={
                  <Switch
                    checked={today}
                    onChange={() => filterData(objects, !today, todo)}
                  />
                }
                label='visite del giorno'
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={todo}
                    onChange={() => filterData(objects, today, !todo)}
                  />
                }
                label='da fare'
              />
            </Stack>
          </MDBox>

          <ZUMapView
            locations={todayData}
            zoom={5}
            centerLat={40.8359215}
            centerLng={14.2474822}
            height='500px'
            width='100%'
          />
          <DataTable table={{ columns: columns, rows: filtered }} canSearch />
        </Card>
      </MDBox>
      <Footer />
      <Dialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          Confermi eliminazione?
        </DialogTitle>
        <DialogActions>
          <MDButton onClick={() => setOpenConfirm(false)}>Annulla</MDButton>
          <MDButton onClick={deleteObject} autoFocus>
            Conferma
          </MDButton>
        </DialogActions>
      </Dialog>
      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>
  )
}
