'use strict'
import { useEffect, useContext, useState } from 'react'
// @mui material components
import Card from '@mui/material/Card'
import Icon from '@mui/material/Icon'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { Link as MuiLink } from '@mui/material'
import Grid from '@mui/material/Grid'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
// import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'

// Material Dashboard 2 PRO React example components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import DataTable from 'components/Tables/DataTable'

// zerouno
import authService from 'services/auth.service'
import customerService from 'services/customer.service'
import mainService from 'services/invoice.service'
import uploadService from 'services/upload.service'
import enumerationService from 'services/enumeration.service'
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import utils from 'utils/utils.js'
import RabbitContext from 'context/rabbit.js'
import { config } from 'variables/Config.js'
import { useForm, Controller } from 'react-hook-form'


export default function InvoiceTable() {
  const [objId, setObjId] = useState(0)
  const { idCustomer } = useParams()
  const [filename, setFilename] = useState('')
  const [obj, setObj] = useState([])
  const [objects, setObjects] = useState([])
  const [showSnackbar, setShowSnackbar] = useState({
    sh: false,
    msg: '',
    color: 'success'
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues
  } = useForm()
  const [openConfirm, setOpenConfirm] = useState(false)
  const { rabbit } = useContext(RabbitContext)

  useEffect(() => {
    fetchObjects()
  }, [])

  const fetchObjects = () => {
    if (idCustomer) {
      customerService
        .get(idCustomer)
        .then(response => {
          setObj(response.data)
        })
        .then(() => {
          return mainService.findByCustomer(idCustomer)
        })
        .then(response2 => {
          setObjects(response2.data)
        })
        .catch(error => {
          setShowSnackbar({
            sh: true,
            msg: error.response
              ? error.response.data.message
              : 'errore di ricezione dati',
            color: 'error'
          })
        })
    } else {
      mainService
        .getAll(rabbit.companyId)
        .then(response => {
          setObjects(response.data)
        })
        .catch(error => {
          setShowSnackbar({
            sh: true,
            msg: error.response
              ? error.response.data.message
              : 'errore di ricezione dati',
            color: 'error'
          })
        })
    }
  }

  // Delete
  const deleteObject = () => {
    setOpenConfirm(false)
    mainService.remove(objId).then(
      response => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
        fetchObjects()
      },
      error => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const columns = [
    {
      Header: 'fornitore',
      accessor: row =>
        row.customer.businessName?.toUpperCase() +
        ' ' +
        row.customer.surname.toUpperCase() +
        ' ' +
        row.customer.name.toUpperCase()
    },
    {
      Header: 'num',
      accessor: 'number'
    },
    {
      Header: 'anno',
      accessor: d => {
        return utils.formatDate(d.dateInvoice, 'yyyy')
      }
    },
    {
      Header: 'data',
      accessor: d => {
        return utils.formatDate(d.dateInvoice, 'dd/MM/yyyy')
      }
    },
    {
      Header: 'imponibile.',
      accessor: d => (d.taxable).toFixed(2).replace('.', ',')
    },
    {
      Header: 'iva',
      accessor: d => d.vat.toFixed(2).replace('.', ',')
    },
    {
      Header: 'totale',
      accessor: d => d.totalAmount.toFixed(2).replace('.', ',')
    },
    {
      Header: 'saldato',
      accessor: d => d.paid.toFixed(2).replace('.', ',')
    },
    {
      Header: 'da pagare',
      accessor: d => (d.totalAmount - d.paid).toFixed(2).replace('.', ',')
    },
    {
      Header: 'azioni',
      sortable: false,
      filterable: false,
      width: 100,
      accessor: row => (
        <>
          <MDButton
            color='info'
            size='small'
            iconOnly
            component={Link}
            to={`/invoices/${row.id}`}
            sx={{ mr: 1 }}
          >
            <Icon>edit</Icon>
          </MDButton>
                      <MDButton
              color='primary'
              size='small'
              iconOnly
              component={Link}
              to={`/invoices/${row.id}/invoiceTransactions`}
              sx={{ mr: 1 }}
            >
                <Icon>payments</Icon>
            </MDButton>
          <MDButton
            iconOnly
            color='error'
            size='small'
            onClick={() => {
              setObjId(row.id)
              setOpenConfirm(true)
            }}
            sx={{ mr: 1 }}
          >
            <Icon>delete</Icon>
          </MDButton>          
        </>
      )
    }
  ]

  const onSubmit = data => {
    data.filename = filename
    mainService.importXml(rabbit.companyId, data).then(
      response => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione eseguita con successo',
          color: 'success'
        })
        setFilename('')
        fetchObjects()
      },
      error => {
        if (error.response) {
          console.error(error.response.data.message)
          setShowSnackbar({
            sh: true,
            msg: error.response
              ? error.response.data.message
              : 'errore di ricezione dati',
            color: 'error'
          })
        } else {
          setShowSnackbar({
            sh: true,
            msg: 'errore di ricezione dati',
            color: 'error'
          })
        }
      }
    )
  }

  const showNotification = (message, messageClass) => {
    if (!tr) {
      setTR(true)
      setTimeout(function () {
        setTR(false)
      }, 6000)
      setMessageClass(messageClass)
      setMessage(message)
    }
  }

  const uploadFile = files => {
    const file = files[0]
    if (file) {
      if (file.size <= 1024000) {
        const formData = new FormData()
        formData.append('file', file)
        uploadService.uploadOne(formData).then(
          response => {
            const filename = response.data.filename
            setFilename(filename)
          },
          error => {
            if (error.response) {
              console.error(error.response.data.message)
              showNotification(error.response.data.message, 'error')
            } else {
              showNotification('errore di ricezione dati', 'error')
            }
          }
        )
      } else {
        showNotification('file troppo grande', 'error')
      }
    }
  }

  const removeFile = () => {
    const formData = new FormData()
    formData.delete('file')
    setFilename('')
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <ZUTitleForm
            bgColor='info'
            title='Fatture'
            icon='list_alt'
          />
          <MDBox p={3} lineHeight={1}>
            {idCustomer && (
              <MDButton
                color='secondary'
                component={Link}
                to='/invoices'
                sx={{ m: 1 }}
              >
                indietro
              </MDButton>
            )}
            {false && !idCustomer && authService.isAdmin() && (
              <MDButton
                color='success'
                component={MuiLink}
                href={
                  config.SERVER_URL +
                  '/invoices/download/excel?companyId=' +
                  rabbit.companyId
                }
                target='_blank'
                sx={{ m: 1 }}
              >
                esporta in excel
              </MDButton>
            )}
          </MDBox>
          <MDBox p={3} lineHeight={1}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                {(filename == '') && (
                  <Grid item xs={12} sm={12} md={12}>
                    <label htmlFor='contained-button-file'>
                      <MDInput
                        accept='text/xml'
                        id='contained-button-file'
                        type='file'
                        sx={{ display: 'none' }}
                        onChange={target => uploadFile(target.target.files)}
                      />
                      <MDButton
                        variant='gradient'
                        color='info'
                        component='span'
                      >
                        carica fattura xml
                      </MDButton>
                    </label>
                  </Grid>
                )}
                {filename && (
                  <>
                    <Grid item xs={12} sm={12} md={12}>

                      <MDTypography variant='info' fontWeight='medium'>{filename}</MDTypography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <MDButton color='error' onClick={() => removeFile()}>
                        elimina
                      </MDButton>
                      <MDButton type='submit' variant='gradient' color='success' sx={{ ml: 1 }}>
                        importa
                      </MDButton>
                    </Grid>
                  </>
                )}
              </Grid>
            </form>
          </MDBox>
          <DataTable table={{ columns: columns, rows: objects }} canSearch />
        </Card>
      </MDBox>
      <Footer />
      <Dialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          Confermi eliminazione?
        </DialogTitle>
        <DialogActions>
          <MDButton onClick={() => setOpenConfirm(false)}>Annulla</MDButton>
          <MDButton onClick={deleteObject} autoFocus>
            Conferma
          </MDButton>
        </DialogActions>
      </Dialog>
      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content={showSnackbar.msg}
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />

    </DashboardLayout>
  )
}
