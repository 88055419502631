import { useState, useEffect, useContext } from 'react'
// @material-ui/core components
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
// import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
// import Select from '@mui/material/Select'
// import Icon from '@mui/material/Icon'
// import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
// import FormControl from '@mui/material/FormControl'
// import FormHelperText from '@mui/material/FormHelperText'
import FormControlLabel from '@mui/material/FormControlLabel'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
// import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'

// Material Dashboard 2 PRO React components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'components/Navbars/DashboardNavbar'
import Footer from 'components/Footer'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import MDTypography from 'components/MDTypography'
import MDSnackbar from 'components/MDSnackbar'
// import SelectZeroUno from 'components/ZeroUno/SelectZeroUno'

// zerouno
import mainService from 'services/visit.service'
import healthDoctorService from 'services/healthDoctor.service'
import { useForm, Controller } from 'react-hook-form'
import { useParams, Link, useNavigate } from 'react-router-dom'
import ZUTitleForm from 'components/ZeroUno/ZUTitleForm'
import ZUSelect from 'components/ZeroUno/ZUSelect'
import RabbitContext from 'context/rabbit.js'
import { DateTime } from 'luxon'
import utils from 'utils/utils.js'

export default function VisitForm() {
  const { idObj } = useParams()
  const [obj, setObj] = useState({})
  const [doctors, setDoctors] = useState([])
  const [showSnackbar, setShowSnackbar] = useState({
    sh: false,
    msg: '',
    color: 'success'
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
    setError
  } = useForm()
  const navigate = useNavigate()
  const { rabbit } = useContext(RabbitContext)

  useEffect(() => {
    fetchAccessorsCompany(healthDoctorService, setDoctors)
    fetchObject()
  }, [])

  useEffect(() => {
    reset({ ...obj })
    setValue('scheduledOn', DateTime.fromISO(obj.scheduledOn).toISODate())
  }, [obj])

  const fetchObject = () => {
    if (idObj === 'new') {
      setObj({
        scheduledOn: DateTime.now(),
        scheduledOnTime: '08:00',
        healthDoctorId: 'n'
      })
      // setValue('scheduledOn', DateTime.now().toISODate())
    } else {
      mainService.get(idObj).then(
        (response) => {
          setObj(response.data)
        },
        (error) => {
          setShowSnackbar({
            sh: true,
            msg: error.response
              ? error.response.data.message
              : 'errore di ricezione dati',
            color: 'error'
          })
        }
      )
    }
  }

  const fetchAccessorsCompany = (service, setFunc) => {
    service.getAll(rabbit.companyId).then(
      (response) => {
        setFunc(response.data)
      },
      (error) => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const onSubmit = (data) => {
    // // check if scheduledOn is a string and convert it to a DateTime object
    // if (
    //   typeof data.scheduledOn === 'string' ||
    //   data.scheduledOn instanceof String
    // ) {
    //   data.scheduledOn = DateTime.fromFormat(data.scheduledOn, 'd/M/yyyy hh:mm')
    // }
    data.companyId = rabbit.companyId
    if (data.healthDoctorId === 'n') {
      setError('healthDoctorId', {
        type: 'custom',
        message: 'campo obbligatorio'
      })
      setShowSnackbar({
        sh: true,
        msg: 'selezionare un medico',
        color: 'error'
      })
    } else {
      if (idObj === 'new') {
        addObj(data)
      } else {
        data.id = obj.id
        updateObj(data, obj.id)
      }
    }
  }

  // Add obj
  const addObj = (data) => {
    mainService.create(data).then(
      (response) => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
        navigate('/visits')
      },
      (error) => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  const updateObj = (data, id) => {
    mainService.update(id, data).then(
      (response) => {
        setShowSnackbar({
          sh: true,
          msg: 'operazione effettuata con successo',
          color: 'success'
        })
        navigate('/visits')
      },
      (error) => {
        setShowSnackbar({
          sh: true,
          msg: error.response
            ? error.response.data.message
            : 'errore di ricezione dati',
          color: 'error'
        })
      }
    )
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={8}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Card>
                <ZUTitleForm
                  bgColor='info'
                  title='Visita Struttura Sanitaria'
                  icon='medical_information'
                />
                <MDBox p={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={3}>
                      <MDInput
                        fullWidth
                        type='date'
                        required
                        label='data appuntamento'
                        error={!!errors.scheduledOn}
                        {...register('scheduledOn', { required: true })}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                      {obj.scheduledOnTime && (
                        <ZUSelect
                          error={!!errors.ora}
                          label='ora'
                          register={register}
                          name='scheduledOnTime'
                          required
                          rules={{ required: true }}
                          defaultValue={obj.scheduledOnTime}
                        >
                          {utils.time().map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.label}
                            </MenuItem>
                          ))}
                        </ZUSelect>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      {obj.healthDoctorId && (
                        <ZUSelect
                          error={!!errors.healthDoctorId}
                          label='medico struttura sanitaria'
                          defaultValue={obj.healthDoctorId}
                          register={register}
                          name='healthDoctorId'
                          required
                          rules={{ required: true }}
                        >
                          <MenuItem key='0' value='n'>
                            seleziona un medico
                          </MenuItem>
                          {doctors &&
                            doctors.map((item) => (
                              <MenuItem key={item.id} value={item.id}>
                                {item.surname} {item.name}
                              </MenuItem>
                            ))}
                        </ZUSelect>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <Controller
                        control={control}
                        name='done'
                        render={({ field: { value, onChange } }) => (
                          <FormControlLabel
                            control={
                              <Switch
                                checked={value || false}
                                onChange={onChange}
                              />
                            }
                            label='completata'
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <MDInput
                        fullWidth
                        type='text'
                        required={false}
                        label='note'
                        error={!!errors.notes}
                        {...register('notes', { required: false })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} />
                  </Grid>
                </MDBox>
                <MDBox p={2}>
                  <MDButton
                    variant='gradient'
                    color='secondary'
                    component={Link}
                    to='/visits/'
                  >
                    Indietro
                  </MDButton>
                  <MDButton
                    type='submit'
                    variant='gradient'
                    color='success'
                    sx={{ ml: 1 }}
                  >
                    Salva
                  </MDButton>
                </MDBox>
              </Card>
            </form>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Card>
              <MDBox p={2}>
                <MDTypography variant='h6'>Struttura Sanitaria</MDTypography>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <MDSnackbar
        color={showSnackbar.color}
        icon='notifications'
        title='Notifica'
        content='errore di ricezione dati'
        dateTime='adesso'
        open={showSnackbar.sh}
        autoHideDuration={5000}
        // close={() => ''}
        close={() => setShowSnackbar({ sh: false, msg: '', color: 'info' })}
      />
    </DashboardLayout>
  )
}
